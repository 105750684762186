import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from 'services/api'
import Paper from 'components/Paper'
import LoadingScreen from 'components/LoadingScreen'
import { IFilters } from '../types/IFilters'
import { getSceneryService } from 'services/Scenery/get-scenery.service'
import './checkboxStyles.css'

import { DataTable } from 'primereact/datatable'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import 'primereact/resources/primereact.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import { Column } from 'primereact/column'
import { createDynamicColumnsDiscount } from './createDynamicColumnsDiscount'
import { colsSimple, colsClient } from './dynamicColumns'
import { Checkbox } from '@chakra-ui/react'
import { FaTimes } from 'react-icons/fa'
import { IRow } from 'pages/PriceBaseTable/Table/types'

interface IProps {
  filters: IFilters
  handleSetDiscount: (number: number) => void
  newColumns: any
}

const Table2: React.FC<IProps> = ({ filters, handleSetDiscount, newColumns }) => {
  const { id, type } = useParams()

  const [dataTable, setDataTable] = useState<Record<string, unknown>[]>([])
  const [loading, setLoading] = useState(true)
  const [allChecked, setAllChecked] = useState({
    approve: false,
    reject: false,
  })
  const [order, setOrder] = useState(1)
  const [statusScenery, setStatusScenery] = useState<boolean>(false)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPage] = useState(1)
  const [sort, setSort] = useState(1)
  const [rows, setRows] = useState(100)
  const [orderBy, setOrderBy] = useState('id')
  

  const [selectedRows, setSelectedRows] = useState([])

  useEffect(() => {
    const initialSelected: any = dataTable.filter((row: any) => row.status === 'S')
    setSelectedRows(initialSelected)
  }, [dataTable])

  const handleCheckboxChange = (
    rowData: any,
    actionType: 'approve' | 'disapprove'
  ) => {
    const newStatus = actionType === 'approve' ? 'S' : 'N'

    // Update the dataTable state
    const updatedRows = dataTable.map((row) => {
      if (row.id === rowData.id) {
        return { ...row, status: newStatus }
      }
      return row
    })

    setDataTable(updatedRows)

    // Update selection based on the actionType and new status
    const updatedSelection: any = updatedRows.filter(
      (row) => row.status === 'S'
    )
    setSelectedRows(updatedSelection)

    // Perform API Update
    const req = { id: rowData.id, status: newStatus }
    const url = '/sceneries/item-discount'
    api.put(url, req).then(() => {})
  }

  const inputsRef = useRef<any[]>([])

  useEffect(() => {
    inputsRef.current = inputsRef.current.slice(0, dataTable?.length)
  }, [dataTable?.length])

  useEffect(() => {
    setPage(1)
    setLoading(true)
  }, [filters])

  useEffect(() => {
    const getScenery = async () => {
      const res: any = await getSceneryService(Number(id) ?? 0)

      if (
        res.status === 'APPROVED' ||
        res.status === 'REJECTED' ||
        res.status === 'Aprovado' ||
        res.status === 'Reprovado'
      )
        setStatusScenery(true)
    }
    getScenery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getRows = useCallback(() => {
    setLoading(true)
    const url = `sceneries/${id}/items/B?page=${page}&limit=${rows}`

    const request: { [key: string]: string[] } = {}

    Object.keys(filters).forEach((key) => {
      request[key] = filters[key]?.map((v: IOption) => v.value) || []
    })

    api.post(url, { ...request, status: 'S', order_by: orderBy, sort: sort === 1 ? 'asc' : 'desc' }).then((res: any) => {
      const newRows = res.data.data.data.payload.map((item: any) => {
        return {
          ...item,
          statusScenery: statusScenery,
        }
      })

      const majorDiscount = newRows.reduce(
        (maxValue: number, currentItem: any) => {
      
          const discount = Number(currentItem.desconto)

  
          if (discount > maxValue) {
            return discount 
          } else {
            return maxValue 
          }
        },
        -Infinity
      )

      handleSetDiscount(majorDiscount)
      setDataTable(newRows)
      setTotal(res.data.data.data.total)
      setTotalPage(res.data.data.data.totalPages)
      setLoading(false)
    })
  }, [filters, id, page, statusScenery, handleSetDiscount, orderBy, sort, rows])

  useEffect(() => {
    getRows()
  }, [ getRows, orderBy, page, filters, sort, rows])

  const updateRow = (newRow: Partial<IRow>) => {
    setDataTable((prevRows: any) =>
      prevRows.map((row: any) =>
        String(row.id) === String(newRow.id) ? { ...row, ...newRow } : row
      )
    )
  }

  const toggleBigData = () => {
    console.log('TESTE')
  }

  const dynamicColumns = createDynamicColumnsDiscount(
    newColumns,
    getRows,
    updateRow,
    toggleBigData,
    inputsRef
  )

  const handleHeaderCheckboxChange = (type: string) => {
    const newAllChecked =
      type === 'approve'
        ? { approve: !allChecked.approve, reject: false }
        : { approve: false, reject: !allChecked.reject }

    setAllChecked(newAllChecked)

    const newSelection: any =
      newAllChecked.approve || newAllChecked.reject
        ? dataTable.map((row) => ({
            ...row,
            status: type === 'approve' ? 'S' : 'N',
          }))
        : []

    setSelectedRows(newSelection)

    const updatedRows = dataTable.map((row) => ({
      ...row,
      status: newAllChecked.approve ? 'S' : newAllChecked.reject ? 'N' : '',
    }))

    setDataTable(updatedRows)

    const url = '/sceneries/all-items-discount'

    api.put(url, {
      ids: updatedRows.map((item: any) => +item.id),
      status: newAllChecked.approve ? 'S' : newAllChecked.reject ? 'N' : '',
    })
  }

  const onSort = (event: any) => {
    const { sortField } = event;
    setOrderBy(sortField) 
    setSort(sort === 1 ? -1 : 1)
  }


  // if (loading) return <LoadingScreen />
  return (
    <Paper>
      <DataTable
        scrollable
        scrollHeight="65vh"
        paginator
        loading={loading}
        rows={rows}
        rowsPerPageOptions={[5, 10, 25, 50]}
        value={dataTable}
        selectionMode={null}
        selection={selectedRows}
        dataKey="id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        emptyMessage="Sem dados"
        filterDisplay="menu"
        currentPageReportTemplate={`Exibindo de {first} à {last} de ${total} registros`}
        onSort={onSort}
      >
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd' }}
          header={
            <Checkbox
              sx={{
                '.chakra-checkbox__control': {
                  borderColor: 'black',
                },
              }}
              colorScheme="green"
              size="lg"
              icon={<FaTimes color="white" size={10} />}
              isDisabled={statusScenery}
              style={{ backgroundColor: 'transparent' }}
              isChecked={allChecked.approve}
              onChange={() => handleHeaderCheckboxChange('approve')}
            />
          }
          body={(rowData) => (
            <Checkbox
              sx={{
                '.chakra-checkbox__control': {
                  borderColor: 'black',
                },
              }}
              colorScheme="green"
              size="lg"
              icon={<FaTimes color="white" size={10} />}
              isDisabled={statusScenery}
              isChecked={rowData.status === 'S'}
              onChange={() => handleCheckboxChange(rowData, 'approve')}
            />
          )}
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd' }}
          header={
            <Checkbox
              colorScheme="red"
              isInvalid
              size="lg"
              icon={<FaTimes color="white" size={10} />}
              isDisabled={statusScenery}
              style={{ backgroundColor: 'transparent' }}
              isChecked={allChecked.reject}
              onChange={() => handleHeaderCheckboxChange('reject')}
            />
          }
          body={(rowData) => (
            <Checkbox
              colorScheme="red"
              isInvalid
              size="lg"
              icon={<FaTimes color="white" size={10} />}
              isDisabled={statusScenery}
              style={{ backgroundColor: 'transparent' }}
              isChecked={rowData.status === 'N'}
              onChange={() => handleCheckboxChange(rowData, 'disapprove')}
            />
          )}
        ></Column>       
        {dynamicColumns}
      </DataTable>
    </Paper>
  )
}

export default memo(Table2)
