import { AxiosResponse } from 'axios'
import api from '../api'

export const getTradingConditionListService = async (
  request: any,
  page: number,
  limit: number
) => {
  const url = `/trading-condition/list`

  const payload = {
    ...request,
    page,
    limit,
  }
  //Deixei essa URL abaixo pra resolver se ainda vai ser reutilizada
  //   const oldUrl = `/sceneries/${id}/items?page=${page}&limit=${limit}&type=${type}`

  return api
    .post(url, payload)
    .then((response: any) => {
      return response.data
    })
    .catch(() => {
      return []
    })
}
