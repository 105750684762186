import React, { useState, useEffect } from 'react';
import { Flex, VStack, Checkbox, Divider } from '@chakra-ui/react';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FaRegSun } from 'react-icons/fa';

interface Column {
  field: string;
  header: string | React.ReactNode;
  style?: any;
}

interface IProps {
  columns: Column[];
  newColumns: Column[];
  setNewColumns: React.Dispatch<React.SetStateAction<Column[]>>;
}

interface SortableItemProps {
  id: string;
  index: number;
  header: string | React.ReactNode;
  isChecked: boolean;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  onCheckboxChange: () => void;
}

const ItemType = 'COLUMN';

const flattenHeader = (header: React.ReactNode): React.ReactNode => {
  if (typeof header === 'string') {
    return header;
  }

  if (React.isValidElement(header)) {
    return React.Children.map(header.props.children, (child) => {
      if (typeof child === 'string') {
        return child;
      }
      if (React.isValidElement(child) && child.type === 'br') {
        return null;
      }
      return flattenHeader(child);
    });
  }

  return header;
};

const SortableItem: React.FC<SortableItemProps> = ({
  id,
  index,
  header,
  isChecked,
  moveItem,
  onCheckboxChange,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: ItemType,
    hover(item: { index: number }, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const opacity = isDragging ? 0 : 1;

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCheckboxChange();
  };

  const style: React.CSSProperties = {
    padding: '0.5rem',
    border: '1px solid #ccc',
    borderRadius: '4px',
    marginBottom: '0.2rem',
    backgroundColor: '#fff',
    justifyContent: 'flex-start',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    opacity,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  return (
    <div ref={ref} style={style}>
      <Checkbox
        isChecked={isChecked}
        onChange={(e) => handleCheckboxChange(e)}
      />
      <span style={{ whiteSpace: 'nowrap', marginLeft: '0.5rem' }}>
        {flattenHeader(header)}
      </span>
    </div>
  );
};

const ConfigButton: React.FC<IProps> = ({
  columns,
  newColumns,
  setNewColumns,
}) => {
  const [originalOrder, setOriginalOrder] = useState<Column[]>([]);
  const [availableColumns, setAvailableColumns] = useState<Column[]>(columns);

  useEffect(() => {
    if (originalOrder.length === 0) {
      setOriginalOrder(columns); 
    }
    setAvailableColumns(columns);
    setNewColumns(columns);
  }, [columns, setNewColumns, originalOrder.length]);

  const [selectedColumns, setSelectedColumns] = useState<Column[]>(columns);

  const handleOpenConfig = (): void => {
    setSelectedColumns(newColumns);
    setOpenConfig(true);
  };

  const [openConfig, setOpenConfig] = useState<boolean>(false);

  const onClose = (): void => {
    setOpenConfig(false);
  };

  const onReset = (): void => {
    setNewColumns(columns); 
    setSelectedColumns(originalOrder);
    setOpenConfig(false);
  };

  const handleCheckboxChange = (field: string): void => {
    setSelectedColumns((prev) => {
      const isSelected = prev.some((col) => col.field === field);
      if (isSelected) {
        return prev.filter((col) => col.field !== field);
      } else {
        const newColumn = availableColumns.find((col) => col.field === field);
        if (newColumn) {
          const updatedColumns = [...prev];

          const insertIndex = originalOrder.findIndex(
            (col) => col.field === field
          );
          updatedColumns.splice(insertIndex, 0, newColumn);

          updatedColumns.sort(
            (a, b) =>
              originalOrder.findIndex((col) => col.field === a.field) -
              originalOrder.findIndex((col) => col.field === b.field)
          );
          return updatedColumns;
        }
        return prev;
      }
    });
  };

  const moveItem = (dragIndex: number, hoverIndex: number): void => {
    if (dragIndex === hoverIndex) return;

    const updatedColumns = [...selectedColumns];
    const [draggedItem] = updatedColumns.splice(dragIndex, 1);
    updatedColumns.splice(hoverIndex, 0, draggedItem);

    setSelectedColumns(updatedColumns);
  };

  const handleSave = (): void => {
    setNewColumns(selectedColumns);
    onClose();
  };

  return (
    <div>
      <Flex>
        <Button
          padding="0.5rem 1rem"
          onClick={handleOpenConfig}
          containerStyle={{
            backgroundColor: '#003b74',
          }}
        >
          <FaRegSun />
        </Button>
      </Flex>
      <Modal
        w="800px"
        maxHeight="80vh"
        isOpen={openConfig}
        title="Configuração das colunas"
        onClose={onClose}
        body={
          <DndProvider backend={HTML5Backend}>
            <VStack
              w="100%"
              align="flex-start"
              maxH="60vh"
              overflowY="auto"
              padding="1rem"
              spacing="0.5rem"
            >
              {availableColumns.map((item, index) => (
                <SortableItem
                  key={item.field}
                  id={item.field}
                  index={index}
                  header={item.header}
                  isChecked={selectedColumns.some(
                    (col) => col.field === item.field
                  )}
                  moveItem={moveItem}
                  onCheckboxChange={() => handleCheckboxChange(item.field)}
                />
              ))}
            </VStack>
          </DndProvider>
        }
        footer={
          <VStack w="100%" align="flex-start">
            <Divider />
            <Flex w="100%" pt="0.5rem" justifyContent="space-between">
              <Flex
                flexDirection={'row'}
                w="30%"
                justifyContent="space-between"
              >
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={onReset}>Resetar</Button>
              </Flex>
              <Button onClick={handleSave}>Salvar</Button>
            </Flex>
          </VStack>
        }
      />
    </div>
  );
};

export default ConfigButton;
