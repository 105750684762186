import { useCallback, Fragment, useEffect, useState } from 'react'
import { Text } from '@chakra-ui/react'

import Autocomplete from 'components/Autocomplete'

import nloop from '../nloop'
import INiveisIniState from '../reducer/types/INiveisIniState'
import api from 'services/api'

interface IProps {
  niveis: INiveisIniState
  setNivel: (data: { [n: string]: IOption[] }) => void
  levels: any
  setLevels: any
  levelsSelected: any
  setLevelsSelected: any
}

const Niveis: React.FC<IProps> = ({
  niveis,
  levels,
  setLevels,
  setNivel,
  levelsSelected,
  setLevelsSelected,
}) => {
  const [shouldUpdateSubsequentLevels, setShouldUpdateSubsequentLevels] = useState(false);

  const setValue = useCallback(
    (newVal: IOption[], key: string) => {
      const data: { [n: string]: IOption[] } = { ...niveis };
  
      const keyNumber = parseInt(key);
      if (isNaN(keyNumber)) return;
  
      if (keyNumber === 1 && newVal.length === 0) {
        nloop.forEach((n) => {
          if (parseInt(n) > 1) {
            data[n] = [];
          }
        });
        setShouldUpdateSubsequentLevels(true);
      } else {
        nloop.forEach((n) => {
          if (parseInt(n) > keyNumber) {
            data[n] = [];
          } else if (parseInt(n) === keyNumber) {
            data[n] = newVal;
          } else {
            if (niveis[n]) {
              data[n] = niveis[n];
            } else {
              data[n] = [];
            }
          }
        });
        setShouldUpdateSubsequentLevels(false);
      }
  
      setNivel(data);
      setLevelsSelected((oldOptions: any) => ({
        ...oldOptions,
        [key]: newVal,
      }));
    },
    [niveis, setNivel, setLevelsSelected]
  );
  
  useEffect(() => {
    if (shouldUpdateSubsequentLevels) {
      nloop.forEach(n => {
        if (parseInt(n) > 1) {
          setValue([], n);
        }
      });
      setShouldUpdateSubsequentLevels(false);
    }
  }, [shouldUpdateSubsequentLevels, setValue]);



  

  useEffect(() => {
    const getLevel2 = async () => {
      api
      .post('materials/filter/level-2', {
        level1: levelsSelected[1]?.map(
          (item: { label: string; value: string }) => Number(item.value)
        ),
        level2: levelsSelected[2]?.map(
          (item: { label: string; value: string }) => Number(item.value)
        ),
      })
      .then((res) => {
      // api
      //   .post('materials/filter/level-2', {
      //     level1: niveis[1].map((item: { label: string; value: string }) =>
      //       Number(item.value)
      //     ), level2: levelsSelected[2]?.map(
      //       (item: { label: string; value: string }) => Number(item.value)
      //     ),
      //   })
      //   .then((res) => {

          setLevels((oldOptions: any) => ({
            ...oldOptions,
            [2]: res.data.data.result.map(
              (item: { level_name: string; level_code: string }) => {
                return {
                  label: item.level_name,
                  value: item.level_code,
                }
              }
            ),
          }))

        })
    }
    getLevel2()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelsSelected[1]])  

  useEffect(() => {
    const getLevel3 = async () => {
      api
        .post('materials/filter/level-3', {
          level1: levelsSelected[1]?.map(
            (item: { label: string; value: string }) => Number(item.value)
          ),
          level2: levelsSelected[2]?.map(
            (item: { label: string; value: string }) => Number(item.value)
          ),
        })
        .then((res) => {

          setLevels((oldOptions: any) => ({
            ...oldOptions,
            [3]: res.data.data.result.map(
              (item: { level_name: string; level_code: string }) => {
                return {
                  label: item.level_name,
                  value: item.level_code,
                }
              }
            ),
          }))
        })
    }
    getLevel3()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelsSelected[2]])

  useEffect(() => {
    const getLevel4 = async () => {
      api
        .post('materials/filter/level-4', {
          level1: levelsSelected[1]?.map(
            (item: { label: string; value: string }) => Number(item.value)
          ),
          level2: levelsSelected[2]?.map(
            (item: { label: string; value: string }) => Number(item.value)
          ),
          level3: levelsSelected[3]?.map(
            (item: { label: string; value: string }) => Number(item.value)
          ),
        })
        .then((res) => {

          setLevels((oldOptions: any) => ({
            ...oldOptions,
            [4]: res.data.data.result.map(
              (item: { level_name: string; level_code: string }) => {
                return {
                  label: item.level_name,
                  value: item.level_code,
                }
              }
            ),
          }))


        })
    }
    getLevel4()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelsSelected[3]])

  useEffect(() => {
    const getLevel5 = async () => {
      api
        .post('materials/filter/level-5', {
          level1: levelsSelected[1]?.map(
            (item: { label: string; value: string }) => Number(item.value)
          ),
          level2: levelsSelected[2]?.map(
            (item: { label: string; value: string }) => Number(item.value)
          ),
          level3: levelsSelected[3]?.map(
            (item: { label: string; value: string }) => Number(item.value)
          ),
          level4: levelsSelected[4]?.map(
            (item: { label: string; value: string }) => Number(item.value)
          ),
        })
        .then((res) => {

          setLevels((oldOptions: any) => ({
            ...oldOptions,
            [5]: res.data.data.result.map(
              (item: { level_name: string; level_code: string }) => {
                return {
                  label: item.level_name,
                  value: item.level_code,
                }
              }
            ),
          }))
        })
    }
    getLevel5()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelsSelected[4]])

  return (
    <>
      {nloop.map((n) => (
        <Fragment key={n}>
          <Text>Nível 0{n}</Text>
          <Autocomplete
            isMulti
            value={levelsSelected[Number(n)]}
            options={levels[n]}
            setValue={(newVal: IOption[]) => setValue(newVal, n)}
          />
        </Fragment>
      ))}
    </>
  )
}

export default Niveis