import { HStack } from '@chakra-ui/react'

import IRow from '../../types/IRow'

import View from './View'
import Delete from './Delete'
import { useContext } from 'react'
import { UserContext } from 'state/user-context'

interface IProps {
  row: IRow
}

const Options: React.FC<IProps> = ({ row }) => {
  const { userParameters } = useContext(UserContext)

  return (
    <HStack justify="flex-end" spacing={4}>
      {userParameters.write ? <Delete row={row} /> : <></>}
      <View row={row} />
    </HStack>
  )
}

export default Options
