import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import Paper from 'components/Paper'
import MainTable from './MainTable'
import { IRow, IFilters } from './types'
import { getItemsListService } from 'services/PriceBaseTable/get-items-list.service'
import { DataTable, SortOrder } from 'primereact/datatable'
import {
  colsSimple,
  colsDetailed,
  colsTradingCondition,
} from './dynamicColumns'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import 'primereact/resources/primereact.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import api from 'services/api'
import './DataTable.css'
import ClientTable from './ClientTable'
import SimpleTable from './SimpleTable'

interface Child {
  id: number
}

interface ITableProps {
  filters: IFilters
  typeView: string
  toggleBigData: () => void
  handleRefreshVerifyStatus: (status: boolean) => void
  handleSetIds: (ids: number[]) => void
  handleRefresh: () => void
  setColumns: any
  newColumns: any
  setDinamicFilterData: (data: any) => void
  showBigNumbers: boolean
  handleEraseFilters: () => void
  handleSortOrderExport: (sort: string, orderBy: string) => void
  setFilterToUpdateBult: (filter: any) => void
  refreshTable: boolean
}

const PriceBaseTable: React.FC<ITableProps> = ({
  filters,
  typeView,
  toggleBigData,
  handleRefreshVerifyStatus,
  handleSetIds,
  handleRefresh,
  setColumns,
  newColumns,
  // dinamicFilterData,
  setDinamicFilterData,
  showBigNumbers,
  handleEraseFilters,
  handleSortOrderExport,
  setFilterToUpdateBult,
  refreshTable,
}) => {
  const { id } = useParams<{ id: string }>()
  const [dataTable, setDataTable] = useState<IRow[]>([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPage] = useState(1)
  const [selectedRows, setSelectedRows] = useState([])
  const [allSelected, setAllSelected] = useState(false)
  const [scrollHeight, setScrollHeight] = useState('55vh')
  const [expandedRows, setExpandedRows] = useState<any>({})
  const [childrenData, setChildrenData] = useState<Record<string, Child[]>>({})
  const [totalRecords, setTotalRecords] = useState(0)
  const [sort, setSort] = useState<SortOrder>(1)
  const [rows, setRows] = useState(100)
  const [orderBy, setOrderBy] = useState('id')

  useEffect(() => {
    const updateScrollHeight = () => {
      const height = window.innerHeight
      if (!showBigNumbers) {
        if (!showBigNumbers && height > 1280) {
          setScrollHeight('84vh')
        } else if (!showBigNumbers && height < 1280) {
          setScrollHeight('76vh')
        }
      }
      if (showBigNumbers && height > 1280) {
        setScrollHeight('64vh')
      } else if (showBigNumbers && height < 1280) {
        setScrollHeight('54vh')
      }
    }
    setExpandedRows([])

    updateScrollHeight()
    window.addEventListener('resize', updateScrollHeight)

    return () => {
      window.removeEventListener('resize', updateScrollHeight)
    }
  }, [showBigNumbers])

  useEffect(() => {
    if (
      selectedRows.length > 0 ||
      dataTable.filter(
        (item: any) => item.status === 'S' || item.status === null
      )
    ) {
      handleRefreshVerifyStatus(true)
    } else {
      handleRefreshVerifyStatus(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, handleRefreshVerifyStatus])

  const handleHeaderCheckboxChange = () => {
    const newSelection: any = allSelected
      ? []
      : dataTable.map((row) => ({ ...row, status: 'S' }))
    setSelectedRows(newSelection)

    const updatedRows = dataTable.map((row) => ({
      ...row,
      status: allSelected ? null : 'S',
    }))
    setDataTable(updatedRows)

    const verifyNewId = (id: string | number) => {
      if (String(id).includes('-')) {
        return Number(String(id).split('-')[0])
      } else return id
    }

    const url = '/sceneries/all-items'
    api.put(url, {
      ids: updatedRows.map((item: any) => verifyNewId(item.id)),
      status: allSelected ? null : 'S',
      sceneryId: id,
      type: typeView,
    })

    setAllSelected(!allSelected)
  }

  const onCheckboxChange = (e: any, rowData: any, type: string) => {
    const newStatus = rowData.status

    const payloadId = rowData.newId ? rowData.newId : rowData.id
    const req = {
      id: Number(payloadId),
      status: newStatus,
      type: typeView,
    }
    const updatedRows = dataTable.map((row) => {
      if (
        row.newId && rowData.newId
          ? String(row.id) === String(rowData.id)
          : String(row.id) === String(rowData.id)
      ) {
        return { ...row, status: newStatus }
      }
      return row
    })

    setDataTable(updatedRows)

    const updatedSelection: any = newStatus
      ? [...selectedRows, { ...rowData, status: newStatus }]
      : selectedRows.filter((row: any) =>
          row.newId && rowData.newId
            ? String(row.id) !== String(rowData.id)
            : String(row.id) !== String(rowData.id)
        )
    setSelectedRows(updatedSelection)

    api.put('/sceneries/item', req).then(() => {
      toggleBigData()
    })
  }

  const getRows = useCallback(async () => {
    setLoading(true)
    const request: { [key: string]: string[] } = {}

    Object.keys(filters).forEach((key) => {
      request[key] = filters[key]?.map((v: IOption) => v.value) || []
    })

    const newDinamicFilter =
      filters.dinamicFilter?.length > 0
        ? [
            {
              ...filters.dinamicFilter[0],
              above_that:
                filters?.dinamicFilter[0]?.above_that ===
                filters?.dinamicFilter[0]?.higher_value
                  ? null
                  : filters?.dinamicFilter[0]?.above_that,
              until_that:
                filters?.dinamicFilter[0]?.until_that ===
                filters?.dinamicFilter[0]?.smaller_value
                  ? null
                  : filters?.dinamicFilter[0]?.until_that,
            },
            {
              ...filters?.dinamicFilter[1],
              above_that:
                filters?.dinamicFilter[1]?.above_that ===
                filters?.dinamicFilter[1]?.higher_value
                  ? null
                  : filters?.dinamicFilter[1]?.above_that,
              until_that:
                filters?.dinamicFilter[1]?.until_that ===
                filters?.dinamicFilter[1]?.smaller_value
                  ? null
                  : filters?.dinamicFilter[1]?.until_that,
            },
            {
              ...filters?.dinamicFilter[2],
              above_that:
                filters?.dinamicFilter[2]?.above_that ===
                filters?.dinamicFilter[2]?.higher_value
                  ? null
                  : filters?.dinamicFilter[2]?.above_that,
              until_that:
                filters?.dinamicFilter[2]?.until_that ===
                filters?.dinamicFilter[2]?.smaller_value
                  ? null
                  : filters?.dinamicFilter[2]?.until_that,
            },
          ]
        : []
    try {
      setFilterToUpdateBult({
        ...request,
        dinamicFilter: newDinamicFilter,
        typeView,
        step: 'table',
      })
      const result = await getItemsListService(
        Number(id) ?? 0,
        { ...request, dinamicFilter: newDinamicFilter },
        page === 0 ? 1 : page + 1,
        rows,
        typeView,
        'table',
        orderBy,
        sort === 1 ? 'asc' : 'desc'
      )
      setTotalRecords(result.data.total)
      handleSetIds(result.idsToExport)
      setDataTable(result.data.payload)
      setDinamicFilterData(result.dinamicFilter)
      setTotalPage(result.data.totalPages)
      handleSortOrderExport(sort === 1 ? 'asc' : 'desc', orderBy)

      if (typeView === 'simple') {
        setColumns(colsSimple)
      } else if (typeView === 'detailed') {
        setColumns(colsDetailed)
      } else if (typeView === 'trading_condition') {
        setColumns(colsTradingCondition)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [
    filters,
    id,
    page,
    typeView,
    handleSetIds,
    rows,
    sort,
    orderBy,
    setColumns,
    setDinamicFilterData,
    handleSortOrderExport,
    setFilterToUpdateBult,
  ])

  useEffect(() => {
    getRows()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, typeView, sort, rows, page, filters, refreshTable])

  useEffect(() => {
    handleEraseFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeView])

  useEffect(() => {
    // Ajusta o comprimento de inputsRef para corresponder ao comprimento de dataTable
    if (inputsRef.current.length !== dataTable.length) {
      inputsRef.current = Array(dataTable.length).fill(null)
    }
  }, [dataTable.length])

  const updateRow = (newRow: Partial<IRow>) => {
    handleRefresh()
    setDataTable((prevRows: any) =>
      prevRows.map((row: any) =>
        row.id === newRow.id ? { ...row, ...newRow } : row
      )
    )
  }

  useEffect(() => {
    const initialSelected: any = dataTable.filter(
      (row: any) => row.status === 'S'
    )
    setSelectedRows(initialSelected)
  }, [dataTable])

  const inputsRef = useRef<(HTMLInputElement | null)[]>([])

  // if (loading) return <LoadingScreen />
  return (
    <Paper>
      {typeView === 'detailed' ? (
        <MainTable
          handleRefresh={handleRefresh}
          dataTable={dataTable}
          getRows={getRows}
          toggleBigData={toggleBigData}
          inputsRef={inputsRef}
          selectedRows={selectedRows}
          onSelectionChange={onCheckboxChange}
          handleHeaderCheckboxChange={handleHeaderCheckboxChange}
          allSelected={allSelected}
          typeView={typeView}
          page={page}
          setPage={setPage}
          loading={loading}
          totalRecords={totalRecords}
          rows={rows}
          setRows={setRows}
          columns={newColumns}
          sort={sort}
          orderBy={orderBy}
          setSort={setSort}
          setOrderBy={setOrderBy}
          scrollHeight={scrollHeight}
          filters={filters}
        />
      ) : typeView === 'trading_condition' ? (
        <ClientTable
          handleRefresh={handleRefresh}
          dataTable={dataTable}
          getRows={getRows}
          toggleBigData={toggleBigData}
          inputsRef={inputsRef}
          selectedRows={selectedRows}
          onSelectionChange={onCheckboxChange}
          handleHeaderCheckboxChange={handleHeaderCheckboxChange}
          allSelected={allSelected}
          typeView={typeView}
          page={page}
          setPage={setPage}
          loading={loading}
          totalRecords={totalRecords}
          rows={rows}
          setRows={setRows}
          columns={newColumns}
          sort={sort}
          setSort={setSort}
          setOrderBy={setOrderBy}
          orderBy={orderBy}
          scrollHeight={scrollHeight}
          filters={filters}
        />
      ) : (
        <SimpleTable
          handleRefresh={handleRefresh}
          dataTable={dataTable}
          getRows={getRows}
          toggleBigData={toggleBigData}
          inputsRef={inputsRef}
          selectedRows={selectedRows}
          onSelectionChange={onCheckboxChange}
          handleHeaderCheckboxChange={handleHeaderCheckboxChange}
          allSelected={allSelected}
          typeView={typeView}
          page={page}
          setPage={setPage}
          loading={loading}
          totalRecords={totalRecords}
          rows={rows}
          setRows={setRows}
          columns={newColumns}
          sort={sort}
          setSort={setSort}
          setOrderBy={setOrderBy}
          orderBy={orderBy}
          scrollHeight={scrollHeight}
          filters={filters}
        />
      )}
    </Paper>
  )
}

export default memo(PriceBaseTable)
