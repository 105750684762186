import React, { useContext, useEffect, useState } from 'react'
import Paper from 'components/Paper'
import LoadingScreen from 'components/LoadingScreen'
import IPaginationOptions from 'components/TableBackend/types/IPaginationOptions'
import IRow from '../types/IRow'
import api from '../../../../../services/api'
import { Flex } from '@chakra-ui/react'
import IFilters from 'pages/PriceBaseTable/types/IFilters'
import Filters from '../Filters'
import ModalAddClient from '../ModalAddClient'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import CheckboxTaxRecovery from './CheckboxTaxRecovery'
import Percent from './Percent'
import Input from './Input'
import { Delete } from 'pages/UsersEdit/Inputs/Access/Cluster/styles'
import { UserContext } from 'state/user-context'

interface IProps extends IPaginationOptions {
  rows: IRow[]
  loading: boolean
  clusterId: string
  binaryPos: number[]
  filters: any
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const Table: React.FC<IProps> = ({
  rows,
  loading,
  clusterId,
  setPage,
  setFilters,
  filters,
  setLoading,
}) => {
  const [rowsState, setRowsState] = useState<any[]>([])
  const [order, setOrder] = useState(1)

  useEffect(() => {
    setRowsState(rows)
  }, [rows])

  const { userParameters } = useContext(UserContext)

  const deleteRow = (clientCode: string, clusterId: string) => {
    const payload = {
      clientCode,
      clusterId,
    }
    api.post(`trading-condition/delete`, payload).then((res) => {
      setRowsState(res.data)
    })
  }

  const updateRow = () => {}

  const clients = rowsState.map((item: any) => {
    return {
      label: item.client_name,
      value: item.client_code,
    }
  })

  const uniqueChannelsMap = new Map()

  rows.forEach((item: any) => {
    const uniqueKey = `${item.channel_code}_${item.channel_name}`
    if (!uniqueChannelsMap.has(uniqueKey)) {
      uniqueChannelsMap.set(uniqueKey, {
        label: item.channel_name,
        value: item.channel_code,
      })
    }
  })

  const channels = Array.from(uniqueChannelsMap.values())

  const checkboxTemplate = (rowData: any, type: string, updateRow: any) => {
    return (
      <CheckboxTaxRecovery
        param={type}
        row={rowData}
        updateCell={updateRow}
        // toggleBigData={toggleBigData}
      />
    )
  }

  const historicTemplate = (rowData: any, type: string) => {
    return <Percent value={rowData[type]} />
  }

  const inputTemplate = (rowData: any, type: string, updateRow: any) => {
    return <Input row={rowData} param={type} onUpdate={updateRow} />
  }

  const deleteTemplate = (rowData: any) => {
    if (userParameters.write) {
      return (
        <Delete
          onClick={() => deleteRow(rowData.client_code, rowData.cluster_id)}
        />
      )
    } else return <></>
  }

  const onSort = (event: any) => {
    const { sortField } = event

    setRowsState((prevData: IRow[]) => {
      const sortedData = [...prevData].sort((a: any, b: any) => {
        const aValue = a[sortField]
        const bValue = b[sortField]

        if (aValue == null) return order === 1 ? 1 : -1
        if (bValue == null) return order === 1 ? -1 : 1

        if (aValue < bValue) return order === 1 ? -1 : 1
        if (aValue > bValue) return order === 1 ? 1 : -1

        return 0
      })

      return sortedData
    })

    setOrder(order === 1 ? -1 : 1)
  }

  if (!clusterId) return null
  if (loading) return <LoadingScreen />
  return (
    <Paper containerStyle={{ width: '100%' }}>
      <Flex marginBottom={5} justify="right">
        <div style={{ marginRight: 25 }}>
          <ModalAddClient
            clients={clients}
            channels={channels}
            setNewRows={setRowsState}
          />
        </div>
        <div style={{ marginRight: 25 }}>
          <Filters
            setPage={setPage}
            setLoading={setLoading}
            filters={filters}
            setFilters={setFilters}
          />
        </div>
        {/* <div>
          <Button onClick={handleSave}>Salvar </Button>
        </div> */}
      </Flex>
      <DataTable
        onSort={onSort}
        scrollable
        scrollHeight="flex"
        paginator
        rows={15}
        rowsPerPageOptions={[5, 10, 25, 50]}
        value={rowsState}
        // selectionMode="multiple"
        // selection={selectedRows}
        // onSelectionChange={onSelectionChange}
        dataKey="id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        emptyMessage="Sem dados"
        filterDisplay="menu"
        currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
      >
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="cluster_name"
          header="Cluster"
          style={{ minWidth: '100px', zIndex: '2' }}
        ></Column>

        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="client_name"
          header="Cliente"
          style={{ minWidth: '170px', zIndex: '2' }}
        ></Column>

        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="channel_name"
          header="Canal"
          style={{ minWidth: '100px', zIndex: '2' }}
        ></Column>
        {/* <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field=""
          header="Recuperação de Impostos"
          body={(rowData) =>
            checkboxTemplate(rowData, 'tax_recovery', updateRow)
          }
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column> */}
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="commission_percent"
          header="Comissões %"
          body={(rowData) =>
            inputTemplate(rowData, 'commission_percent', updateRow)
          }
          style={{ minWidth: '100px', zIndex: '2' }}
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="commission_historic_percent"
          body={(rowData) =>
            historicTemplate(rowData, 'commission_historic_percent')
          }
          header="Comissão Histórico"
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field=""
          header="Ajustar Preço"
          body={(rowData) =>
            checkboxTemplate(rowData, 'adjust_commission', updateRow)
          }
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column>
        {/* <Column
          field=""
          header="Bonificações %"
          body={(rowData) => inputTemplate(rowData, 'bonus_percent', updateRow)}
          style={{ minWidth: '100px', zIndex: '2' }}
        ></Column>
        <Column
          field="bonus_historic_percent"
          header="Bonificações Histórico"
          body={(rowData) =>
            historicTemplate(rowData, 'bonus_historic_percent')
          }
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column>
        <Column
          field=""
          header="Ajustar Preço"
          body={(rowData) =>
            checkboxTemplate(rowData, 'adjust_bonus', updateRow)
          }
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column>
        <Column
          field=""
          header="Invest. Ações
          Comerciais %"
          body={(rowData) =>
            inputTemplate(rowData, 'invest_percent', updateRow)
          }
          style={{ minWidth: '100px', zIndex: '2' }}
        ></Column>
        <Column
          field="invest_historic_percent"
          header="Invest
          Histórico"
          body={(rowData) =>
            historicTemplate(rowData, 'invest_historic_percent')
          }
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column>
        <Column
          field=""
          header="Ajustar Preço"
          body={(rowData) =>
            checkboxTemplate(rowData, 'adjust_invest', updateRow)
          }
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column>
        <Column
          field=""
          header="Contrato %"
          body={(rowData) =>
            inputTemplate(rowData, 'contract_percent', updateRow)
          }
          style={{ minWidth: '100px', zIndex: '2' }}
        ></Column>
        <Column
          field="contract_historic_percent"
          header="Contrato
          Histórico"
          body={(rowData) =>
            historicTemplate(rowData, 'contract_historic_percent')
          }
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column>
        <Column
          field=""
          header="Ajustar Preço"
          body={(rowData) =>
            checkboxTemplate(rowData, 'adjust_contract', updateRow)
          }
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column>
        <Column
          field=""
          header="Frete %"
          body={(rowData) => inputTemplate(rowData, 'bonus_percent', updateRow)}
          style={{ minWidth: '100px', zIndex: '2' }}
        ></Column>
        <Column
          field="freight_historic_percent"
          header="Frete
          Histórico"
          body={(rowData) =>
            historicTemplate(rowData, 'freight_historic_percent')
          }
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column>
        <Column
          field=""
          header="Ajustar Preço"
          body={(rowData) =>
            checkboxTemplate(rowData, 'adjust_freight', updateRow)
          }
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column> */}

        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field=""
          header=""
          body={(rowData) => deleteTemplate(rowData)}
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column>
      </DataTable>
      {/* <TableBackend
        rows={rowsState}
        cols={cols}
        page={page}
        limit={limit}
        total={total}
        setPage={setPage}
        totalPages={totalPages}
        cellProps={{ updateRow, deleteRow }}
      /> */}
    </Paper>
  )
}

export default Table
