import { VStack } from '@chakra-ui/react'
import LoadingScreen from 'components/LoadingScreen'
import Nav from './Nav'
import Access from './Access'
import useEdit from './useEdit'
import MainInputs from './MainInputs'

const Inputs: React.FC = () => {
  const {
    onSave,
    onCancel,
    name,
    email,
    nivel,
    roles,
    access,
    active,
    enrollment,
    setName,
    setEmail,
    setNivel,
    setRoles,
    setAccess,
    setActive,
    setEnrollment,
    defaultInfo,
    loading,
    setClusters,
    clusters,
    password,
    setPassword,
    setApproveType,
    approveType,
    setCategory,
    category,
    setLevel,
    level,
    setApprovalLevel,
    approvalLevel,
  } = useEdit()

  if (loading) return <LoadingScreen />
  return (
    <VStack w="100%" align="flex-start" spacing="1rem">
      <Nav
        name={name}
        email={email}
        enrollment={enrollment}
        defaultInfo={defaultInfo}
        onSave={onSave}
        onCancel={onCancel}
      />
      <MainInputs
        name={name}
        email={email}
        access={access}
        active={active}
        enrollment={enrollment}
        setName={setName}
        setEmail={setEmail}
        setAccess={setAccess}
        setActive={setActive}
        setEnrollment={setEnrollment}
        password={password}
        setPassword={setPassword}
      />
      <Access
        nivel={nivel}
        roles={roles}
        active={active}
        setNivel={setNivel}
        setRoles={setRoles}
        setClusters={setClusters}
        clusters={clusters}
        setApproveType={setApproveType}
        approveType={approveType}
        setCategory={setCategory}
        category={category}
        setLevel={setLevel}
        level={level}
        setApprovalLevel={setApprovalLevel}
        approvalLevel={approvalLevel}
      />
    </VStack>
  )
}

export default Inputs
