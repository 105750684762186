import api from 'services/api'

interface IOptionsType {
  channels: IOption[]
  clusters: IOption[]
  clients: IOption[]
}

export const handleGetTradingConditionOptions = (): Promise<IOptionsType> => {
  return api
    .get(`/trading-condition/filters`)
    .then((res: any): IOptionsType => {
      return res.data
    })
}
