import api from '../../../services/api'
import { Values } from '../Inputs/Access/Approvals/UseSlider/types'
import { ApproveType } from '../Inputs/Access/Level';

interface ICreateUserProps {
  name: string
  roles: { id: number; name: string }[]
  nivel: any
  email: string
  access: boolean
  active: boolean
  enrollment: string
  approvalScopes: Values[]
  clusters: string[]
  level: any
  category: any
  password: string
  approveTable: boolean
  approveDiscount: boolean
}

export const createUserService = async (payload: ICreateUserProps) => {
  const url = `access-controll/create-user`

  return api
    .post(url, payload)
    .then((result) => {
      return {
        status: result.status,
        message: 'Usuário cadastrado com sucesso',
      }
    })
    .catch((error) => {
      return {
        status: error?.response?.data?.statusCode,
        message: error?.response?.data?.error?.description?.message,
      }
    })
}
