import { Box } from '@chakra-ui/react'
import { useMemo } from 'react'
import {
  FaArrowUp as PlusSvg,
  FaArrowDown as MinusSvg,
  FaEquals as EqualSvg,
} from 'react-icons/fa'

interface IProps {
  row: { [key: string]: any }
}

export const Indicator: React.FC<IProps> = ({ row }) => {
  const convertToValidNumber = (input: string): number => {
    const cleanedInput = input?.replace(/,/g, '.')

    const validNumber = parseFloat(cleanedInput)

    if (validNumber && isNaN(validNumber)) {
      return 0
    }

    return validNumber
  }

  const ppv = useMemo(() => {
    return convertToValidNumber(row.preco_bruto_sugestao ?? '0')
  }, [row.preco_bruto_sugestao])

  const mrg = useMemo(() => {
    return +row.preco_bruto_atual?.replace(',', '.')
  }, [row.preco_bruto_atual])

  const mrgValue = isNaN(mrg) ? 0 : mrg

  const svg = useMemo(() => {
    if (ppv > mrgValue) return <PlusSvg />
    if (ppv < mrgValue) return <MinusSvg />
    return <EqualSvg />
  }, [mrgValue, ppv])

  const color = useMemo(() => {
    if (ppv > mrgValue) return 'green.500'
    if (ppv < mrgValue) return 'red.500'
    return 'yellow.500'
  }, [mrgValue, ppv])

  return (
    <Box
      pl="0.5rem"
      color={color}
      marginRight={2}
      marginLeft={-8}
      marginTop={3}
    >
      {svg}
    </Box>
  )
}

export default Indicator
