import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import Button from '../../../../../components/Button'
import Autocomplete from '../../../../../components/Autocomplete'
import api from 'services/api'
import { IChannels } from '../../ClassificacaoDoItem/types/IRow'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UserContext } from 'state/user-context'

interface IProps {
  clients: any
  channels: any
  setNewRows: (data: any[]) => void
}

const ModalAddClient: React.FC<IProps> = ({
  clients,
  channels,
  setNewRows,
}) => {
  const { user } = useContext(UserContext)
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [channelsOpt, setChannelsOpt] = useState([])
  const [clientsOpt, setClientsOpt] = useState([])
  const [channelsSelected, setChannelsSelected] = useState([])
  const [clientsSelected, setClientsSelected] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [clusterIdOpt, setClusterIdOpt] = useState([])
  const [clusterSelected, setClusterSelected] = useState([])

  useEffect(() => {
    setChannelsSelected([])
    setClientsSelected([])
  }, [isOpen])

  const handleAddClient = (ids: any) => {
    setIsLoading(true)
    api
      .put('/trading-condition/update-clients', { ids })
      .then((res: any) => {

        onClose()
        setNewRows(res.data)
        setIsLoading(false)

        if (res.status === 201) {
          toast({
            title: `Os Clientes foram atualizados com sucesso`,
            status: 'success',
            isClosable: true,
          })
        }
      })
      .catch(() => {
        toast({
          title: `Houve um erro ao atualizar os clientes`,
          status: 'error',
          isClosable: true,
        })
        setIsLoading(false)
      })
  }

  const getClusterId = useCallback(() => {
    isOpen &&
      api
        .get('/clusters/all')
        .then((res) => {
          if (user?.clusters?.includes(0)) {
            setClusterIdOpt(
              res.data.data.clusters.map((item: any) => ({
                label: item.name,
                value: item.id,
              }))
            )
          } else {
            setClusterIdOpt(
              res.data.data.clusters
                .filter((item: any) => user?.clusters?.includes(item.id))
                .map((item: any) => ({
                  label: item.name,
                  value: item.id,
                }))
            )
          }
        })
        .catch((e) => {
          toast({
            title: `Não foi possível trazer as opções de filtro:  ${e.response?.data?.message}`,
            status: 'error',
            isClosable: true,
          })
        })
  }, [isOpen, toast, user])

  const getChannelId = useCallback(() => {
    isOpen &&
      api
        .post('/channels/by-cluster', {
          clusters: clusterSelected.map((item: any) => item.value),
        })
        .then((res) => {
          setChannelsOpt(
            res.data?.data?.channels?.map((v: IChannels) => ({
              label: v?.channel_name,
              value: v?.channel_code,
            }))
          )
        })
        .catch((error) => {
          if (!toast.isActive('data')) {
            toast({
              id: 'data',
              title: `Erro ao carregar dados, tente novamente: ${error.message}`,
              status: 'error',
            })
          }
        })
  }, [isOpen, toast, clusterSelected])

  const getClients = useCallback(
    (clients: IOption[]) => {
      if (isOpen) {
        api
          .post('/clients/filter-by-channel-cluster', {
            channelCode: channelsSelected.map((item: IOption) => item.value),
            clusterId: clusterSelected.map((item: IOption) => item.value),
          })
          .then((res) => {
            const result = res.data?.data?.result?.map((v: any) => ({
              label: v?.client_name,
              value: v?.client_code,
            }))
            const filtered = result.filter(
              (item: IOption) =>
                !clients.some((client: IOption) => client.value === item.value)
            )
            setClientsOpt(filtered)
          })
          .catch((error) => {
            if (!toast.isActive('data')) {
              toast({
                id: 'data',
                title: `Erro ao carregar dados, tente novamente: ${error.message}`,
                status: 'error',
              })
            }
          })
      }
    },
    [isOpen, toast, channelsSelected, clusterSelected]
  )

  useEffect(() => {
    if (clusterSelected) {
      getChannelId()
    }
  }, [clusterSelected, getChannelId, clients])

  useEffect(() => {
    if (channelsSelected) {
      getClients(clients)
    }
  }, [channelsSelected, getClients, clients])

  useEffect(() => {
    // getChannelId()
    getClusterId()
  }, [getClusterId])

  const addClient = () => {
    const data = clientsSelected.map(
      (item: { label: string; value: string }) => item.value
    )
    handleAddClient(data)
  }

  return (
    <>
      <Flex mb="1rem">
        <Button
          padding="0.5rem 1rem"
          onClick={onOpen}
          containerStyle={{
            backgroundColor: '#003b74',
            // backgroundColor: '#003b74' || '#38A169',
          }}
        >
          Adicionar Cliente
        </Button>
      </Flex>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="40rem">
          <ModalHeader>Adicionar Clientes</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="flex-start">
              <Text>Clusters</Text>
              <Autocomplete
                value={clusterSelected}
                options={clusterIdOpt}
                setValue={setClusterSelected}
                isMulti
              />
              <Text>Canais</Text>
              <Autocomplete
                value={channelsSelected}
                options={channelsOpt}
                setValue={(a: any) => setChannelsSelected(a)}
                isMulti
              />
              <Text>Clientes</Text>
              <Autocomplete
                value={clientsSelected}
                options={clientsOpt}
                setValue={setClientsSelected}
                isMulti
              />
              {/* {nloop.map((n) => (
                <Fragment key={n}>
                  <Text>Nível 0{n}</Text>
                  <Autocomplete
                    isMulti
                    value={niveis[n]}
                    options={niveis[`${n}opt`]}
                    setValue={(newVal: IOption[]) => setValueNivel(newVal, n)}
                  />
                </Fragment>
              ))} */}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <VStack w="100%" align="flex-start">
              <Divider />
              <Flex w="100%" pt="0.5rem" justifyContent="space-between">
                <Button onClick={onClose}>Voltar</Button>
                <Button onClick={addClient}>
                  {isLoading ? (
                    <CircularProgress style={{ color: 'white' }} size={20} />
                  ) : (
                    'Adicionar'
                  )}
                </Button>
              </Flex>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalAddClient
