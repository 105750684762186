import { FaCheckDouble as ApprovalSvg } from 'react-icons/fa'
import PageTitle from 'components/PageTitle'
import Button from 'components/Button'
import { Container } from './styles'
import { HStack } from '@chakra-ui/react'

interface IHeaderApproval {
  handleApproveReject: (type: string) => void
  disabled: boolean
  expired: boolean
}

const Header: React.FC<IHeaderApproval> = ({
  handleApproveReject,
  disabled,
  expired,
}) => {
  return (
    <Container>
      <PageTitle icon={<ApprovalSvg />} title="Requisições" />
      <HStack spacing="1rem">
        <Button
          // disable={disabled || expired}
          disable={disabled}
          bg="brown"
          onClick={() => handleApproveReject('reject')}
        >
          Reprovar
        </Button>
        <Button
          // disable={disabled || expired}
          disable={disabled}
          bg="darkGreen"
          onClick={() => handleApproveReject('approve')}
        >
          Aprovar
        </Button>
      </HStack>
    </Container>
  )
}

export default Header
