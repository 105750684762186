import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { HStack, useToast } from '@chakra-ui/react'
import { IoPricetagsSharp as ParametersSvg } from 'react-icons/io5'

import Button from 'components/Button'
import PageTitle from 'components/PageTitle'

import IFilters from '../types/IFilters'
import FilterButton from './FilterButton'
import SendToApproveButton from './SendToApproveButton'
import { Container } from './styles'
import ConfigButton from './ConfigButton'

interface IProps {
  filters: IFilters
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>
  typeView: string
  setTypeView: (type: string) => void
  columns: any
  newColumns: any
  setNewColumns: React.Dispatch<any>
}

const Header: React.FC<IProps> = ({
  filters,
  setFilters,
  typeView,
  setTypeView,
  columns,
  newColumns,
  setNewColumns,
}) => {
  const toast = useToast()
  const navigate = useNavigate()

  const toggleSimpleView = useCallback(
    (type: string) => {
      setTypeView(type)
    },
    [setTypeView]
  )

  const onSave = useCallback(() => {
    toast({
      title: 'Salvo com sucesso!',
      status: 'success',
    })
    navigate('/pricebase')
  }, [navigate, toast])

  return (
    <Container>
      <HStack spacing="1rem">
        <PageTitle
          icon={<ParametersSvg />}
          title="Gestão de Preços"
          linkLabel="Cenário"
        />
      </HStack>

      <HStack spacing="1rem">
        <ConfigButton
          columns={columns}
          newColumns={newColumns}
          setNewColumns={setNewColumns}
        />
        <FilterButton filters={filters} setFilters={setFilters} />
        <Button
          style={{ display: 'none' }}
          disabled={typeView === 'simple'}
          onClick={() => toggleSimpleView('simple')}
        >
          Simples
        </Button>
        <Button
          style={{ display: 'none' }}
          disabled={typeView === 'detailed'}
          onClick={() => toggleSimpleView('trading_condition')}
        >
          Detalhado
        </Button>
        <Button
          style={{ display: 'none' }}
          disabled={typeView === 'trading_condition'}
          onClick={() => toggleSimpleView('trading_condition')}
        >
          Visão Cliente
        </Button>
        <Button onClick={onSave}>Salvar Cenário</Button>
        <SendToApproveButton />
      </HStack>
    </Container>
  )
}

export default Header
