import React, { useEffect, useState } from 'react'
import { Box, Text, VStack, Table, Tbody, Tr, Td } from '@chakra-ui/react'
import Autocomplete from 'components/Autocomplete'
import { Delete } from './styles'
import {
  ICluster,
  getClustersListService,
} from 'services/Clusters/get-clusters-list-service'
import api from 'services/api'

interface IProps {
  setClusters: React.Dispatch<React.SetStateAction<IOption[]>>
  clusters: IOption[]
  setCategory: React.Dispatch<React.SetStateAction<IOption[]>>
  category: IOption[]
  setLevel: React.Dispatch<React.SetStateAction<IOption[]>>
  level: IOption[]
}

const Cluster: React.FC<IProps> = ({
  setClusters,
  clusters,
  setCategory,
  category,
  setLevel,
  level,
}) => {
  const [data, setData] = useState<IOption[]>([])
  const [options, setOptions] = useState<IOption[]>([])
  const [categoryOptions, setCategoryOptions] = useState<IOption[]>([])
  const [levelSelected, setLevelSelected] = useState<any>()

  const levelsOptions = [
    { label: 'Nivel 1', value: '1' },
    { label: 'Nivel 2', value: '2' },
    { label: 'Nivel 3', value: '3' },
    { label: 'Nivel 4', value: '4' },
    { label: 'Nivel 5', value: '5' },
  ]

  useEffect(() => {
    const getClusters = async () => {
      const clustersData = await getClustersListService()
      const clusterMapped = clustersData?.map((item: ICluster) => {
        return {
          label: item.name,
          value: String(item.id),
        }
      })
      if (clusterMapped) {
        setData(clusterMapped)
        setOptions(clusterMapped)
      }
    }
    getClusters()
  }, [])

  useEffect(() => {
    const getLevels = async () => {
      if (levelSelected?.value) {
        api
          .post(`access-controll/filter-category`, {
            level: Number(levelSelected.value),
          })
          .then((res: any) => {
            setCategoryOptions(res.data)
          })
      }
    }
    getLevels()
  }, [levelSelected])

  return (
    <VStack w="100%" align="flex-start">
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div>
          <Text fontSize="md" fontWeight="semibold" color="#003b74">
            Cluster
          </Text>
          <Autocomplete
            options={options}
            value={clusters}
            setValue={setClusters}
            isMulti
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <Text fontSize="md" fontWeight="semibold" color="#003b74">
            Nivel
          </Text>
          <Autocomplete
            options={levelsOptions}
            value={level}
            setValue={(e: [{ value: string; label: string }]) => {
              setLevel(e), setLevelSelected(e)
            }}
          />
        </div>
        <div style={{ marginTop: 13 }}>
          <Text fontSize="md" fontWeight="semibold" color="#003b74">
            Categoria
          </Text>
          <Autocomplete
            options={categoryOptions}
            value={category}
            setValue={setCategory}
            isMulti
          />
        </div>
      </div>
    </VStack>
  )
}

export default Cluster
