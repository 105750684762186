const rolesDictionary = (name: string) => {
    return (
      {
        ADMIN: 'Admin',
        READ_PARAMETERS: 'Visualizar Parâmetros',
        WRITE_PARAMETERS: 'Editar Parâmetros',
        READ_SCENERY: 'Visualizar Simulador Tabela de Preço',
        WRITE_SCENERY: 'Editar Simulador Tabela de Preço',
        READ_DISCOUNT_SCENERY: 'Visualizar Simulador Tabela de Desconto',
        WRITE_DISCOUNT_SCENERY: 'Editar Simulador Tabela de Desconto',
      }[name] || name
    )
  }

  export default rolesDictionary