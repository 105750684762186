import { useRef } from 'react'
import { useToast } from '@chakra-ui/react'
import { SplitButton } from 'primereact/splitbutton'
import api from 'services/api'
import LoadingModal from 'components/LoadingModal'

interface IProps {
  url: string
  type: string
  filter: IFilterProps

}

export interface IFilterProps {
  scenery_id?: number
  channel_code?: number[]
  cluster_id?: number[]
  level1_code?: string[]
  level2_code?: string[]
  level3_code?: string[]
  level4_code?: string[]
  level5_code?: string[]
  client_code?: number[]
  classificacao_item?: string[]
}

const ExportExcelButton: React.FC<IProps> = ({
  url,
  type,
  filter
}) => {
  const toast = useToast()
  const linkRef = useRef<any>(null)
  const loadingRef = useRef<any>(null)

  const onClick = (extensionType: string) => {
    const request: { [key: string]: string[] } = {}

    Object.keys(filter).forEach((key) => {
      if (key in filter) {
        // Verifica se a chave existe no objeto antes de acessá-la
        request[key as keyof IFilterProps] =
          (filter[key as keyof IFilterProps] as unknown as IOption[])?.map(
            (v: IOption) => v.value
          ) || []
      }
    })

    const newUrl = '/industry-margins/export'
    api
      .post(
        newUrl,
        {
          filter: request,
          type,
          extensionType         
        },
        { responseType: 'arraybuffer' }
      )
      .then((response) => {
        let mimeType = 'application/vnd.ms-excel;'
        let fileExtension = 'xls'

        if (extensionType === 'csv') {
          mimeType = 'text/csv;charset=utf-8;'
          fileExtension = 'csv'
        }

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: mimeType })
        )

        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `Margem Industria`
        )

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch((err) => {
        toast({
          title: 'Erro, tente novamente.' + err,
          status: 'error',
          isClosable: true,
        })
      })
  }

  const items = [
    {
      label: 'Exportar CSV',
      icon: 'pi pi-file',
      command: () => onClick('csv'),
    },
    {
      label: 'Exportar XLSX',
      icon: 'pi pi-file-excel',
      command: () => onClick('xls'),
    },
  ]

  return (
    <>
      <SplitButton
        label="Exportar"
        icon="pi pi-download"
        model={items}
        className="p-button-success"
        style={{
          backgroundColor: '#003b74',
          color: '#fff',
          height: 33,
          paddingLeft: 10,
          borderRadius: 5,
          fontSize: '1.1rem',
          fontWeight: 100,
        }}
      />
      <a ref={linkRef} style={{ display: 'none' }} download />
      <LoadingModal ref={loadingRef} />
    </>
  )
}

export default ExportExcelButton
