import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { FaTrash as DeleteSvg } from 'react-icons/fa'
import api from '../../../services/api'
import Paper from '../../../components/Paper'
import Status from '../../../components/Status'
import TableBackend from '../../../components/TableBackend'
import LoadingScreen from '../../../components/LoadingScreen'

import { DeleteContainer, EditSvg } from './styles'
import { ISceneries } from './types'
import { UserContext } from 'state/user-context'
import { useToast } from '@chakra-ui/react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import DeleteModal from './DeleteComponent/DeleteModal'

const Table: React.FC = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { userDiscountScenery, userScenery } = useContext(UserContext)
  const [rows, setRows] = useState<ISceneries[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [page, setPage] = useState<number>(0)
  const [limit, setLimit] = useState<number>(15)
  const [total, setTotal] = useState<number>(0)
  const [totalPages, setTotalPage] = useState<number>(1)
  const [order, setOrder] = useState(1)
  const [rowToDelete, setRowToDelete] = useState<any>('')
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const onEdit = useCallback(
    (row: ISceneries) => {
      const { id, type } = row

      if (
        (type === 'A' && !userScenery.read) ||
        (type === 'B' && !userDiscountScenery.read) ||
        (type === 'B1' && !userDiscountScenery.read)
      ) {
        toast({
          status: 'error',
          title: 'É necessário ter permissão para visualizar o cenário',
        })
        return
      } else {
        const url =
          type === 'A'
            ? `/pricebase/table/${id}/A`
            : `/pricebase/discount/${id}/${type}`

        navigate(url)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  )

  const verifyTypeName = (type: string) => {
    if (type === 'A') return 'Tabela'
    else return 'Desconto'
  }

  const columns = useMemo(() => {
    return [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Solicitante', accessor: 'users' },
      { Header: 'Nome do Cenário', accessor: 'name' },
      {
        Header: 'Tipo',
        accessor: ({ row }: any) => <>{verifyTypeName(row.type)}</>,
      },
      {
        Header: 'Data',
        accessor: ({ row }: any) => (
          <>
            {moment(new Date(row.created_at))
              .zone(0)
              .format('DD/MM/YYYY HH:mm')}
          </>
        ),
      },
      {
        Header: 'Status',
        accessor: ({ row }: any) => <Status status={row.status} />,
      },
      {
        Header: 'Editar',
        accessor: ({ row }: any) =>
          row.status === 'Editando' && <EditSvg onClick={() => onEdit(row)} />,
      },
    ]
  }, [onEdit])


 

  const getListData = useCallback(() => {

    api.post(`/sceneries?page=${page + 1}&limit=${limit}`).then((res) => {
      setRows(
        res.data?.data?.data?.payload?.map((row: ISceneries) => ({
          ...row,
          user: row.users,
          type: row.type,
        }))
      )
      setTotal(Number(res?.data?.data?.data?.total))
      setTotalPage(res.data?.data?.data?.totalPages)
      setLoading(false)
    })
  }, [page, limit])

  // Atualização periódica com useEffect e setInterval
  useEffect(() => {
    getListData() // Chamada inicial dos dados
    const intervalId = setInterval(() => {
      getListData() // Atualiza os dados a cada 10 segundos
    }, 10000)

    // Limpar intervalo ao desmontar o componente
    return () => clearInterval(intervalId)
  }, [getListData])

  const onDelete = useCallback(() => {
    console.log('quero deletar')
    api
      .delete(`/sceneries/${rowToDelete.id}/${rowToDelete.type}/delete`)
      .then(() => {
        setOpenDeleteModal(false)
        toast({
          title: 'Cenário excluido com sucesso!',
          status: 'success',
          isClosable: true,
        })
        getListData()
      })
      .catch((error) => {
        toast({
          title: `Erro ao deletar o capitão: ${error.message} `,
          status: 'error',
          isClosable: true,
        })
      })
  }, [getListData, rowToDelete, toast])

  useEffect(getListData, [getListData])

  const editColumnTemplate = (rowData: ISceneries) => {
    return rowData.status === 'Editando' ? (
      <EditSvg onClick={() => onEdit(rowData)} />
    ) : null
  }

  const deleteTemplate = (rowData: ISceneries) => {
    return rowData.status === 'Editando' || rowData.status === 'Cancelado' ? (
      <DeleteContainer>
        <DeleteSvg
          onClick={() => {
            setRowToDelete(rowData)
            setOpenDeleteModal(true)
          }}
        />
      </DeleteContainer>
    ) : null
  }

  const statusTemplate = (rowData: ISceneries) => {
    return <Status status={rowData.status} />
  }

  const dateHourTemplate = (rowData: any) => {
    return moment(rowData.created_at).add(3, 'hours').format('DD/MM/yyyy HH:mm')
  }

  const typeTemplate = (rowData: any) => {
    return <span>{verifyTypeName(rowData.type)}</span>
  }

  const dateTemplate = (rowData: any) => {
    if (!rowData.date_initial) return ''
    else {
      return `${moment(rowData.date_initial).add(1, 'day').format('DD/MM/yyyy')} à ${moment(
        rowData.date_final
      ).add(1, 'day').format('DD/MM/yyyy')}`
    }
  }

  const onPage = (event: any) => {
    setPage(event.page)
    setLimit(event.rows)
  }

  const onSort = (event: any) => {
    const { sortField } = event

    setRows((prevData) => {
      const sortedData = [...prevData].sort((a: any, b: any) => {
        const aValue = a[sortField]
        const bValue = b[sortField]

        if (aValue == null) return order === 1 ? 1 : -1
        if (bValue == null) return order === 1 ? -1 : 1

        if (aValue < bValue) return order === 1 ? -1 : 1
        if (aValue > bValue) return order === 1 ? 1 : -1

        return 0
      })

      return sortedData
    })

    setOrder(order === 1 ? -1 : 1)
  }

  if (loading) return <LoadingScreen />
  return (
    <Paper>
      <DeleteModal
        isOpen={openDeleteModal}
        row={rowToDelete}
        onClose={() => setOpenDeleteModal(false)}
        onDelete={onDelete}
      />
      <DataTable
        onSort={onSort}
        totalRecords={total}
        scrollable
        lazy
        scrollHeight={'70vh'}
        first={page * limit}
        // tableStyle={{ minWidth: '40rem', maxHeight: '600px' }}
        paginator
        rows={limit}
        rowsPerPageOptions={[5, 10, 25, 50]}
        value={rows}
        onPage={onPage}
        dataKey="id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        emptyMessage="Sem dados"
        filterDisplay="menu"
        currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
      >
        <Column
          sortable
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="id"
          header="ID"
          style={{ minWidth: '100px', zIndex: '2' }}
        ></Column>

        <Column
          sortable
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="users"
          header="Solicitante"
          style={{ minWidth: '100px', zIndex: '2' }}
        ></Column>
        <Column
          sortable
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="name"
          header="Nome do cenário"
          style={{ minWidth: '200px', zIndex: '2' }}
        ></Column>
        <Column
          sortable
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="type"
          body={typeTemplate}
          header="Tipo"
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column>
        <Column
          sortable
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="created_at"
          header="Data de criação"
          body={dateHourTemplate}
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column>
        <Column
          sortable
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field=""
          header="Período"
          body={dateTemplate}
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column>
        <Column
          sortable
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="status"
          header="Status"
          body={statusTemplate}
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field=""
          header="Editar"
          body={editColumnTemplate}
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field=""
          header="Excluir"
          body={deleteTemplate}
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column>
      </DataTable>
    </Paper>
  )
}

export default Table
