import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px !important;
`

export const Title = styled.h1`
  color: #003b74;
  font-weight: 500;
`

export const Buttons = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
`

interface ITableButtonProps {
  selected: boolean
}
export const TableButton = styled.div<ITableButtonProps>`
  cursor: pointer;
  user-select: none;

  position: relative;

  height: 2.75rem;
  padding: 0.5rem 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.1rem;
  text-align: center;
  border-radius: 4px;
  white-space: nowrap;

  transition: all 200ms ease-in-out;

  &:hover {
    background-color: #f0f0f0;
  }

  color: ${({ selected }) => (selected ? '#003b74' : '#808080')};
  border: ${({ selected }) => `1px solid ${selected ? '#003b74' : '#E8E8E8'}`};
`
