import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FaFilter as FilterSvg } from 'react-icons/fa'
import {
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import Button from '../../../../../components/Button'
import Autocomplete from '../../../../../components/Autocomplete'
import nloop from './nloop'
import IFilters from 'pages/PriceBaseTable/types/IFilters'
import { handleGetTradingConditionOptions } from './service'
import { UserContext } from 'state/user-context'

interface IProps {
  filters: IFilters
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const Filters: React.FC<IProps> = ({
  filters,
  setFilters,
  setPage,
  setLoading,
}) => {
  const { user } = useContext(UserContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [clusterId, setClusterId] = useState<IOption[]>([])
  const [clusterIdOpt, setClusterIdOpt] = useState<IOption[]>([])
  const [isFiltering, setIsFiltering] = useState<boolean>(false)
  const [channelsOpt, setChannelsOpt] = useState<IOption[]>([])
  const [clientsOpt, setClientsOpt] = useState<IOption[]>([])
  const [channelsSelected, setChannelsSelected] = useState<IOption[]>([])
  const [clientsSelected, setClientsSelected] = useState<IOption[]>([])
  const [clientsBackupOpt, setClientsBackupOpt] = useState<any[]>([])

  const setDefaultData = useCallback(() => {
    setClusterId(filters?.cluster_id)
    setChannelsSelected(filters?.channel_code)
    setClientsSelected(filters?.client_code)
  }, [filters])

  useEffect(setDefaultData, [setDefaultData, isOpen])

  const getOptions = async () => {
    const result: {
      channels: IOption[]
      clusters: IOption[]
      clients: IOption[]
    } = await handleGetTradingConditionOptions()
    setChannelsOpt(result.channels)
    if (user?.clusters?.includes(0)) {
      setClusterIdOpt(result.clusters)
    } else {
      setClusterIdOpt(
        result.clusters.filter((item: any) =>
          user?.clusters?.includes(item.value)
        )
      )
    }

    setClientsOpt(result.clients)
    setClientsBackupOpt(result.clients)
  }

  useEffect(() => {
    if (isOpen) {
      getOptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    if (channelsSelected?.length < 1) {
      setClientsOpt(clientsBackupOpt)
    } else {
      const channelsFiltered = channelsSelected?.map((item) => item.value)
      const clientsFiltered = clientsOpt?.filter((item: any) =>
        channelsFiltered?.includes(item.channel_code)
      )
      setClientsOpt(clientsFiltered)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelsSelected])

  const onFilter = useCallback(() => {
    const newFilters: { [n: string]: IOption[] } = {
      cluster_id: !clusterId ? [] : clusterId,
      channel_code: !channelsSelected ? [] : channelsSelected,
      client_code: !clientsSelected ? [] : clientsSelected,
    }

    setIsFiltering(false)

    Object.keys(newFilters).forEach((key) => {
      if (newFilters[key]?.length > 0) setIsFiltering(true)
    })

    setFilters(newFilters)
    onClose()
    setPage(1)
    setLoading(true)
  }, [
    clusterId,
    onClose,
    setFilters,
    setLoading,
    setPage,
    channelsSelected,
    clientsSelected,
  ])

  const onClear = useCallback(() => {
    const newFilters: { [n: string]: IOption[] } = {
      cluster_id: [],
      captain_code: [],
    }

    nloop.forEach((n) => {
      newFilters[`level${n}_code`] = []
    })

    setIsFiltering(false)

    setFilters(newFilters)
    onClose()
    setPage(1)
    setLoading(true)
  }, [onClose, setFilters, setLoading, setPage])

  return (
    <>
      <Flex mb="1rem">
        <Button
          padding="0.5rem 1rem"
          onClick={onOpen}
          containerStyle={{
            backgroundColor: isFiltering ? '#38A169' : '#003b74',
          }}
        >
          <FilterSvg />
        </Button>
      </Flex>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="40rem">
          <ModalHeader>Filtros</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="flex-start">
              <Text>Cluster</Text>
              <Autocomplete
                value={clusterId}
                options={clusterIdOpt}
                setValue={setClusterId}
                isMulti
              />
              <Text>Canal</Text>
              <Autocomplete
                value={channelsSelected}
                options={channelsOpt}
                setValue={setChannelsSelected}
                isMulti
              />
              <Text>Cliente</Text>
              <Autocomplete
                value={clientsSelected}
                options={clientsOpt}
                setValue={setClientsSelected}
                isMulti
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <VStack w="100%" align="flex-start">
              <Divider />
              <Flex w="100%" pt="0.5rem" justifyContent="space-between">
                <Button onClick={onClear}>Limpar</Button>
                <Button onClick={onFilter}>Filtrar</Button>
              </Flex>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Filters
