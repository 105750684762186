import {
  useCallback,
  useEffect,
  useState,
  useReducer,
  useMemo,
  useContext,
} from 'react'
import { useParams } from 'react-router-dom'
import { FaExchangeAlt } from 'react-icons/fa'
import { Divider, Flex, HStack, Input, Text, VStack } from '@chakra-ui/react'
import Modal from 'components/Modal'
import Button from 'components/Button'
import Autocomplete from 'components/Autocomplete'
// import nloop from './nloop'
// import Niveis from './Niveis'
// import { handleGetOptions } from './actions'
// import { niveisIniState, niveisReducer } from './reducer'
import api from 'services/api'
import IFilters from 'pages/PriceBaseTable/types/IFilters'
import { UserContext } from 'state/user-context'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import InputToFilter from './Input'
import Money from 'pages/PriceBaseTable/Table/Money'
import Percent from 'pages/PriceBaseTable/Table/Percent'

interface IProps {
  filters: IFilters
  setFilters: React.Dispatch<any>
  dinamicFilterData: any
}

export interface LevelsSelectedState {
  '1': IOption[]
  '2': IOption[]
  '3': IOption[]
  '4': IOption[]
  '5': IOption[]
}

const RangeFilterButton: React.FC<IProps> = ({
  filters,
  setFilters,
  dinamicFilterData,
}) => {
  const { user } = useContext(UserContext)
  const { id } = useParams()
  const [isFilteringRange, setIsFilteringRange] = useState(false)
  const initialValue = [
    {
      filter: 'Descolamento Real',
    },
    {
      filter: 'Margem Bruta Sobre Receita Bruta',
    },
    {
      filter: 'Margem Bruta atual sobre preço bruto atual',
    },
  ]
  const [dinamicFilter, setDinamicFilter] = useState(initialValue)

  useEffect(() => {
    if (dinamicFilterData) setDinamicFilter(dinamicFilterData)
  }, [dinamicFilterData])

  const request = useMemo(() => {
    const newRequest: { [n: string]: string[] } = {}

    // nloop.forEach((n) => {
    //   newRequest[`nivel0${n}`] = niveis[n].map((o) => o.value)
    // })

    return newRequest
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const getOptions = useCallback(() => {
    if (!id) return

    // handleGetOptions(id, request, (data: any) => {
    //   if (user?.clusters?.includes(0)) {
    //     setClusterIdOpt(data.cluster || [])
    //   } else {
    //     const clusterFiltered = data.cluster?.filter((item: any) =>
    //       user?.clusters?.includes(item.value)
    //     )
    //     setClusterIdOpt(clusterFiltered)
    //   }
    //   setClientOpt(
    //     data.client?.map((v: any) => ({
    //       label: v?.client_name.trim(),
    //       value: v?.client_code,
    //     })) || []
    //   )
    //   setClassificationOpt(data.classificacao_item || [])
    //   setSkuOpt(data.sku || [])
    //   setCaptainOpt(data.captains || [])
    //   setChannelOpt(
    //     data.channel?.map((v: any) => ({
    //       label: v?.channel_name,
    //       value: v?.channel_code,
    //     })) || []
    //   )
    //   dispatch({ type: 'SETOPTS', payload: data.niveis })
    // })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, request, user])

  const handleOpenFilterRange = () => {
    setIsFilteringRange(true)
  }

  const onFilter = useCallback(() => {
    setIsFilteringRange(false)
    setFilters({ dinamicFilter })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFilters, dinamicFilter])

  const onClear = useCallback(() => {
    const newFilters: { [n: string]: IOption[] } = {
      cluster_id: [],
      classificacao_item: [],
      captain_code: [],
      channel_code: [],
      sku: [],
      client_code: [],
    }

    setIsFilteringRange(false)
    setFilters(newFilters)
  }, [setFilters])

  const onClose = () => {
    setIsFilteringRange(false)
  }

  const InputTemplate = (
    rowData: any,
    field: any,
    updateRow: (newRow: Partial<any>) => void
  ) => <InputToFilter row={rowData} field={field} updateRow={updateRow} />

  const updateRow = (index: number, field: string, value: any) => {
    setDinamicFilter((prevRows: any) => {
      // Cria uma cópia dos rows atuais
      const updatedRows = [...prevRows]
      // Atualiza o campo específico na linha com base no índice
      updatedRows[index] = {
        ...updatedRows[index],
        [field]: Number(value[field]),
      }

      // Retorna o array atualizado
      return updatedRows
    })
  }

  const onSubmit = (data: any) => {
    onFilter()
  }

  const MaxPercentTemplate = (rowData: any) => {
    return rowData.higher_value ? <Percent value={rowData.higher_value} /> : '0,0%'
  }

  const MinPercentTemplate = (rowData: any) => {
    return (
      <Percent
        value={rowData.smaller_value === 0 ? '0' : rowData.smaller_value}
      />
    )
  }

  return (
    <>
      <Flex display={'flex'}>
        <Button
          padding="0.5rem 1rem"
          onClick={handleOpenFilterRange}
          containerStyle={{
            backgroundColor: isFilteringRange ? '#38A169' : '#003b74',
          }}
        >
          <FaExchangeAlt />
          <span style={{ whiteSpace: 'nowrap', marginLeft: 10 }}>
            Filtro Dinâmico
          </span>
        </Button>
      </Flex>
      <Modal
        w="70rem"
        isOpen={isFilteringRange}
        title="Filtro Dinâmico"
        onClose={onClose}
        body={
          <VStack w="100%" align="flex-start">
            <Divider style={{ marginTop: -15, marginBottom: 20 }} />
            <DataTable
              // onSort={(e) => onSort(e)}
              // scrollable
              // scrollHeight="flex"
              // paginator
              // rows={15}
              // rowsPerPageOptions={[5, 10, 25, 50]}
              value={dinamicFilter.map((item, i) => ({ ...item, index: i }))}
              // selectionMode="multiple"
              // selection={selectedRows}
              // onSelectionChange={onSelectionChange}
              dataKey="id"
              // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              // emptyMessage="Sem dados"
              filterDisplay="menu"
              // currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
            >
              <Column
                headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
                field="filter"
                header="Filtro"
                style={{ minWidth: '100px', zIndex: '2' }}
                frozen
              ></Column>

              <Column
                headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
                field="smaller_value"
                header="Menor Valor"
                style={{ minWidth: '100px', zIndex: '2' }}
                body={MinPercentTemplate}
                frozen
              ></Column>
              <Column
                headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
                field="until_that"
                header="Abaixo de"
                body={(rowData) =>
                  InputTemplate(rowData, 'until_that', (value) =>
                    updateRow(rowData.index, 'until_that', value)
                  )
                }
                style={{ minWidth: '80px', zIndex: '2' }}
                frozen
              ></Column>
              <Column
                headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
                field="above_that"
                header="Acima de"
                body={(rowData) =>
                  InputTemplate(rowData, 'above_that', (value) =>
                    updateRow(rowData.index, 'above_that', value)
                  )
                }
                style={{ minWidth: '120px', zIndex: '2' }}
                frozen
              ></Column>
              <Column
                headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
                field="higher_value"
                header="Maior Valor"
                body={MaxPercentTemplate}
                style={{ minWidth: '100px', zIndex: '2' }}
                frozen
              ></Column>
              <Column
                headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
                field=""
                header=""
                body={(rowData) => (
                  <Button onClick={() => onSubmit(rowData)}>Buscar</Button>
                )}
                style={{ minWidth: '100px', zIndex: '2' }}
                frozen
              ></Column>
            </DataTable>
          </VStack>
          // <>
          //   <VStack w="100%" align="flex-start" spacing="1rem">
          //     {/* Cabeçalho da tabela */}
          //     <HStack w="100%" spacing="1rem">
          //       <Text w="25%">FILTRO</Text>
          //       <Text w="15%">MENOR VALOR</Text>
          //       <Text w="15%">ABAIXO DE</Text>
          //       <Text w="15%">ACIMA DE</Text>
          //       <Text w="15%">MAIOR VALOR</Text>
          //       <Text w="15%"></Text> {/* Espaço para o botão de buscar */}
          //     </HStack>

          //     {/* Linha 1 */}
          //     <HStack w="100%" spacing="1rem" alignItems="center">
          //       <Text w="25%">Descolamento Real</Text>
          //       <Input w="15%" value="-267,00" readOnly />
          //       <Input w="15%" value="-267,00" readOnly />
          //       <Input w="15%" value="89,50" readOnly />
          //       <Input w="15%" value="89,50" readOnly />
          //       <Button style={{ backgroundColor: '#003b74' }}>
          //         Buscar
          //       </Button>
          //     </HStack>

          //     {/* Linha 2 */}
          //     <HStack w="100%" spacing="1rem" alignItems="center">
          //       <Text w="25%">Margem Bruta Sobre Receita Bruta</Text>
          //       <Input w="15%" value="29,80" readOnly />
          //       <Input w="15%" value="29,80" readOnly />
          //       <Input w="15%" value="216,50" readOnly />
          //       <Input w="15%" value="216,50" readOnly />
          //       <Button style={{ backgroundColor: '#003b74' }}>
          //         Buscar
          //       </Button>
          //     </HStack>

          //     {/* Linha 3 */}
          //     <HStack w="100%" spacing="1rem" alignItems="center">
          //       <Text w="25%">Margem Bruta atual sobre preço bruto atual</Text>
          //       <Input w="15%" value="-40,2" readOnly />
          //       <Input w="15%" value="29,80" readOnly />
          //       <Input w="15%" value="216,50" readOnly />
          //       <Input w="15%" value="35,4" readOnly />
          //       <Button style={{ backgroundColor: '#003b74' }}>
          //         Buscar
          //       </Button>
          //     </HStack>
          //   </VStack>
          // </>
        }
        footer={
          <VStack w="100%" align="flex-start">
            <Divider />
            <Flex w="100%" pt="0.5rem" justifyContent="space-between">
              <Button onClick={onClear}>Limpar</Button>
              <Button onClick={onClose}>Fechar</Button>
            </Flex>
          </VStack>
        }
      />
    </>
  )
}

export default RangeFilterButton
