import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import jwt from 'jwt-decode'
import { IUser } from 'domain/login/IUser'
import api from 'services/api'

interface IUserContext {
  user: IUser | undefined
  userParameters: { read: boolean; write: boolean }
  userGoalsPlan: { read: boolean; write: boolean }
  userScenery: { read: boolean; write: boolean }
  userDiscountScenery: { read: boolean; write: boolean }
  loading: boolean
  isAdmin: boolean
  isZMaster: boolean
  setUser: () => void
  clearUser: () => void
  setLoading: (value: boolean) => void
}

const initialValue: IUserContext = {
  user: undefined,
  userParameters: { read: false, write: false },
  userGoalsPlan: { read: false, write: false },
  userScenery: { read: false, write: false },
  userDiscountScenery: { read: false, write: false },
  loading: false,
  isAdmin: false,
  isZMaster: false,
  setUser: () => undefined,
  clearUser: () => undefined,
  setLoading: () => undefined,
}

export const UserContext = createContext<IUserContext>(initialValue)

interface UserContextProviderProps {
  children: React.ReactNode
}
const UserContextProvider: React.FC<UserContextProviderProps> = ({
  children,
}) => {
  const [state, setState] = useState<IUser>()
  const [isLoading, toggleLoading] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isZMaster, setIsZMaster] = useState(false)
  const [userParameters, setUserParameters] = useState({
    read: false,
    write: false,
  })
  const [userGoalsPlan, setUserGoalsPlan] = useState({
    read: false,
    write: false,
  })
  const [userScenery, setUserScenery] = useState({
    read: false,
    write: false,
  })
  const [userDiscountScenery, setUserDiscountScenery] = useState({
    read: false,
    write: false,
  })

  const decodeToken = (): IUser | undefined => {
    const token = localStorage.getItem('@Prixsia:token')
    if (token) {
      return jwt(token)
    }
  }

  const setUser = useCallback(async () => {
    const tokenDecoded = decodeToken()
    if (tokenDecoded) {
      const res = await api.get(`access-controll/${tokenDecoded.id}`)
      const resData = res.data?.data?.user
      const data = res.data?.data?.user?.roles?.map((role: any) =>
        JSON.parse(role)
      )

      setState({
        name: resData.name,
        login: resData.login,
        email: resData.email,
        status: resData.status,
        profile: resData.profile,
        access: resData.access,
        nivel: resData.nivel,
        nivelValid: resData.nivelValid,
        level: JSON.parse(resData.level),
        category: JSON.parse(resData.category),
        approveDiscount: resData.approve_discount,
        approveTable: resData.approve_table,
        percentualValid: resData?.percentualValid,
        clusters: resData.clusters.map((row: any) => row.value),
        newClusters: resData.clusters,
      })

      setUserParameters({
        read: !!data?.find((item: any) => item.id === 2),
        write: !!data?.find((item: any) => item.id === 6),
      })
      setUserGoalsPlan({
        read: !!data?.find((item: any) => item.id === 8),
        write: !!data?.find((item: any) => item.id === 9),
      })
      setUserScenery({
        read: !!data?.find((item: any) => item.id === 4),
        write: !!data?.find((item: any) => item.id === 3),
      })
      setUserDiscountScenery({
        read: !!data?.find((item: any) => item.id === 5),
        write: !!data?.find((item: any) => item.id === 1),
      })

      const { name, profile } = tokenDecoded
      setIsAdmin(profile.toUpperCase() === 'PRIXSIA')
      setIsZMaster(name.toUpperCase() === 'ZMASTER')
      // setState(tokenDecoded)
    }
  }, [])

  const clearUser = useCallback(() => {
    setState(undefined)
    setIsAdmin(false)
  }, [])

  const setLoading = useCallback((value = false) => {
    toggleLoading(value)
  }, [])

  useEffect(() => {
    setUser()
  }, [setUser])

  const value = useMemo(() => {
    return {
      user: state,
      setUser,
      clearUser,
      setLoading,
      loading: isLoading,
      isAdmin,
      isZMaster,
      userParameters,
      userScenery,
      userDiscountScenery,
      userGoalsPlan,
    }
  }, [
    state,
    setUser,
    clearUser,
    setLoading,
    isLoading,
    isAdmin,
    isZMaster,
    userScenery,
    userParameters,
    userGoalsPlan,
    userDiscountScenery,
  ])

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default React.memo(UserContextProvider)
