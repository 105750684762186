import { Flex, HStack } from '@chakra-ui/react'
import Button from '../../../../components/Button'
import LoadingModalButton from '../../../../components/LoadingModalButton'

interface IProps {
  name: string
  email: string
  password: string
  onSave: () => Promise<void>
  onCancel: () => void
}

const Nav: React.FC<IProps> = ({
  name,
  email,
  password,
  onSave,
  onCancel,
}) => {
  return (
    <Flex w="100%" align="center" justify="space-between">
      <HStack align="center" color="#003b74"></HStack>
      <HStack spacing="1rem">
        <Button onClick={onCancel}>Cancelar</Button>
        <LoadingModalButton
          disable={!name || !email || !password}
          promise={onSave}
          message="Salvando, por favor aguarde..."
        >
          Salvar
        </LoadingModalButton>
      </HStack>
    </Flex>
  )
}

export default Nav
