import { useCallback, useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import Paper from 'components/Paper'
import Button from 'components/Button'

import Header from './Header'
import Inputs from './Inputs'
import { parameters } from './constants'
import { Container, Title, Buttons, TableButton } from './styles'
import { UserContext } from 'state/user-context'

const PriceBaseParameters: React.FC = () => {
  const navigate = useNavigate()
  const { userParameters } = useContext(UserContext)

  const [inputs, setInputs] = useState({})
  const [selected, setSelected] = useState(parameters[0])

  // useEffect(() => {
  //   setInputs({})
  // }, [selected])

  const onEdit = useCallback(() => {
    const tableCoded = window.btoa(window.btoa(selected))
    const inputsCoded = window.btoa(window.btoa(JSON.stringify(inputs)))
    navigate(`/parameters/edit/${tableCoded}@${inputsCoded}`)
  }, [inputs, navigate, selected])

  const onClickParameter = useCallback((param: any) => {
    const tableCoded = window.btoa(window.btoa(param))
    const inputsCoded = window.btoa(window.btoa(JSON.stringify(inputs)))
    navigate(`/parameters/edit/${tableCoded}@${inputsCoded}`)
  }, [inputs, navigate])

  return (
    <Container>
      <Header />
      <Paper containerStyle={{ padding: '2rem' }}>
        <Title>Escolha qual tabela voce quer alterar:</Title>
        <Buttons>
          {parameters.map((param) => (
            <TableButton
              key={param}
              // selected={selected === param}
              selected={true}
              onClick={() => {
                setSelected(param)
                onClickParameter(param)
              }}
            >
              {param}
            </TableButton>
          ))}
        </Buttons>
        <Inputs table={selected} setInputs={setInputs} />
        {/* <Button disable={!userParameters.read} width="8rem" onClick={onEdit}>
          Editar
        </Button> */}
      </Paper>
    </Container>
  )
}

export default PriceBaseParameters
