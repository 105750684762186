import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FaFilter as FilterSvg } from 'react-icons/fa'
import {
  Box,
  Checkbox,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'

import api from 'services/api'
import Button from 'components/Button'
import Autocomplete from 'components/Autocomplete'

import { ICaptain, IChannels, IClusters, IFilters } from './types'
import { UserContext } from 'state/user-context'

interface IProps {
  filters: any
  setIsFiltering: React.Dispatch<React.SetStateAction<boolean>>
  isFiltering: boolean
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const Filters: React.FC<IProps> = ({
  filters,
  setIsFiltering,
  isFiltering,
  setFilters,
  setPage,
  setLoading,
}) => {
  const { user } = useContext(UserContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [pricePointValue, setPricePointValue] = useState<number>(0)
  const [clusterId, setClusterId] = useState<IOption[]>([])
  const [channelId, setChannelId] = useState<IOption[]>([])
  const [captainsId, setCaptainsId] = useState<IOption[]>([])
  const [channelIdOpt, setChannelIdOpt] = useState<IOption[]>([])
  const [clusterIdOpt, setClusterIdOpt] = useState<IOption[]>([])
  const [captainsIdOpt, setCaptainsIdOpt] = useState<IOption[]>([])
  const [classificationOptSelected, setClassificationOptSelected] = useState<
    IOption[]
  >([])
  const toast = useToast()

  const setDefaultData = useCallback(() => {
    setClusterId(filters?.values?.cluster_id)
    setChannelId(filters?.values?.channel_code)
    setCaptainsId(filters?.values?.captain_code)
    setPricePointValue(filters?.is_pricepoint)
  }, [filters])
  useEffect(setDefaultData, [setDefaultData, isOpen])

  const getClusterId = useCallback(() => {
    isOpen &&
      api
        .get('/clusters/all')
        .then((res) => {
          if (user?.clusters?.includes(0)) {
            setClusterIdOpt(
              res.data.data.clusters.map((item: any) => ({
                label: item.name,
                value: item.id,
              }))
            )
          } else {
            setClusterIdOpt(
              res.data.data.clusters
                .filter((item: any) => user?.clusters?.includes(item.id))
                .map((item: any) => ({
                  label: item.name,
                  value: item.id,
                }))
            )
          }
        })
        .catch((error) => {
          if (!toast.isActive('data')) {
            toast({
              id: 'data',
              title: `Erro ao carregar dados, tente novamente: ${error.message}`,
              status: 'error',
            })
          }
        })
  }, [isOpen, toast, user])

  useEffect(getClusterId, [getClusterId])

  useEffect(() => {
    if (clusterIdOpt.length < 1) getClusterId()
  }, [getClusterId, clusterIdOpt])

  const getChannelId = useCallback(() => {
    isOpen &&
      api
        .get('/channels')
        .then((res) => {
          setChannelIdOpt(
            res.data?.data?.channels?.map((v: IChannels) => ({
              label: v?.channel_name,
              value: v?.channel_code,
            }))
          )
        })
        .catch((error) => {
          if (!toast.isActive('data')) {
            toast({
              id: 'data',
              title: `Erro ao carregar dados, tente novamente: ${error.message}`,
              status: 'error',
            })
          }
        })
  }, [isOpen, toast])

  useEffect(() => {
    if (channelIdOpt.length < 1) getChannelId()
  }, [getChannelId, channelIdOpt])

  const getCaptainsId = useCallback(() => {


    isOpen &&
      api
        .get('/relativity-sku/captains')
        .then((res) => {
          setCaptainsIdOpt(
            res.data?.data?.materials?.map((v: ICaptain) => ({
              label: v?.captain_code + ' - ' + v?.description,
              value: v?.captain_code,
            }))
          )
        })
        .catch((error) => {
          if (!toast.isActive('data')) {
            toast({
              id: 'data',
              title: `Erro ao carregar dados, tente novamente: ${error.message}`,
              status: 'error',
            })
          }
        })
  }, [isOpen, toast])

  useEffect(() => {
    if (captainsIdOpt.length < 1) getCaptainsId()
  }, [getCaptainsId, captainsIdOpt])

  const onFilter = useCallback(() => {
    const newFilters: IFilters = {
      values: {
        cluster_id: clusterId,
        captain_code: captainsId,
        channel_code: channelId,
        sensitivity: classificationOptSelected
      },

      is_pricepoint: pricePointValue,
    }
    setIsFiltering(false)
    Object.keys(newFilters.values).forEach((key) => {
      if (newFilters.values[key].length > 0 || newFilters.is_pricepoint === 1)
        setIsFiltering(true)
    })

    setFilters(newFilters)
    onClose()
    setPage(1)
    setLoading(true)
  }, [
    clusterId,
    onClose,
    setFilters,
    setPage,
    channelId,
    captainsId,
    pricePointValue,
    setLoading,
    setIsFiltering,
    classificationOptSelected
  ])

  const onClear = useCallback(() => {
    const newFilters: IFilters = {
      values: {
        cluster_id: [],
        captain_code: [],
        channel_code: [],
        sensitivity: []
      },
      is_pricepoint: 0,
    }

    setIsFiltering(false)

    setFilters(newFilters)
    onClose()
    setPage(1)
    setLoading(true)
  }, [onClose, setFilters, setLoading, setPage, setIsFiltering])

  const classificationOpt = [
    { label: 'MERCADO', value: 'MERCADO' },
    { label: 'MRG. OBJETIVA', value: 'MRG. OBJETIVA' },
  ]

  return (
    <>
      <Flex mb="1rem">
        <Button
          padding="0.5rem 1rem"
          onClick={onOpen}
          containerStyle={{
            backgroundColor: isFiltering ? '#38A169' : '#003b74',
          }}
        >
          <FilterSvg />
        </Button>
      </Flex>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="40rem">
          <ModalHeader>Filtros</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="flex-start">
              <Text>Cluster</Text>
              <Autocomplete
                value={clusterId}
                options={clusterIdOpt}
                setValue={setClusterId}
                isMulti
              />
              {/* <Text>Canal</Text>
              <Autocomplete
                value={channelId}
                options={channelIdOpt}
                setValue={setChannelId}
                isMulti
              /> */}
              <Text>Sku Capitão</Text>
              <Autocomplete
                value={captainsId}
                options={captainsIdOpt}
                setValue={setCaptainsId}
                isMulti
              />
              <Box
                display={'flex'}
                w={'7rem'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Text>Pricepoint: </Text>
                <Checkbox
                  size="lg"
                  isChecked={pricePointValue === 0 ? false : true}
                  onChange={() => {
                    setPricePointValue(pricePointValue === 0 ? 1 : 0)
                  }}
                />
              </Box>
              {/* <Autocomplete
                isMulti
                label="Classificação Produto"
                value={classificationOptSelected}
                options={classificationOpt}
                setValue={setClassificationOptSelected}
              /> */}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <VStack w="100%" align="flex-start">
              <Divider />
              <Flex w="100%" pt="0.5rem" justifyContent="space-between">
                <Button onClick={onClear}>Limpar</Button>
                <Button onClick={onFilter}>Filtrar</Button>
              </Flex>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Filters
