import { AxiosResponse } from 'axios'
import api from '../api'

export const getClusterConcorrenteListService = async (
  request: any,
  page: number,
  limit: number
) => {
  const url = `/cluster-competitor/list`

  const payload = {
    ...request,
    page,
    limit,
  }

  return api
    .post(url, payload)
    .then((response: any) => {
      return response.data
    })
    .catch(() => {
      return []
    })
}
