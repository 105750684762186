import { AxiosResponse } from 'axios'
import api from '../../services/api'

export const getSceneryService = async (id: number) => {
  const url = `/sceneries/${id}`

  return api
    .get(url)
    .then((response: AxiosResponse<any>) => {
      return response.data.data.result[0]
    })
    .catch(() => {
      return []
    })
}
