import React, { useEffect, useMemo, useState } from 'react'

import TableBackend from 'components/TableBackend'

import IRow, { ITableProps } from '../types/IRow'
import Ean from './Ean'
import Indice from './Indice'
import Channel from './Channel'
import DateEnd from './DateEnd'
import DateIni from './DateIni'
import PriceType from './PriceType'
import Pricepoint from './Pricepoint'
import CalculationType from './CalculationType'
import Priceppc from './Priceppc'
import Options from './Options'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

const Table: React.FC<ITableProps> = ({
  dataTable,
  page,
  limit,
  total,
  setPage,
  totalPages,
  getRows,
  loading,
  sort,
  setSort,
  setOrderBy,

  setLimit,
}) => {
  const [order, setOrder] = useState(1)
  const [data, setData] = useState<IRow[]>([])

  useEffect(() => {
    if (dataTable) setData(dataTable)
  }, [dataTable])

  const pricepointTemplate = (rowData: IRow) => {
    return <Pricepoint row={rowData} getRows={getRows} />
  }

  const pricePpcTemplate = (rowData: any) => {
    return <Priceppc row={rowData} getRows={getRows} />
  }

  const researchTemplate = (rowData: any) => {
    return <CalculationType row={rowData} getRows={getRows} />
  }

  const indexTemplate = (rowData: any) => {
    return <Indice row={rowData} getRows={getRows} />
  }

  const strategyTemplate = (rowData: any) => {
    return <Options row={rowData} />
  }

  const initialTemplate = (rowData: any) => {
    return <DateIni row={rowData} getRows={getRows} />
  }

  const finalTemplate = (rowData: any) => {
    return <DateEnd row={rowData} getRows={getRows} />
  }

  const onSort = (event: any) => {
    const { sortField } = event
    setOrderBy(sortField)

    setSort(sort === 1 ? -1 : 1)
  }

  const onPage = (event: any) => {
    setPage(event.page)
    setLimit(event.rows)
  }

  console.log(total, 'total')
  return (
    <DataTable
      scrollable
      scrollHeight="flex"
      paginator
      lazy
      rows={limit}
      rowsPerPageOptions={[5, 10, 25, 50]}
      value={data}
      loading={loading}
      onSort={onSort}
      totalRecords={total}
      first={page * limit}
      onPage={onPage}
      // selectionMode="multiple"
      // selection={selectedRows}
      // onSelectionChange={onSelectionChange}
      dataKey="id"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      emptyMessage="Sem dados"
      filterDisplay="menu"
      currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
    >
      <Column
        headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
        sortable
        field="cluster_name"
        header="Cluster de Preço"
        style={{ minWidth: '100px', zIndex: '2' }}
      ></Column>
      <Column
        headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
        sortable
        field="sku"
        header="SKU Capitão"
        style={{ minWidth: '120px', zIndex: '2' }}
      ></Column>
      <Column
        headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
        sortable
        field="description"
        header="Descrição"
        style={{ minWidth: '200px', zIndex: '2' }}
      ></Column>
      {/* <Column
        headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
        field="sensitivity"
        header="Classificação do Item"
        style={{ minWidth: '50px', zIndex: '2' }}
      ></Column> */}
      <Column
        headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
        field=""
        header="PricePoint"
        body={pricepointTemplate}
        style={{ minWidth: '30px', zIndex: '2' }}
      ></Column>
      <Column
        headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
        field="new_price_ppc"
        header="Preço PPC"
        body={pricePpcTemplate}
        style={{ minWidth: '100px', zIndex: '2' }}
      ></Column>
      <Column
        headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
        field="description"
        body={researchTemplate}
        header="Base de Pesquisa Cálculo"
        style={{ minWidth: '100px', zIndex: '2' }}
      ></Column>
      <Column
        headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
        field="index"
        header="Índice"
        body={indexTemplate}
        style={{ minWidth: '50px', zIndex: '2' }}
      ></Column>
      {/* <Column
        headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
        field="start_date"
        header="Data Início Período"
        body={initialTemplate}
        style={{ minWidth: '50px', zIndex: '2' }}
      ></Column>
      <Column
        headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
        field="end_date"
        header="Data Fim Período"
        body={finalTemplate}
        style={{ minWidth: '50px', zIndex: '2' }}
      ></Column> */}
    </DataTable>
  )
}

export default Table
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠿⠿⠿⠿⠿⠿⣿⣿⣿⣿⣿⣿⣿⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠋⣉⣁⣤⣤⣶⣾⣿⣿⣶⡄⢲⣯⢍⠁⠄⢀⢹⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⢯⣾⣿⣿⣏⣉⣹⠿⠇⠄⠽⠿⢷⡈⠿⠇⣀⣻⣿⡿⣻
// ⣿⣿⡿⠿⠛⠛⠛⢛⡃⢉⢣⡤⠤⢄⡶⠂⠄⠐⣀⠄⠄⠄⠄⠄⡦⣿⡿⠛⡇⣼
// ⡿⢫⣤⣦⠄⠂⠄⠄⠄⠄⠄⠄⠄⠄⠠⠺⠿⠙⠋⠄⠄⠄⠢⢄⠄⢿⠇⠂⠧⣿
// ⠁⠄⠈⠁⠄⢀⣀⣀⣀⣀⣠⣤⡤⠴⠖⠒⠄⠄⠄⠄⠄⠄⠄⠄⠄⠘⢠⡞⠄⣸
// ⡀⠄⠄⠄⠄⠄⠤⠭⠦⠤⠤⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⣂⣿
// ⣷⡀⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⢳⠄⠄⢀⠈⣠⣤⣤⣼⣿
// ⣿⣿⣷⣤⣤⣤⣤⣤⣤⣤⣤⣤⣤⣴⣶⣶⣶⣄⡀⠄⠈⠑⢙⣡⣴⣿⣿⣿⣿⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
