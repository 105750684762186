import scenValue from "utils/scenValue";

const _noValueValidation = (value: any, cases = 2) => {
  return !value || !+value || !isFinite(+value) ? '-' : scenValue(value, cases);
}

export const formatDataChildren = (rawRows: any[]) => {
  return rawRows.map((row: any) => ({
    ...row,
    captain_code: _noValueValidation(row.captain_code).replace(/,00$/, ''),
    ean: _noValueValidation(row.ean),
    sku: _noValueValidation(row.sku).replace(/,00$/, ''),
    classificacao_item: 'RELATIVIDADE',
    tipo_calculo: _noValueValidation(row.tipo_calculo),
    relatividade_sku: _noValueValidation(parseFloat(row.relatividade_sku)),
    mrg_mercado: _noValueValidation(parseFloat(row.mrg_mercado)),
    ppc_s_mrg: _noValueValidation(parseFloat(row.ppc_s_mrg)),
    tipo_imposto: _noValueValidation(row.tipo_imposto),
    icms_destino: _noValueValidation(parseFloat(row.icms_destino)),
    mva: _noValueValidation(parseFloat(row.mva)),
    icms_st: _noValueValidation(parseFloat(row.icms_st)),
    mrg_ppv_objetiv: _noValueValidation(parseFloat(row.mrg_ppv_objetiv)),
    relat_canal: _noValueValidation(parseFloat(row.relat_canal)),
    custo_varejo: _noValueValidation(parseFloat(row.custo_varejo)),
    mrg_min: _noValueValidation(parseFloat(row.mrg_min)),
    ppv_liq_min: _noValueValidation(parseFloat(row.ppv_liq_min)),
    mrg_max: _noValueValidation(parseFloat(row.mrg_max)),
    ppv_liq_max: _noValueValidation(parseFloat(row.ppv_liq_max)),
    ppv_novo: _noValueValidation(parseFloat(row.ppv_novo)),   
    icms_origem: _noValueValidation(parseFloat(row.icms_origem)),
    ipi: _noValueValidation(parseFloat(row.ipi)),
    ppv_bruto_novo: _noValueValidation(parseFloat(row.ppv_bruto_novo)),
    novo_ppc_s_mrg: _noValueValidation(parseFloat(row.novo_ppc_s_mrg)),
    novo_ppc: _noValueValidation(parseFloat(row.novo_ppc)),
    desconto: _noValueValidation(parseFloat(row.desconto)),
    desc_alcada: _noValueValidation(parseInt(row.desc_alcada)),
    ppv_bruto_novo_c_desc: _noValueValidation(parseFloat(row.ppv_bruto_novo_c_desc)),
    ppv_liq_novo_c_desc: _noValueValidation(parseFloat(row.ppv_liq_novo_c_desc)),
    recup_impostos: _noValueValidation(parseFloat(row.recup_impostos)),
    comissoes: _noValueValidation(parseFloat(row.comissoes)),
    bonificacoes: _noValueValidation(parseFloat(row.bonificacoes)),
    investimento_rs: _noValueValidation(parseFloat(row.investimento_rs)),
    investimento_pc: _noValueValidation(parseFloat(row.investimento_pc)),
    contrato: _noValueValidation(parseFloat(row.contrato)),
    frete: _noValueValidation(parseFloat(row.frete)),
    custo_medio_produto: _noValueValidation(parseFloat(row.custo_medio_produto)),
    lucro_bruto_c_recup_imp: _noValueValidation(parseFloat(row.lucro_bruto_c_recup_imp)),
    lucro_bruto_pc: _noValueValidation(parseFloat(row.lucro_bruto_pc)),
    status: row.status
  }));
};
