import { Column } from 'primereact/column'
import Money from './Money'
import Percent from './Percent'
import PPVFinal from './PPVFinal'
import Volume from './Volume'
import { FaEye } from 'react-icons/fa'

const MoneyTemplate = (rowData: any, field: any) => {
  return rowData[field] ? <Money value={rowData[field]} /> : '    -'
}

const PercentTemplate = (rowData: any, field: any) => {
  return rowData[field] ? (
    <Percent value={!rowData[field] ? '0,0' : rowData[field]} />
  ) : (
    '-'
  )
}

const VolumeTemplate = (rowData: any, field: any) =>
  rowData[field] ? <Volume value={rowData[field]} /> : '-'

const DefaultTemplate = (rowData: any, field: any) => {
  return rowData[field] ? rowData[field] : '-'
}

const ViewTemplate = (
  rowData: any,
  field: any,
  handleOpenCompetitor: (rowData: any) => void,
  type: string
) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}>
      <span style={{ marginRight: 20 }}>
        {!rowData[field] ? '-' : <Money value={rowData[field]} />}
      </span>
      {/* {type !== 'trading_condition' && (
        <FaEye size={16} onClick={() => handleOpenCompetitor(rowData)} />
      )} */}
      <FaEye size={16} onClick={() => handleOpenCompetitor(rowData)} />
    </div>
  )
}

const PPVFinalTemplate = (
  rowData: any,
  field: any,
  getRows: () => void,
  updateRow: (newRow: Partial<any>) => void,
  toggleBigData: () => void,
  inputsRef: React.MutableRefObject<any[]>
) => (
  <PPVFinal
    row={rowData}
    rowIndex={rowData[field]}
    inputsRef={inputsRef}
    getRows={getRows}
    updateRow={updateRow}
    toggleBigData={toggleBigData}
  />
)

const getColumnTemplate = (
  field: any,
  getRows: () => void,
  updateRow: (newRow: Partial<any>) => void,
  toggleBigData: () => void,
  inputsRef: React.MutableRefObject<any[]>,
  type: string,
  handleOpenCompetitor: (rowData: any, field: string) => void
) => {
  switch (field) {
    case 'preco_concorrencia_consumidor':
    case 'preco_concorrencia_consumidor_geral':
      return (rowData: any) =>
        ViewTemplate(
          rowData,
          field,
          () => handleOpenCompetitor(rowData, field),
          type
        )
    case 'novo_ppc_c_desc':
      return (rowData: any) => VolumeTemplate(rowData, field)
    case 'custo_medio_produto':
    case 'ppc_s_mrg':
    case 'novo_ppc':
    case 'ppc_base':
    case 'ppc_index':
    case 'ppc_relativo':
    case 'custo_varejo':
    case 'ppv_liq_min':
    case 'ppv_liq_atual':
    case 'ppv_bruto_novo':
    case 'novo_ppc_s_mrg':
    case 'faturamento':
    case 'faturamento_liquido':
    case 'lucro_liquido':
    case 'preco_alvo_consumidor':
    case 'preco_relativo_sku':
    case 'preco_alvo_s_mkp':
    case 'preco_bruto_atual':
    case 'lucro_bruto_atual':
    case 'preco_liquido_sugestao':
    case 'lucro_bruto_tabela_sugerida':
    case 'base_cal_pis_cofins':
    case 'valor_mva':
    case 'valor_icms':
    case 'preco_bruto_cst':
    case 'lucro_bruto_sobre_receita_bruta':
    case 'novo_ppc_c_mkp':
    case 'preco_embalagem_atual':
    case 'preco_embalagem_sugestao':
    case 'custo_frete':
    case 'frete':
    case 'preco_mercado_novo':
    case 'preco_bruto_sugestao_embalagem':
    case 'lucro_bruto_sobre_receita_bruta_sugestao':
    case 'lucro_bruto_sugestao':
    case 'preco_bruto_embalagem_tabela_atual':
    case 'ppc_atual':
      return (rowData: any) => MoneyTemplate(rowData, field)
    case 'mrg_atual':
    case 'mrg_ppv_objetiv':
    case 'margem_bruta_sobre_receita_bruta':
    case 'mrg_ppv_novo':
    case 'mrg_mercado':
    case 'indice_meta':
    case 'icms_destino':
    case 'mva':
    case 'relat_canal':
    case 'mrg_min':
    case 'mrg_max':
    case 'icms_origem':
    case 'ipi':
    case 'desconto':
    case 'acoes_comerciais':
    case 'bonificacoes':
    case 'indice_final':
    case 'pis_cofins_origem':
    case 'pis_cofins_destino':
    case 'relatividade_sku':
    case 'relatividade_canal':
    case 'mkp_da_cadeia_2':
    case 'mrg_bruta_meta':
    case 'icms_st':
    case 'mrg_bruta_atual':
    case 'margem_bruta_sobre_receita_liquida':
    case 'relativ_canal':
    case 'mkp_da_cadeia':
    case 'margem_contribuicao':
    case 'descolamento_real':
    case 'variacao_preco':
    case 'aumento_reducao_atual':
    case 'descolamento_meta':
    case 'margem_bruta_sobre_receita_bruta_atual':
    case 'descolamento_ppc_atual':
    case 'descolamento_concorrencia_p1':
    case 'descolamento_concorrencia_geral':
    case 'comissao':
      return (rowData: any) => PercentTemplate(rowData, field)
    case 'preco_bruto_sugestao':
      return (rowData: any) =>
        PPVFinalTemplate(
          rowData,
          field,
          getRows,
          updateRow,
          toggleBigData,
          inputsRef
        )
    default:
      return (rowData: any) => DefaultTemplate(rowData, field)
  }
}

export const createDynamicColumns = (
  columns: any[],
  getRows: () => void,
  updateRow: (newRow: Partial<any>) => void,
  toggleBigData: () => void,
  inputsRef: React.MutableRefObject<any[]>,
  type: string,
  handleOpenCompetitor: (rowData: any, field: string) => void
) => {
  return columns.map((col, index) => {
    const headerStyle =
      col.style && col.style.headerStyle ? col.style.headerStyle : {}
    const bodyStyle =
      col.style && col.style.bodyStyle ? col.style.bodyStyle : {}
    return (
      <Column
        style={{
          minWidth:
            col.style && col.style.minwidth ? col.style.minwidth : '120px',
        }}
        key={index}
        field={col.field}
        frozen={col.frozen}
        sortable
        header={col.headerComponent ? col.headerComponent() : col.header}
        body={getColumnTemplate(
          col.field,
          getRows,
          updateRow,
          toggleBigData,
          inputsRef,
          type,
          handleOpenCompetitor
        )}
        headerStyle={headerStyle}
        bodyStyle={bodyStyle}
      />
    )
  })
}
