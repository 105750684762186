import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import moment from 'moment'

import TableBackend from 'components/TableBackend'
import IPaginationOptions from 'components/TableBackend/types/IPaginationOptions'

import IRow from '../types/IRow'
import Options from './Options'
import { DataTable, SortOrder } from 'primereact/datatable'
import { Column } from 'primereact/column'

interface IProps extends IPaginationOptions {
  rows: IRow[]
  setOrderBy: Dispatch<SetStateAction<string>>
  orderBy: string
  sort: SortOrder
  setSort: Dispatch<SetStateAction<SortOrder>>
  setLimit: Dispatch<SetStateAction<number>>
}

const Table: React.FC<IProps> = ({
  rows,
  page,
  limit,
  total,
  setPage,
  totalPages,
  setOrderBy,
  orderBy,
  sort,
  setSort,
  setLimit,
}) => {
  const [data, setData] = useState<IRow[]>([])

  useEffect(() => {
    setData(rows)
  }, [rows])

  const dateTemplate = (rowData: any) => {
    return moment(rowData.date).format('DD/MM/yyyy')
  }

  const optionsTemplate = (rowData: any) => {
    return <Options row={rowData} />
  }

  const onSort = (event: any) => {
    const { sortField } = event

    setData((prevData) => {
      const sortedData = [...prevData].sort((a: any, b: any) => {
        const aValue = a[sortField]
        const bValue = b[sortField]

        if (aValue == null) return sort === 1 ? 1 : -1
        if (bValue == null) return sort === 1 ? -1 : 1

        if (aValue < bValue) return sort === 1 ? -1 : 1
        if (aValue > bValue) return sort === 1 ? 1 : -1

        return 0
      })

      return sortedData
    })

    setOrderBy(sortField)
    setSort(sort === 1 ? -1 : 1)
  }

  const onPage = (event: any) => {
    setPage(event.page)
    setLimit(event.rows)
  }

  return (
    <DataTable
      onSort={onSort}
      lazy
      sortField={orderBy}
      sortOrder={sort}
      onPage={onPage}
      totalRecords={total}
      first={page * limit}
      scrollable
      scrollHeight="flex"
      paginator
      rows={limit}
      rowsPerPageOptions={[5, 10, 25, 50]}
      value={data}
      // selectionMode="multiple"
      // selection={selectedRows}
      // onSelectionChange={onSelectionChange}
      dataKey="id"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      emptyMessage="Sem dados"
      filterDisplay="menu"
      currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
    >
      <Column
        headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
        sortable
        field="id"
        header="ID"
        style={{ minWidth: '100px', zIndex: '2' }}
      ></Column>
      <Column
        headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
        sortable
        field="name"
        header="Nome"
        style={{ minWidth: '120px', zIndex: '2' }}
      ></Column>
      <Column
        headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
        sortable
        field="type"
        header="Tipo"
        style={{ minWidth: '200px', zIndex: '2' }}
      ></Column>
      <Column
        headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
        sortable
        field="date"
        header="Data"
        body={dateTemplate}
        style={{ minWidth: '60px', zIndex: '2' }}
      ></Column>
      <Column
        headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
        field=""
        header=""
        body={optionsTemplate}
        style={{ minWidth: '80px', zIndex: '2' }}
      ></Column>
    </DataTable>
    // <TableBackend
    //   rows={rows}
    //   cols={cols}
    //   page={page}
    //   limit={limit}
    //   total={total}
    //   setPage={setPage}
    //   totalPages={totalPages}
    // />
  )
}

export default Table
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠿⠿⠿⠿⠿⠿⣿⣿⣿⣿⣿⣿⣿⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠋⣉⣁⣤⣤⣶⣾⣿⣿⣶⡄⢲⣯⢍⠁⠄⢀⢹⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⢯⣾⣿⣿⣏⣉⣹⠿⠇⠄⠽⠿⢷⡈⠿⠇⣀⣻⣿⡿⣻
// ⣿⣿⡿⠿⠛⠛⠛⢛⡃⢉⢣⡤⠤⢄⡶⠂⠄⠐⣀⠄⠄⠄⠄⠄⡦⣿⡿⠛⡇⣼
// ⡿⢫⣤⣦⠄⠂⠄⠄⠄⠄⠄⠄⠄⠄⠠⠺⠿⠙⠋⠄⠄⠄⠢⢄⠄⢿⠇⠂⠧⣿
// ⠁⠄⠈⠁⠄⢀⣀⣀⣀⣀⣠⣤⡤⠴⠖⠒⠄⠄⠄⠄⠄⠄⠄⠄⠄⠘⢠⡞⠄⣸
// ⡀⠄⠄⠄⠄⠄⠤⠭⠦⠤⠤⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⣂⣿
// ⣷⡀⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⢳⠄⠄⢀⠈⣠⣤⣤⣼⣿
// ⣿⣿⣷⣤⣤⣤⣤⣤⣤⣤⣤⣤⣤⣴⣶⣶⣶⣄⡀⠄⠈⠑⢙⣡⣴⣿⣿⣿⣿⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
