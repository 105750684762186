import { useCallback } from 'react'
import { Flex, Text } from '@chakra-ui/react'

import Paper from 'components/Paper'
import scenValue from 'utils/scenValue'

import IData from '../types/IData'

interface IProps {
  info: IData
}

const Card: React.FC<IProps> = ({ info }) => {
  const invalid = useCallback((value: string | number) => {
    return +(value + '').replace(',', '.') ? false : '-'
  }, [])

  const localeNumber = useCallback((value: string) => {
    let newVal = Intl.NumberFormat('pt-BR').format(+value.replace(',', '.'))
    if (!newVal.includes(',')) newVal += ',00'
    if (newVal.split(',')[1].length === 1) newVal += '0'
    return newVal
  }, [])
  return (
    <Paper containerStyle={{ width: '100%', maxWidth: '30rem' }}>
      <Flex flexDir="column" align="center" color="#003b74">
        <Text fontSize="lg" fontWeight={'bold'}>{info.title}</Text>
        {/* <Text color={`${info.color}.500`} fontSize="5xl" fontWeight="bold">
          {invalid(info.investimento) ||
            `R$ ${localeNumber(scenValue(info.investimento))}`}
        </Text> */}
        <Flex w="100%" justify="space-between">
          <Text>Preço Médio Período</Text>
          <Text fontWeight="bold" textAlign="right">
            {invalid(info.preco_medio_periodo) ||
              `R$ ${String(info.preco_medio_periodo).replace('.', ',')}`}
          </Text>
        </Flex>
        <Flex w="100%" justify="space-between">
          <Text>Preço Médio Atual</Text>
          <Text fontWeight="bold" textAlign="right">
            {invalid(info.preco_medio_periodo) ||
              `R$ ${String(info.preco_medio_atual).replace('.', ',')}`}
          </Text>
        </Flex>

        <Flex w="100%" justify="space-between">
          <Text>Preço Médio Novo</Text>
          <Text fontWeight="bold" textAlign="right">
            {invalid(info.preco_medio_periodo) ||
              `R$ ${String(info.preco_medio_novo).replace('.', ',')}`}
          </Text>
        </Flex>

        <Flex w="100%" justify="space-between">
          <Text>Margem Atual</Text>
          <Text fontWeight="bold" textAlign="right">
            {invalid(info.margem_atual) || `R$ ${info.margem_atual} %`}
          </Text>
        </Flex>

        <Flex w="100%" justify="space-between">
          <Text>Margem Novo</Text>
          <Text fontWeight="bold" textAlign="right">
            {invalid(info.margem_novo) || `R$ ${info.margem_novo} %`}
          </Text>
        </Flex>
      </Flex>
    </Paper>
  )
}

export default Card
