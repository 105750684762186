import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from 'services/api'
import Paper from 'components/Paper'
import LoadingScreen from 'components/LoadingScreen'
import IFilters from '../types/IFilters'
import MainTable from './MainTable'
import { IRow } from './types'
import { SortOrder } from 'primereact/datatable'

interface IProps {
  filters: IFilters
  toggleBigData: () => void
  typeView: string
  handleRefreshVerifyStatus: (status: boolean) => void
  newColumns: any
}

const Table: React.FC<IProps> = ({
  filters,
  toggleBigData,
  typeView,
  handleRefreshVerifyStatus,
  newColumns,
}) => {
  const { id } = useParams()

  const [dataTable, setDataTable] = useState<Record<string, unknown>[]>([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [selectedRows, setSelectedRows] = useState([])
  const ppvInputsRef = useRef<any[]>([])
  const discountInputsRef = useRef<any[]>([])
  const quantVendaInputsRef = useRef<any[]>([])
  const [allSelected, setAllSelected] = useState(false)
  const [sort, setSort] = useState<SortOrder>(1)
  const [rows, setRows] = useState(100)
  const [orderBy, setOrderBy] = useState('id')
  const [totalRecords, setTotalRecords] = useState('0')

  useEffect(() => {
    const initialSelected: any = dataTable.filter(
      (row: any) => row.status === 'S'
    )
    setSelectedRows(initialSelected)
  }, [dataTable])

  const updateRow = (newRow: Partial<IRow>) => {
    setDataTable((prevRows: any) =>
      prevRows.map((row: any) =>
        String(row.id) === String(newRow.id) ? { ...row, ...newRow } : row
      )
    )
  }

  useEffect(() => {
    if (selectedRows.length > 0) {
      handleRefreshVerifyStatus(true)
    } else {
      handleRefreshVerifyStatus(false)
    }
  }, [selectedRows, handleRefreshVerifyStatus])

  useEffect(() => {
    ppvInputsRef.current = ppvInputsRef.current.slice(0, dataTable?.length)
    discountInputsRef.current = discountInputsRef.current.slice(
      0,
      dataTable?.length
    )
    quantVendaInputsRef.current = quantVendaInputsRef.current.slice(
      0,
      dataTable?.length
    )
  }, [dataTable?.length])

  useEffect(() => {
    setPage(1)
  }, [filters])

  const getRows = useCallback(() => {
    setLoading(true)
    const url = `/sceneries/${id}/items/B?page=${page === 0 ? 1 : page + 1}&limit=${rows}`
    const request: { [key: string]: string[] } = {}

    Object.keys(filters).forEach((key) => {
      request[key] = filters[key]?.map((v: IOption) => v.value) || []
    })

    api
      .post(url, {
        ...request,
        sort: sort === 1 ? 'asc' : 'desc',
        order_by: orderBy,
      })
      .then((res) => {
        setDataTable(res.data.data.data.payload)
        setTotalRecords(res.data.data.data.total)
        setLoading(false)
      })
  }, [filters, id, page, sort, orderBy, rows])

  useEffect(() => {
    getRows()
  }, [sort, orderBy, page, filters, getRows, rows])

  useEffect(getRows, [getRows])

  const inputsRef = useRef<any[]>([])

  useEffect(() => {
    inputsRef.current = inputsRef.current.slice(0, dataTable?.length)
  }, [dataTable?.length])

  const handleHeaderCheckboxChange = () => {
    const newSelection: any = allSelected
      ? []
      : dataTable.map((row) => ({ ...row, status: 'S' }))
    setSelectedRows(newSelection)

    const updatedRows = dataTable.map((row) => ({
      ...row,
      status: allSelected ? '' : 'S',
    }))
    setDataTable(updatedRows)

    const url = '/sceneries/all-items-discount'
    api.put(url, {
      ids: updatedRows.map((item: any) => item.id),
      status: allSelected ? '' : 'S',
    })

    toggleBigData()

    setAllSelected(!allSelected)
  }

  const onCheckboxChange = (e: any, rowData: any) => {
    const newStatus = rowData.status === '' || !rowData.status ? 'S' : ''
    const req = { id: Number(rowData.id), status: newStatus }

    const updatedRows = dataTable.map((row) => {
      if (row.id === rowData.id) {
        return { ...row, status: newStatus }
      }
      return row
    })
    setDataTable(updatedRows)

    const updatedSelection: any = newStatus
      ? [...selectedRows, { ...rowData, status: newStatus }]
      : selectedRows.filter((row: any) => row.id !== rowData.id)
    setSelectedRows(updatedSelection)

    api.put('/sceneries/item-discount', req).then(() => {
      toggleBigData()
    })
  }

  return (
    <Paper>
      <MainTable
        loading={loading}
        dataTable={dataTable}
        getRows={getRows}
        toggleBigData={toggleBigData}
        inputsRef={inputsRef}
        selectedRows={selectedRows}
        onSelectionChange={onCheckboxChange}
        updateRow={updateRow}
        handleHeaderCheckboxChange={handleHeaderCheckboxChange}
        allSelected={allSelected}
        newColumns={newColumns}
        totalRecords={totalRecords}
        sort={sort}
        setSort={setSort}
        setOrderBy={setOrderBy}
        orderBy={orderBy}
        setPage={setPage}
        setRows={setRows}
        rows={rows}
        page={page}
        
      />
    </Paper>
  )
}

export default memo(Table)
