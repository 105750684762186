import { memo, useState, useEffect } from 'react'
import { Checkbox, Grid, Text, VStack } from '@chakra-ui/react'
import { v4 as uuid } from 'uuid'
import { labelDict } from './constants'
import Autocomplete from 'components/Autocomplete'
import { INewClient } from '..'

interface IOption {
  label: string
  value: string
}

interface IProps {
  type: string
  options: { [key: string]: IOption[] }
  filters: { [key: string]: IOption[] }
  clients: INewClient[]
  setFilters: React.Dispatch<React.SetStateAction<{ [key: string]: IOption[] }>>
}

const Filters: React.FC<IProps> = ({
  type,
  options,
  filters,
  setFilters,
  clients,
}) => {
  const [chooseClients, setChooseClient] = useState<boolean>(false)
  const [newClients, setNewClients] = useState<IOption[]>([])

  useEffect(() => {
    if (filters?.channel?.length < 1) {
      setNewClients(
        clients.map((item: INewClient) => ({
          value: item.client_code,
          label: item.client_name,
        }))
      )
    } else {
      const filteredClients = clients.filter((client: any) => {
        const channelMatch =
          !filters.channel ||
          filters.channel
            .map((value) => value.value)
            .includes(client.channel_code)

        return channelMatch
      })

      const uniqueClients = new Set<number>()

      const newClients: IOption[] = filteredClients.reduce(
        (acc: IOption[], item: any) => {
          if (item.client_name && !uniqueClients.has(item.client_code)) {
            uniqueClients.add(item.client_code)
            acc.push({
              label: item.client_name,
              value: item.client_code,
            })
          }
          return acc
        },
        []
      )

      setNewClients(newClients)
    }
  }, [clients, filters.channel])

  const { cluster, channel, ...filteredOptions } = options

  // if (type === 'TABELA') return null

  return (
    <>
      <Grid w="100%" templateColumns="repeat(2, 1fr)" gap="1rem">
        <VStack key={uuid()} w="100%" align="flex-start">
          <Text>{labelDict['channel']}</Text>
          <Autocomplete
            isMulti
            value={filters['channel']}
            options={options['channel']}
            setValue={(newVal: IOption[]) => {
              setFilters((current) => {
                return { ...current, ['channel']: newVal }
              })
            }}
          />
        </VStack>
        <VStack key={uuid()} w="100%" align="flex-start">
          <Text>{labelDict['cluster']}</Text>
          <Autocomplete
            isMulti
            value={filters['cluster']}
            options={options['cluster']}
            setValue={(newVal: IOption[]) => {
              setFilters((current) => {
                return { ...current, ['cluster']: newVal }
              })
            }}
          />
        </VStack>
      </Grid>
      {type === 'TABELA' ? null : (
        <VStack key={uuid()} w="100%" align="flex-start">
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <div
              style={{ display: 'flex', flexDirection: 'column', width: '12%' }}
            >
              <span style={{ marginBottom: 20 }}>Selecionar Cliente</span>
              <Checkbox
                sx={{
                  '.chakra-checkbox__control': {
                    borderColor: 'black',
                  },
                }}
                colorScheme="green"
                size="lg"
                isChecked={chooseClients}
                onChange={() => setChooseClient(!chooseClients)}
              />
            </div>

            <div style={{ display: 'flex', marginLeft: 15, width: '88%' }}>
              <Autocomplete
                isMulti
                disabled={!chooseClients}
                label={'Clientes'}
                value={filters.client}
                options={newClients}
                setValue={(newVal: IOption[]) => {
                  setFilters((current) => {
                    return { ...current, client: newVal }
                  })
                }}
              />
            </div>
          </div>
        </VStack>
      )}
      <Grid w="100%" templateColumns="repeat(2, 1fr)" gap="1rem">
        {Object.keys(filteredOptions).map((key) => (
          <VStack key={uuid()} w="100%" align="flex-start">
            <Text>{labelDict[key]}</Text>
            <Autocomplete
              isMulti
              value={filters[key]}
              options={options[key]}
              setValue={(newVal: IOption[]) => {
                setFilters((current) => {
                  return { ...current, [key]: newVal }
                })
              }}
            />
          </VStack>
        ))}
      </Grid>

      {/* <Grid w="100%" templateColumns="repeat(2, 1fr)" gap="1rem">
        <VStack key={uuid()} w="100%" align="flex-start">
          <Text>{labelDict['nivel01']}</Text>
          <Autocomplete
            isMulti
            value={filters['nivel01']}
            options={options['nivel01']}
            setValue={(newVal: IOption[]) => {
              setFilters((current) => {
                return { ...current, ['nivel01']: newVal }
              })
            }}
          />
        </VStack>
        <VStack key={uuid()} w="100%" align="flex-start">
          <Text>{labelDict['nivel02']}</Text>
          <Autocomplete
            isMulti
            value={filters['nivel02']}
            options={options['nivel02']}
            setValue={(newVal: IOption[]) => {
              setFilters((current) => {
                return { ...current, ['nivel02']: newVal }
              })
            }}
          />
        </VStack>
      </Grid> */}
    </>
  )
}

export default memo(Filters)
