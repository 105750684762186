import React, { useEffect, useState } from 'react'
import { VStack, useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import Nav from './Nav'
import Access from './Access'
import MainInputs from './MainInputs'
import { Roles } from 'pages/UsersEdit/Inputs/useEdit/types'
import { Values } from './Access/Approvals/UseSlider/types'
import { initialRoles, sliderInitialValues } from '../Types/initalValues'
import { createUserService } from '../Service/create-user-service'
import ModalConfirmNewUser from '../Components/ModalConfirmNewUser'
import { ApproveType } from './Access/Level'

const nivelDefault = {
  nivel1: {
    checked: true,
    percentage: 0,
  },
  nivel2: {
    checked: false,
    percentage: 0,
  },
  nivel3: {
    checked: false,
    percentage: 0,
  },
}

interface NivelProps {
  checked: boolean
  percentage: number
}

const Inputs: React.FC = () => {
  const navigate = useNavigate()
  const toast = useToast()

  const initialApproveType = {
    table: false,
    discount: false,
  }

  const [name, setName] = useState<string>('')
  const [roles, setRoles] = useState<Roles[]>(initialRoles)
  const [nivel, setNivel] = useState<{
    nivel1: NivelProps
    nivel2: NivelProps
    nivel3: NivelProps
  }>(nivelDefault)
  const [email, setEmail] = useState<string>('')
  const [access, setAccess] = useState<boolean>(false)
  const [active, setActive] = useState<boolean>(true)
  const [enrollment, setEnrollment] = useState<string>('')
  const [approvalScopes, setApprovalScopes] =
    useState<Values[]>(sliderInitialValues)
  const [clusters, setClusters] = useState<IOption[]>([])
  const [level, setLevel] = useState<any>()
  const [category, setCategory] = useState<IOption[]>([])
  const [openNewUser, setOpenNewUser] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('Prixsia@2024')
  const [approveType, setApproveType] =
    useState<ApproveType>(initialApproveType)

  // useEffect(() => {
  //   if (nivel > approvalScopes?.length) {
  //     const newScopes = Array.from(
  //       { length: nivel - approvalScopes?.length },
  //       () => ({ initial: 0, final: 20, level: nivel })
  //     )
  //     setApprovalScopes((prevScopes) => [...prevScopes, ...newScopes])
  //   }

  //   if (nivel < approvalScopes?.length) {
  //     setApprovalScopes((prevScopes) => prevScopes.slice(0, nivel))
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [nivel])

  const onSave = async (): Promise<void> => {

    await createUserService({
      name,
      roles,
      nivel: JSON.stringify(nivel),
      email,
      access,
      active: true,
      enrollment,
      approvalScopes,
      approveTable: approveType.table,
      approveDiscount: approveType.discount,
      clusters: clusters?.map((item) => item.value),
      level: JSON.stringify(level),
      category: JSON.stringify(category),
      password,
    }).then((result: { status: number; message: string }) => {
      if (result.status === 200) {
        setOpenNewUser(true)
        // navigate('/users')
        toast({
          title: result.message,
          status: 'success',
        })
      } else {
        toast({
          title: result.message,
          status: 'error',
        })
      }
    })
  }

  const onCancel = () => {
    navigate('/users')
    setOpenNewUser(false)
  }

  const handleNewUser = () => {
    setApprovalScopes(sliderInitialValues)
    setClusters([])
    setCategory([])
    setLevel('')
    setName('')
    setApproveType(initialApproveType)
    setEmail('')
    setPassword('')
    setNivel(nivelDefault)
    setRoles(initialRoles)
    setActive(false)
    setEnrollment('')
    setOpenNewUser(false)
    navigate('/users/register')
  }

  return (
    <VStack w="100%" align="flex-start" spacing="1rem">
      <Nav
        name={name}
        email={email}
        onSave={onSave}
        onCancel={onCancel}
        password={password}
      />
      <MainInputs
        name={name}
        email={email}
        access={access}
        active={active}
        enrollment={enrollment}
        setName={setName}
        setEmail={setEmail}
        setAccess={setAccess}
        setActive={setActive}
        setEnrollment={setEnrollment}
        password={password}
        setPassword={(e) => setPassword(e)}
      />
      <Access
        nivel={nivel}
        roles={roles}
        active={active}
        setNivel={setNivel}
        setRoles={setRoles}
        // handleChangeSliderValues={handleChangeSliderValues}
        // sliderValues={approvalScopes}
        setClusters={setClusters}
        setCategory={setCategory}
        category={category}
        level={level}
        setLevel={setLevel}
        clusters={clusters}
        setApproveType={setApproveType}
        approveType={approveType}
      />
      <ModalConfirmNewUser
        isOpen={openNewUser}
        onClose={() => setOpenNewUser(false)}
        onConfirm={handleNewUser}
        onCancel={onCancel}
      />
    </VStack>
  )
}

export default Inputs
