import { useCallback, useEffect, useState } from 'react'

import Cards from './Cards'
import Table from './Table'
import Header from './Header'
import IFilters from './types/IFilters'
import { BodyContent, Container, HeadContent } from './styles'
import { colsSimple } from './Table/dynamicColumns'
import { SortOrder } from 'primereact/datatable'

const PriceBaseTable: React.FC = () => {
  const [filters, setFilters] = useState({} as IFilters)
  const [typeView, setTypeView] = useState<string>('simple')
  const [getBigData, setGetBigData] = useState<boolean>(false)
  const [refreshVerifyStatus, setRefreshVerifyStatus] = useState(false)
  const [ids, setIds] = useState<number[]>([])
  const [refreshBigNumbers, setRefreshBigNumbers] = useState<boolean>(false)
  const [columns, setColumns] = useState(colsSimple)
  const [newColumns, setNewColumns] = useState(colsSimple)
  const [dinamicFilterData, setDinamicFilterData] = useState()
  const [showBigNumbers, setShowBigNumbers] = useState<boolean>(true)
  const [sort, setSort] = useState<string>('asc')
  const [orderBy, setOrderBy] = useState<string>('id')
  const [usingFilters, setUsingFilters] = useState<boolean>(true)
  const [filterToUpdateBulk, setFilterToUpdateBult] = useState<any>({})
  const [refreshTable, setRefreshTable] = useState<boolean>(false)

  const toggleBigData = useCallback(() => {
    setGetBigData((current) => !current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [headHeight, setHeadHeight] = useState('33%')

  useEffect(() => {
    const updateScrollHeight = () => {
      const height = window.innerHeight
      console.log(height, 'height')
      if (height < 910) {
        setHeadHeight('39%')
      } else if (height > 910 && height < 1000) {
        setHeadHeight('35%')
      } else {
        setHeadHeight('30%')
      }
    }
    updateScrollHeight()
  }, [])

  return (
    <Container>
      <HeadContent style={{ height: showBigNumbers ? headHeight : '8%' }}>
        <Header
          filters={filters}
          setFilters={setFilters}
          typeView={typeView}
          setTypeView={setTypeView}
          refreshVerifyStatus={refreshVerifyStatus}
          columns={columns}
          newColumns={newColumns}
          setNewColumns={setNewColumns}
          dinamicFilterData={dinamicFilterData}
          idsToExport={ids}
          sort={sort}
          orderBy={orderBy}
          setUsingFilters={setUsingFilters}
          usingFilters={usingFilters}
          filterToUpdateBulk={filterToUpdateBulk}
          handleRefreshTable={() => setRefreshTable(!refreshTable)}
        />
        <Cards
          refreshBigNumbers={refreshBigNumbers}
          getBigData={getBigData}
          ids={ids}
          typeView={typeView}
          filter={filters}
          showBigNumbers={showBigNumbers}
          setShowBigNumbers={setShowBigNumbers}
        />
      </HeadContent>

      <BodyContent
        style={{
          height: showBigNumbers
            ? `${Number(headHeight.slice(0, 2)) - 100}% `
            : '92%',
        }}
      >
        <Table
          setDinamicFilterData={setDinamicFilterData}
          newColumns={newColumns}
          setColumns={setColumns}
          handleRefresh={() => setRefreshBigNumbers(!refreshBigNumbers)}
          handleSetIds={setIds}
          filters={filters}
          typeView={typeView}
          toggleBigData={toggleBigData}
          handleRefreshVerifyStatus={(e) => setRefreshVerifyStatus(e)}
          showBigNumbers={showBigNumbers}
          handleEraseFilters={() =>
            console.log('ISSO RESETARIA TODOS OS FILTROS')
          }
          handleSortOrderExport={(sort: string, orderBy: string) => {
            setOrderBy(orderBy), setSort(sort)
          }}
          setFilterToUpdateBult={setFilterToUpdateBult}
          refreshTable={refreshTable}
        />
      </BodyContent>
    </Container>
  )
}

export default PriceBaseTable
