import { Column } from 'primereact/column'
import Money from './Money'
import Percent from './Percent'
import Volume from 'pages/PriceBaseTable/Table/Volume'
import PPVFinal from './PPVFinal'
import QuantVenda from './QuantVenda'
import Desconto from './Desconto'

const MoneyTemplate = (rowData: any, field: any) => (
  <Money value={!rowData[field] ? '0,00' : rowData[field]} />
)

const PercentTemplate = (rowData: any, field: any) => (
  <Percent value={rowData[field]} />
)

const VolumeTemplate = (rowData: any, field: any) => (
  <Volume value={rowData[field]} />
)

const DescontoTemplate = (
  rowData: any,
  field: any,
  updateRow: (newRow: Partial<any>) => void,
  toggleBigData: () => void,
  inputsRef: React.MutableRefObject<any[]>
) => (
  <Desconto
    row={rowData}
    rowIndex={rowData[field]}
    discountInputsRef={inputsRef}
    updateRow={updateRow}
    toggleBigData={toggleBigData}
  />
)

const QuantidadeTemplate = (
  rowData: any,
  field: any,
  getRows: () => void,
  updateRow: (newRow: Partial<any>) => void,
  toggleBigData: () => void,
  inputsRef: React.MutableRefObject<any[]>
) => (
  <QuantVenda
    row={rowData}
    rowIndex={rowData[field]}
    quantVendaInputsRef={inputsRef}
    updateRow={updateRow}
    toggleBigData={toggleBigData}
  />
)

const PPVFinalTemplate = (
  rowData: any,
  field: any,
  getRows: () => void,
  updateRow: (newRow: Partial<any>) => void,
  toggleBigData: () => void,
  inputsRef: React.MutableRefObject<any[]>
) => (
  <PPVFinal
    row={rowData}
    rowIndex={rowData[field]}
    inputsRef={inputsRef}
    getRows={getRows}
    updateRow={updateRow}
    toggleBigData={toggleBigData}
  />
)

const getColumnTemplate = (
  field: any,
  getRows: () => void,
  updateRow: (newRow: Partial<any>) => void,
  toggleBigData: () => void,
  inputsRef: React.MutableRefObject<any[]>
) => {
  switch (field) {
    case 'investimento':
    case 'list_price_unit':
    case 'average_unit_cost':
    case 'sale_average':
    case 'price_average':
    case 'estimated_revenue':
    case 'real_sale':
      return (rowData: any) => MoneyTemplate(rowData, field)
    case 'mrg_contribution_current':
    case 'mrg_contribution_new':
    case 'variation':
    case 'margem_atual':
      return (rowData: any) => PercentTemplate(rowData, field)
    case 'discount':
      return (rowData: any) =>
        DescontoTemplate(rowData, field, updateRow, toggleBigData, inputsRef)
    // return (rowData: any) => VolumeTemplate(rowData, field)
    // case 'qtd_real':
    // case 'qtd_average':
    case 'qtd_negotiated':
      return (rowData: any) =>
        QuantidadeTemplate(
          rowData,
          field,
          getRows,
          updateRow,
          toggleBigData,
          inputsRef
        )
    case 'new_preco':
      return (rowData: any) =>
        PPVFinalTemplate(
          rowData,
          field,
          getRows,
          updateRow,
          toggleBigData,
          inputsRef
        )
    default:
      return
  }
}

export const createDynamicColumnsDiscount = (
  columns: any[],
  getRows: () => void,
  updateRow: (newRow: Partial<any>) => void,
  toggleBigData: () => void,
  inputsRef: React.MutableRefObject<any[]>
) => {
  return columns.map((col, index) => {
    const headerStyle =
      col.style && col.style.headerStyle ? col.style.headerStyle : {}
    const bodyStyle =
      col.style && col.style.bodyStyle ? col.style.bodyStyle : {}

    return (
      <Column
        style={{
          minWidth:
            col.style && col.style.minwidth ? col.style.minwidth : '120px',
        }}
        key={index}
        field={col.field}
        frozen={col.frozen}
        sortable
        header={col.headerComponent ? col.headerComponent() : col.header}
        body={getColumnTemplate(
          col.field,
          getRows,
          updateRow,
          toggleBigData,
          inputsRef
        )}
        headerStyle={headerStyle}
        bodyStyle={bodyStyle}
      />
    )
  })
}
