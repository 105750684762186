import api from '../../../services/api'

export const getRequestItemService = async (id: string) => {
  return api
    .get(`/requests/get-request-item/${id}`)
    .then((result) => {
      return result.data.data
    })
    .catch((error) => {
      return {
        status: error?.response?.data?.statusCode,
        message: error?.response?.data?.error?.description?.message,
      }
    })
}
