import { useContext } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from 'pages/Login'
import AppEntry from 'pages/AppEntry'
import Dashboard from 'pages/Dashboard'
import Users from 'pages/Users'
import UsersEdit from 'pages/UsersEdit'
import UsersRegister from 'pages/UsersRegister'
import PriceBase from 'pages/PriceBase'
import PriceBaseCreate from 'pages/PriceBaseCreate'
import PriceBaseTable from 'pages/PriceBaseTable'
import PriceBaseDiscountV2 from 'pages/PriceBaseDiscountV2'
import PricesOptimizer from 'pages/PricesOptimizer'
import Approval from 'pages/Approval'
import ApprovalPriceBaseTableV2 from 'pages/ApprovalPriceBaseTableV2'
import ApprovalPriceBaseDiscountV2 from 'pages/ApprovalPriceBaseDiscountV2'
import Parameters from 'pages/Parameters'
import ParametersEdit from 'pages/ParametersEdit'
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import { UserContext } from 'state/user-context'

const RoutesProvider: React.FC = () => {
  const { user } = useContext(UserContext)

  return (
    <BrowserRouter>
      <Routes>
        {user && (
          <Route element={<PrivateRoute user={user && true} />}>
            <Route index element={<Dashboard />} />
            <Route path="*" element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="users" element={<Users />} />
            <Route path="users/edit/:userId" element={<UsersEdit />} />
            <Route path="users/register" element={<UsersRegister />} />
            <Route path="pricebase" element={<PriceBase />} />
            <Route path="pricebase/create" element={<PriceBaseCreate />} />
            <Route
              path="pricebase/table/:id/:type"
              element={<PriceBaseTable />}
            />
            <Route
              path="pricebase/discount/:id/:type"
              element={<PriceBaseDiscountV2 />}
            />
            <Route path="approval" element={<Approval />} />
            <Route
              path="approval/table/:id/:type/:typeView/:requestId"
              element={<ApprovalPriceBaseTableV2 />}
            />
            <Route
              path="approval/discount/:id/:type/:typeView/:requestId"
              element={<ApprovalPriceBaseDiscountV2 />}
            />
            <Route path="parameters" element={<Parameters />} />
            <Route
              path="parameters/edit/:b64data"
              element={<ParametersEdit />}
            />
            <Route path="pricesoptimizer" element={<PricesOptimizer />} />
          </Route>
        )}
        {!user && (
          <Route element={<PublicRoute />}>
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
          </Route>
        )}
      </Routes>
    </BrowserRouter>
  )
}

export default RoutesProvider
