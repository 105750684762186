import React, { useCallback } from 'react'
import { FaTrash as DeleteSvg } from 'react-icons/fa'
import { useToast } from '@chakra-ui/react'
import api from 'services/api'
import { IDeleteComponent } from '../types'
import { IMaterials } from '../../types'

export const DeleteComponent: React.FC<IDeleteComponent> = ({
  row,
  setRows,
}) => {
  const toast = useToast()
  const onDelete = useCallback(
    (skuToDel: string) => {
      api
        .delete(`/relativity-sku/${skuToDel}`)
        .then(() => {
          toast({
            title: 'Excluido com sucesso',
            status: 'success',
            isClosable: true,
          })
          setRows((current: IMaterials[]) => {
            return current.filter((row: IMaterials) => row?.sku !== skuToDel)
          })
        })
        .catch(() => {
          toast({
            title: 'Houve um erro ao exluir',
            status: 'error',
            isClosable: true,
          })
        })
    },
    [setRows, toast]
  )

  return (
    <>
      {row.sku === row.captain_code ? (
        ''
      ) : (
        <DeleteSvg onClick={() => onDelete(row.sku)} />
      )}
    </>
  )
}
