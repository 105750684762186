import { useCallback, useContext, useEffect, useState } from 'react'
import { Checkbox as ChakraCheckbox } from '@chakra-ui/react'
import api from 'services/api'
import { UserContext } from 'state/user-context'

interface IProps {
  row: { [key: string]: any }
  updateCell?: (accessor: string, newValue: any, param: any) => void
  param: string
}

export const CheckboxTaxRecovery: React.FC<IProps> = ({
  row,
  updateCell = () => null,
  param,
}) => {
  const [check, setCheck] = useState(row[param])
  const { userParameters } = useContext(UserContext)
  const updateChecked = useCallback(() => {
    const url = '/trading-condition'
    const req = { id: row.client_code, data: { [param]: check } }

    api.put(url, req).then(() => {})
  }, [check, row.client_code, param])

  const onChange = () => {
    const updatedRow = { ...row, [param]: check }
    updateCell(row.client_code, updatedRow, 'param')
    updateChecked()
  }

  useEffect(() => {
    onChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [check])

  return (
    <ChakraCheckbox
      disabled={!userParameters.write}
      size="lg"
      isChecked={check}
      onChange={() => setCheck(!check)}
    />
  )
}

export default CheckboxTaxRecovery
