import { useCallback, useState, useContext } from 'react'
import { Text, VStack, Button, useToast } from '@chakra-ui/react'
import Input from './Input'
import { Container } from './styles'
import api from 'services/api'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../../state/user-context'

const Form: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { setUser } = useContext(UserContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const onLogin = useCallback(() => {
    const request = { email, password }

    api
      .post('auth/login', request)
      .then((response) => {
        if (response.data.status === 200) {
          const { accessToken } = response.data
          localStorage.setItem('@Prixsia:token', accessToken)
          localStorage.setItem('token', accessToken)
          setUser()
          toast({
            title: response.data.message,
            status: 'success',
            isClosable: true,
          })
        } else {
          toast({
            title: response.data.message,
            status: 'error',
            isClosable: true,
          })
        }
      })
      .catch(() => {
        toast({
          title: 'Houve um erro ao tentar fazer o login',
          status: 'error',
          isClosable: true,
        })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, navigate, password, toast, setUser])

  return (
    <Container>
      <VStack
        p={12}
        spacing={4}
        borderRadius={4}
        alignItems="flex-start"
        bgColor="#f2f2f2"
      >
        <Text fontWeight={700}>Acessar sua conta</Text>
        <Input
          label="E-mail"
          placeholder="Insira seu e-mail"
          value={email}
          setValue={setEmail}
        />
        <Input
          label="Senha"
          placeholder="Insira sua senha"
          type="password"
          value={password}
          setValue={setPassword}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onLogin()
            }
          }}
        />
        <Button
          onClick={onLogin}
          colorScheme="blue"
          disabled={!email || !password}
        >
          Acessar
        </Button>
      </VStack>
    </Container>
  )
}

export default Form
