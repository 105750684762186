export const idpCols = [
  'Data preço',
  'EAN',
  'Descrição',
  'UF',
  'Canal do PDV',
  'Preço regular',
  'Fonte Pesquisa',
]

export const idpRows = [
  {
    'Data preço': '01/12/2022',
    EAN: '10000000001',
    'Descrição': '2 - LA DE AÇO ASSOLAN 60G',
    UF: 'SP',
    'Canal do PDV': 'EXPORTAÇÃO',
    'Preço regular': '2,49',
    'Fonte Pesquisa': 'Promotor',
  },
]

export const pricepointCols = [
  'Data preço',
  'Cap SKU',
  'Descrição',
  'UF',
  'Canal do PDV',
  'Preço Ponderado',
  'Preço regular',
]

export const pricePointRows = [
  {
    'Data preço': '01/10/2022',
    'Cap SKU': '5004',
    Descrição: 'LIMPOL CRISTAL 24/500 ML',
    UF: 'SP',
    'Canal do PDV': 'EXPORTAÇÃO',
    'Preço Ponderado': '1,36',
    'Preço regular': '1,49',
  },
]
