import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FaEye as ViewSvg } from 'react-icons/fa'
import {
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  InputLeftAddon,
  InputGroup,
  Input,  
  Spacer,
  Box,
  ButtonGroup,
  useToast,
} from '@chakra-ui/react'

import api from '../../../../../../../../services/api'
import { IClients, IProps } from './types'
import Button from 'components/Button'

const ViewButton: React.FC<IProps> = ({ bundle, clusterId }) => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [clients, setClients] = useState<IClients[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const clientTemplateColumns = useMemo(() => {
    return '0.1fr 0.25fr 1fr 0.25fr 0.25fr 0.3fr'
  }, [])

  const getClients = useCallback(() => {
    isOpen
      ? api
          .get(
            `/cluster-contracts/${bundle.uf}/${bundle.city}/${bundle.channel_code}`
          )
          .then((response) => {
            setClients(response.data.data)
            setTimeout(() => setLoading(false), 1000)
          })
      : setLoading(true)
  }, [bundle, isOpen])
  useEffect(getClients, [getClients])

  const handleContractChange = (e: string, index: any) => {
    const updatedClients = [...clients]
    updatedClients[index] = { ...updatedClients[index], contract: +e }
    setClients(updatedClients)
  }

  const handleTypeChange = (index: number) => {
    const updatedClients = [...clients]
    updatedClients[index] = {
      ...updatedClients[index],
      type_contract: updatedClients[index].type_contract === 0 ? 1 : 0,
    }
    setClients(updatedClients)
  }

  const handleSave = async () => {
    const payload = clients.map((item) => {
      return {
        id: item.id,
        client_code: item.code,
        type_contract: item.type_contract,
        contract: item.contract,
        cluster_id: clusterId
      }
    })
    try {
      await api.put(`/cluster-contracts/update`, payload).then((res) => {
        if (res.data.status === 200) {
          toast({
            title: 'Contratos atualizados com sucesso!',
            status: 'success',
            isClosable: true,
          })
          onClose()
        }
      })
    } catch (err) {
      toast({
        title: 'Houve um erro ao atualizar os contratos!',
        status: 'error',
        isClosable: true,
      })
    }
  }

  return (
    <>
      <ViewSvg
        onClick={onOpen}
        size={18}
        style={{ cursor: 'pointer', color: '#003b74' }}
      />
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="80vw">
          <ModalHeader>Editar</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex minWidth="max-content" alignItems="center" gap="2">
              <Box p="2">
                <HStack color="#003b74">
                  <Text w="10rem">UF</Text>
                  <Text w="10rem">Cidade</Text>
                  <Text w="10rem">Canal</Text>
                </HStack>
                <HStack color="#919191">
                  <Text w="10rem">{bundle.uf}</Text>
                  <Text w="10rem">{bundle.city}</Text>
                  <Text w="10rem">{bundle.channel_name}</Text>
                </HStack>
              </Box>
              <Spacer />
              {/* <ButtonGroup gap="2">
                <Button onClick={handleSave}>Salvar</Button>
              </ButtonGroup> */}
            </Flex>
            <Divider my={8} />
            {loading ? (
              <Flex my={4} justifyContent="center">
                <Spinner color="#003b74" />
              </Flex>
            ) : (
              <Flex flexDir="column">
                <Grid
                  color="#003b74"
                  w="100%"
                  mb={2}
                  gap={6}
                  templateColumns={clientTemplateColumns}
                >
                  <GridItem>UF</GridItem>
                  <GridItem>Cód</GridItem>
                  <GridItem>Clientes</GridItem>
                  <GridItem>Cidade</GridItem>
                  {/* <GridItem>Contrato</GridItem>
                  <GridItem>Tipo</GridItem> */}
                </Grid>
                {clients.map((client, index) => (
                  <Grid
                    key={client.code}
                    w="100%"
                    gap={6}
                    py={1}
                    color="#919191"
                    templateColumns={clientTemplateColumns}
                    _hover={{ color: '#003b74' }}
                  >
                    <GridItem>{client.uf}</GridItem>
                    <GridItem>{client.code}</GridItem>
                    <GridItem>{client.name}</GridItem>
                    <GridItem>{client.city}</GridItem>
                    {/* <GridItem>
                      <InputGroup w="10rem" size="sm">
                        <InputLeftAddon>%</InputLeftAddon>
                        {loading ? (
                          'Carregado...'
                        ) : (
                          <Input
                            fontSize="1rem"
                            maxW="7rem"
                            type="number"
                            name="contract"
                            value={client.contract}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleContractChange(e.target.value, index)}
                          />
                        )}
                      </InputGroup>
                    </GridItem> */}
                    {/* <GridItem>
                      <FormControl display="flex" alignItems="center">
                        <FormLabel mb="0">Preço</FormLabel>
                        <Switch
                          isChecked={client.type_contract === 1}
                          id="type_contract"
                          onChange={(e: any) => handleTypeChange(index)}
                        />
                        <FormLabel style={{ marginLeft: 10 }} mb="0">
                          Margem
                        </FormLabel>
                      </FormControl>
                    </GridItem> */}
                  </Grid>
                ))}
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ViewButton
