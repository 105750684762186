import { useCallback, useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { Checkbox, useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import Paper from 'components/Paper'
import Status from 'components/Status'
import LoadingScreen from 'components/LoadingScreen'
import { getApprovalRequestListService } from '../Service/get-approval-request-list.service'
import { ViewSvg } from './styles'
import { UserContext } from 'state/user-context'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Tooltip } from 'primereact/tooltip'
import { Checkbox as CheckboxPrime } from 'primereact/checkbox'

const limit = 15

interface IRequestsTable {
  handleClickRow: (
    id: number,
    scenereType: string,
    initialValidityDate: string,
    finalValidityDate: string
  ) => void
  ids: number[]
}

const Table: React.FC<IRequestsTable> = ({ handleClickRow, ids }) => {
  const navigate = useNavigate()
  const toast = useToast()
  const { userDiscountScenery, userScenery } = useContext(UserContext)
  const [rows, setRows] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(15)
  const [order, setOrder] = useState(1)

  const viewDetails = useCallback(
    (id: string, type: string, status: string, requestId: number) => {

      if (
        (type === 'A' && !userScenery.read) ||
        (type === 'B' && !userDiscountScenery.read) ||
        (type === 'B1' && !userDiscountScenery.read)
      ) {
        toast({
          status: 'error',
          title: 'É necessário ter permissão para visualizar o cenário',
        })
        return
      } else {
        let url =
          type === 'A' ? `/approval/table/${id}` : `/approval/discount/${id}`

        if (status.includes('Aguardando')) {
          url += `/edit/${type}/${requestId}`
        } else {
          url += `/view/${type}/${requestId}`
        }

        navigate(url)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  )

  useEffect(() => {
    const getList = async () => {
      try {
        const res = await getApprovalRequestListService(1, 15)
        setRows(res.payload)
      } finally {
        setLoading(false)
      }
    }

    getList()
  }, [])

  const dateHourTemplate = (rowData: any) => {
    return moment(rowData.created_at).subtract(3, 'hours').format('DD/MM/yyyy HH:mm')
  }

  const dateTemplate = (rowData: any) => {
    if (!rowData.initial_validity_date) return ''
    else {
      return `${moment(rowData.initial_validity_date).format(
        'DD/MM/yyyy'
      )} à ${moment(rowData.final_validity_date).format('DD/MM/yyyy')}`
    }
  }

  const statusTemplate = (rowData: any) => {
    return <Status status={rowData.status} />
  }

  const typeTemplate = (rowData: any) => {
    return <>{rowData.scenery_type === 'A' ? 'Tabela' : 'Desconto'}</>
  }

  const checkboxTemplate = (rowData: any) => {
    if (rowData.status === 'Aprovado' || rowData.status === 'Reprovado') {
      return (
        <Checkbox
          isDisabled={
            rowData.status === 'Aprovado' || rowData.status === 'Reprovado'
          }
          className="disabled-checkbox"
          style={{ backgroundColor: 'transparent' }}
          size="lg"
          isChecked={ids.includes(rowData.id)}
          onChange={() =>
            handleClickRow(
              rowData.id,
              rowData.scenery_type,
              rowData.initial_validity_date,
              rowData.final_validity_date
            )
          }
        />
      )
    } else
      return (
        <Checkbox
          sx={{
            '.chakra-checkbox__control': {
              borderColor: 'black',
            },
          }}
          isDisabled={
            rowData.status === 'Aprovado' || rowData.status === 'Reprovado'
          }
          color="primary"
          size="lg"
          className="request-checkbox"
          style={{ backgroundColor: 'transparent' }}
          isChecked={ids.includes(rowData.id)}
          onChange={() =>
            handleClickRow(
              rowData.id,
              rowData.scenery_type,
              rowData.initial_validity_date,
              rowData.final_validity_date
            )
          }
        />
      )
  }

  const viewTemplate = (rowData: any) => {
    return (
      <>
        <Tooltip target=".view-icon" content="Ver cenário" position="top" />
        <ViewSvg
          className="view-icon"
          onClick={() =>
            viewDetails(
              rowData.scenery.id,
              rowData.scenery.type,
              rowData.status,
              rowData.id
            )
          }
        />
      </>
    )
  }

  const onPage = (event: any) => {
    setPage(event.page)
    setLimit(event.rows)
  }

  const onSort = (event: any) => {
    const { sortField } = event

    setRows((prevData) => {
      const sortedData = [...prevData].sort((a: any, b: any) => {
        const aValue = a[sortField]
        const bValue = b[sortField]

        if (aValue == null) return order === 1 ? 1 : -1
        if (bValue == null) return order === 1 ? -1 : 1

        if (aValue < bValue) return order === 1 ? -1 : 1
        if (aValue > bValue) return order === 1 ? 1 : -1

        return 0
      })

      return sortedData
    })

    setOrder(order === 1 ? -1 : 1)
  }

  if (loading) return <LoadingScreen />
  return (
    <Paper>
      <DataTable
      onSort={onSort}
        scrollable
        scrollHeight="70vh"
        paginator
        rows={limit}
        rowsPerPageOptions={[5, 10, 25, 50]}
        value={rows}
        onPage={onPage}
        // selectionMode="multiple"
        // selection={selectedRows}
        // onSelectionChange={onSelectionChange}
        dataKey="id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        emptyMessage="Sem dados"
        filterDisplay="menu"
        currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
      >
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field=""
          header=""
          body={checkboxTemplate}
          style={{ minWidth: '20px' }}
        ></Column>
        <Column
          sortable
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="scenery_id"
          header="ID Cenário"
          style={{ minWidth: '100px' }}
        ></Column>

        <Column
          sortable
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="creator_name"
          header="Solicitante"
          style={{ minWidth: '200px' }}
        ></Column>
        <Column
          sortable
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="scenery_name"
          header="Nome do cenário"
          style={{ minWidth: '200px' }}
        ></Column>
        <Column
          sortable
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="scenery_types"
          header="Base de Pesquisa Preço"
          body={typeTemplate}
          style={{ minWidth: '200px' }}
        ></Column>
        <Column
          sortable
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="created_at"
          header="Data de envio"
          body={dateHourTemplate}
          style={{ minWidth: '50px' }}
        ></Column>
        <Column
          sortable
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="initial_validity_date"
          header="Data de vigência"
          body={dateTemplate}
          style={{ minWidth: '50px' }}
        ></Column>
        <Column
          sortable
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="status"
          header="Status"
          body={statusTemplate}
          style={{ minWidth: '50px' }}
        ></Column>
        <Column
          sortable
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field=""
          header=""
          body={viewTemplate}
          style={{ minWidth: '50px' }}
        ></Column>
      </DataTable>
    </Paper>
  )
}

export default Table
