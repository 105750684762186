import { useCallback, useEffect, useState, useContext } from 'react'
import { Accordion, Flex, Text } from '@chakra-ui/react'
import api from 'services/api'
import Paper from 'components/Paper'
import LoadingScreen from 'components/LoadingScreen'
import Row from './Row'
import { UserContext } from 'state/user-context'

const ClusterDePreco: React.FC = () => {
  const { user } = useContext(UserContext)
  const [rows, setRows] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  const getRows = useCallback(() => {
    api.get('/clusters/all').then((response) => {
      const data = response.data?.data?.clusters

      if (/todos/i.test(data[0]?.name)) {
        data.shift()
      }

      if (user?.clusters?.includes(0)) {
        setRows(response.data.data.clusters)
      } else {
        setRows(
          response.data.data.clusters.filter((item: any) =>
            user?.clusters?.includes(item.id)
          )
        )
      }

      setLoading(false)
    })
  }, [user])

  useEffect(getRows, [getRows])

  if (loading) return <LoadingScreen />
  return (
    <Paper>
      <Flex
        flex="1"
        py="1rem"
        pl="1rem"
        mb="1rem"
        color="#003b74"
        bgColor="#d0e1fd"
        borderRadius="4px"
        
      >
        <Text w="10rem">Filial</Text>
        <Text w="10rem">Nome do Cluster</Text>
      </Flex>
      <Accordion allowToggle borderBottom="1px solid transparent">
        {rows.map((row, index) => (
          <Row key={`${index}-${row.id}`} row={row} />
        ))}
      </Accordion>
    </Paper>
  )
}

export default ClusterDePreco
