import {
  HStack,
  Text,
  VStack,
  Checkbox,
  InputGroup,
  Input,
  InputRightAddon,
} from '@chakra-ui/react'

export interface ApproveType {
  table: boolean
  discount: boolean
}

interface IProps {
  nivel: any
  setNivel: React.Dispatch<React.SetStateAction<number>>
  setApproveType: React.Dispatch<React.SetStateAction<object>>
  approveType: any
  setApprovalLevel: (number: number) => void
  approvalLevel: number
}

const Level: React.FC<IProps> = ({
  nivel,
  setNivel,
  setApproveType,
  approveType,
  setApprovalLevel,
  approvalLevel,
}) => {
  const handleSelectNivel = (nivelSelected: number) => {
    const levelKey = `nivel${nivelSelected}`

    const newState = {
      ...nivel,
      [levelKey]: {
        ...nivel[levelKey],
        checked: !nivel[levelKey]?.checked,
      },
    }

    setNivel(newState)
    setApprovalLevel(nivelSelected)
  }

  const handleSelectApproveType = (name: string) => {
    const newState = {
      ...approveType,
      [name]: !approveType[name],
    }

    setApproveType(newState)
  }

  const handleChangePercentageValue = (
    nivelSelected: number,
    percentage: number
  ) => {
    const levelKey = `nivel${nivelSelected}`

    const newState = {
      ...nivel,
      [levelKey]: {
        ...nivel[levelKey],
        percentage: percentage, // Atualiza a porcentagem
      },
    }

    setNivel(newState)
  }

  return (
    <VStack w="100%" align="flex-start">
      <Text fontSize="md" fontWeight="semibold" color="#003b74">
        Tipo de Aprovador
      </Text>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Text style={{ marginBottom: 15 }} fontSize="1rem">
            Preço
          </Text>
          <Checkbox
            size="lg"
            isChecked={approveType.table}
            onChange={() => {
              handleSelectApproveType('table')
            }}
          ></Checkbox>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginLeft: 25,
          }}
        >
          <Text style={{ marginBottom: 15 }} fontSize="1rem">
            Desconto
          </Text>
          <Checkbox
            size="lg"
            isChecked={approveType.discount}
            onChange={() => {
              handleSelectApproveType('discount')
            }}
          ></Checkbox>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            marginLeft: 25,
          }}
        >
          <Text fontSize="1rem">Nível de Aprovador</Text>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 10,
            }}
          >
            <Checkbox
              size="lg"
              isChecked={nivel?.nivel1?.checked}
              onChange={() => {
                handleSelectNivel(1)
              }}
            ></Checkbox>
            <Text style={{ marginLeft: 30, marginTop: 4 }} fontSize="1rem">
              Nível 1
            </Text>
            <InputGroup size="sm" style={{ width: 90, marginLeft: 30 }}>
              <Input
                type="number"
                max={100}
                disabled={!nivel?.nivel1?.checked || !approveType?.discount}
                // placeholder="0"
                value={nivel?.nivel1?.percentage}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangePercentageValue(
                    1,
                    Number(e.target.value) > 100 ? 100 : Number(e.target.value)
                  )
                }
              />
              <InputRightAddon>%</InputRightAddon>
            </InputGroup>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 10,
            }}
          >
            <Checkbox
              size="lg"
              isChecked={nivel?.nivel2?.checked}
              onChange={() => {
                handleSelectNivel(2)
              }}
            ></Checkbox>
            <Text style={{ marginLeft: 30, marginTop: 4 }} fontSize="1rem">
              Nível 2
            </Text>
            <InputGroup size="sm" style={{ width: 90, marginLeft: 30 }}>
              <Input
                type="number"
                max={100}
                disabled={!nivel?.nivel2?.checked || !approveType.discount}
                // placeholder="0"
                value={nivel?.nivel2?.percentage}
                onChange={(e) =>
                  handleChangePercentageValue(
                    2,
                    Number(e.target.value) > 100 ? 100 : Number(e.target.value)
                  )
                }
              />
              <InputRightAddon>%</InputRightAddon>
            </InputGroup>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 10,
            }}
          >
            <Checkbox
              size="lg"
              isChecked={nivel?.nivel3?.checked}
              onChange={() => {
                handleSelectNivel(3)
              }}
            ></Checkbox>
            <Text style={{ marginLeft: 30, marginTop: 4 }} fontSize="1rem">
              Nível 3
            </Text>
            <InputGroup size="sm" style={{ width: 90, marginLeft: 30 }}>
              <Input
                type="number"
                max={100}
                disabled={!nivel?.nivel3?.checked || !approveType.discount}
                // placeholder="0"
                value={nivel?.nivel3?.percentage}
                onChange={(e) =>
                  handleChangePercentageValue(
                    3,
                    Number(e.target.value) > 100 ? 100 : Number(e.target.value)
                  )
                }
              />
              <InputRightAddon>%</InputRightAddon>
            </InputGroup>
          </div>
        </div>
      </div>
      {/* {[1, 2, 3, 4, 5].map((n) => (
        <HStack key={n}>
          <Checkbox
            size="lg"
            isChecked={nivel >= n}
            onChange={() => {
              setNivel(n)
            }}
          >
            <Text fontSize="1rem">Nível 0{n}</Text>
          </Checkbox>
        </HStack>
      ))} */}
    </VStack>
  )
}

export default Level
