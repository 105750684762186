import React, { useEffect, useMemo, useState } from 'react'
import { Column } from 'primereact/column'
import { createDynamicColumns } from './createDynamicColumns'
import { DataTable, SortOrder } from 'primereact/datatable'
import { IFilters, IRow } from './types'
import { useParams } from 'react-router-dom'
import { colsDetailed, colsDetailedChildren } from './dynamicColumns'
import { Button } from 'primereact/button'
import { formatDataChildren } from './actionsChildren'
import 'primeicons/primeicons.css'
import api from 'services/api'
import { Checkbox } from '@chakra-ui/react'
import CompetitorListModal from './CompetitorListModal'
interface Child {
  id: number
}

interface IMainTableProps {
  dataTable: IRow[]
  getRows: () => void
  toggleBigData: () => void
  inputsRef: React.MutableRefObject<any[]>
  selectedRows: any
  onSelectionChange: any
  handleHeaderCheckboxChange: any
  allSelected: any
  typeView: string
  handleRefresh: () => void
  page: number
  setPage: (number: number) => void
  rows: number
  setRows: (number: number) => void
  loading: boolean
  totalRecords: number
  columns: any
  sort: SortOrder
  setSort: (sort: SortOrder) => void
  setOrderBy: (orderBy: string) => void
  orderBy: string
  scrollHeight: string
  filters: IFilters
}
const limit = 15

const MainTable: React.FC<IMainTableProps> = ({
  dataTable,
  getRows,
  toggleBigData,
  inputsRef,
  selectedRows,
  onSelectionChange,
  handleHeaderCheckboxChange,
  allSelected,
  typeView,
  handleRefresh,
  page,
  setPage,
  rows,
  setRows,
  loading,
  totalRecords,
  columns,
  sort,
  orderBy,
  setSort,
  setOrderBy,
  scrollHeight,
  filters,
}) => {
  const { id } = useParams()
  const [childrenData, setChildrenData] = useState<Record<string, Child[]>>({})
  const [data, setData] = useState(dataTable)
  const [order, setOrder] = useState(1)
  const [openCompetitorModal, setOpenCompetitorModal] = useState(false)
  const [rowSelected, setRowSelected] = useState({})
  const [newLimit, setNewLimit] = useState(100)
  const [newTotalRecords, setNewTotalRecords] = useState<number>(totalRecords)
  const [rowDataBackup, setRowDataBackup] = useState()


  useEffect(() => {
    setNewTotalRecords(Number(totalRecords))
      }, [totalRecords])

  useEffect(() => {
    setNewLimit(rows)
  }, [rows])

  useEffect(() => {
    setData(dataTable)
  }, [dataTable])

  const updateRow = (newRow: Partial<IRow>) => {
    handleRefresh()
    setData((prevRows: any) =>
      prevRows.map((row: any) =>
        row.id === newRow.id ? { ...row, ...newRow } : row
      )
    )
  }

  const handleNewChangeCheckBox = (e: any, rowData: any) => {
    const { checked } = e
    const newStatus =
      rowData.status === '' || !rowData.status || rowData.status === 'N'
        ? 'S'
        : ''

    const updatedRowData = { ...rowData, status: newStatus }

    onSelectionChange(e, updatedRowData, 'detailed')
    updateRow(updatedRowData)

    if (rowData.newId) {
      setData((prevExpandedRows: any[]) => {
        const expandedRows = Array.isArray(prevExpandedRows)
          ? prevExpandedRows
          : []

        const newExpandedRows = expandedRows.map((row) =>
          row.id === rowData.id
            ? {
                ...row,
                status: newStatus,
              }
            : row
        )

        return newExpandedRows
      })
    }
  }

  // useEffect(() => {
  //   const dataMap = new Map(data.map((item) => [item.id, item]))

  //   dataTable.forEach((row) => {
  //     dataMap.set(row.id, row)
  //   })

  //   const updatedData = Array.from(dataMap.values())

  //   setData(updatedData)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dataTable])

  const [expandedRows, setExpandedRows] = useState<any>({})

  const handleToggleDetails = (rowData: any, page = 1) => {
    setRowDataBackup(rowData)

    const isExpanded = !!expandedRows[rowData.id]
    const newData: any = [...data]

    if (isExpanded) {
      const startIndex =
        newData.findIndex((item: any) => item.id === rowData.id) + 1
      let count = 0
      while (
        newData[startIndex + count] &&
        newData[startIndex + count].parentId === rowData.id
      ) {
        count++
      }
      newData.splice(startIndex, count)
      setData(newData)
      setNewLimit(100)
      setNewTotalRecords(totalRecords)
      setExpandedRows((prev: any) => ({ ...prev, [rowData.id]: false }))
    } else {
      const request: { [key: string]: string[] } = {}

      Object.keys(filters).forEach((key) => {
        request[key] = filters[key]?.map((v: IOption) => v.value) || []
      })
      const url = `/sceneries/${id}/items/A?page=${page}&limit=${limit}`
      api
        .get(url, {
          params: {
            captain_code: rowData.captain_code,
            channel_code: rowData.channel_code,
            cluster_id: rowData.cluster_id,
            id: rowData.id,
            filters: request,
            orderBy,
            sort: sort === 1 ? 'asc' : 'desc',
          },
        })
        .then((res) => {
          const formattedChildrenData = formatDataChildren(res.data.data)
          setChildrenData((prevData) => ({
            ...prevData,
            [rowData.id]: { data: formattedChildrenData, page },
          }))
          expandRow(rowData, formattedChildrenData)
        })
        .catch((error) => console.log(error))
    }
  }

  useEffect(() => {
    if (rowDataBackup) {
      handleToggleDetails(rowDataBackup, 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, sort, filters])

  const paginationControls = (rowData: any) => {
    const childData: any = childrenData[rowData.id]
    return (
      <div>
        {childData && childData.page > 1 && (
          <Button
            icon="pi pi-chevron-left"
            onClick={() => handleToggleDetails(rowData, childData.page - 1)}
          />
        )}
        {childData && (
          <Button
            icon="pi pi-chevron-right"
            onClick={() => handleToggleDetails(rowData, childData.page + 1)}
          />
        )}
      </div>
    )
  }

  const expandRow = (rowData: any, children: any) => {
    const newData = [...data]
    const index = newData.findIndex((item) => item.id === rowData.id) + 1

    const childrenRows = children.map((child: any, idx: any) => ({
      ...child,
      // id: child.id,
      newId: child.id,
      id: `${rowData.id}-${idx}`,
      parentId: rowData.id,
    }))

    newData.splice(index, 0, ...childrenRows)
    setData(newData)
    setNewLimit(newData.length)
    setNewTotalRecords(newData.length)
    setExpandedRows((prev: any) => ({ ...prev, [rowData.id]: true }))
  }

  const handleOpenCompetitor = (rowData: any, field: string) => {
    setRowSelected({ ...rowData, field })
    setOpenCompetitorModal(true)
  }

  const dynamicColumns = createDynamicColumns(
    columns,
    getRows,
    updateRow,
    toggleBigData,
    inputsRef,
    typeView,
    handleOpenCompetitor
  )

  const actionBodyTemplate = (rowData: any) => {
    if (!rowData.parentId && filters?.sku?.length < 1 || !rowData.parentId && !filters?.sku) {
      // Botão de expansão/colapso
      const expandCollapseButton = (
        <Button
          icon={
            expandedRows[rowData.id] ? 'pi pi-chevron-up' : 'pi pi-chevron-down'
          }
          onClick={() => handleToggleDetails(rowData)}
          className="p-button-text"
        />
      )

      // Obtendo os controles de paginação
      const paginationButtons = paginationControls(rowData)

      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {expandCollapseButton}
          {expandedRows[rowData.id] && paginationButtons}
        </div>
      )
    } else {
      return <div style={{ width: 15 }} /> 
    }
  }

  const onPage = (event: any) => {
    setPage(event.page)
    setRows(event.rows)
  }

  const onSort = (event: any) => {
    const { sortField } = event
    setOrderBy(sortField)
    setSort(sort === 1 ? -1 : 1)
  }

  return (
    <>
      <CompetitorListModal
        isOpen={openCompetitorModal}
        row={rowSelected}
        onClose={() => setOpenCompetitorModal(false)}
      />
      <DataTable
        key={data.length} 
        sortField={orderBy}
        sortOrder={sort}
        scrollable
        scrollHeight={scrollHeight}
        paginator
        lazy
        onPage={onPage}
        totalRecords={Number(newTotalRecords)}
        first={page * rows}
        rows={newLimit}
        rowsPerPageOptions={[5, 10, 25, 50]}
        tableStyle={{ minWidth: '40rem', maxHeight: '200px' }}
        value={data}
        loading={loading}
        selectionMode={null}
        selection={selectedRows}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        emptyMessage="Sem dados"
        filterDisplay="menu"
        currentPageReportTemplate={`Exibindo de {first} à {last} de ${newTotalRecords} registros`}
        onSort={onSort}
      >
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          frozen
          header={
            <Checkbox
              sx={{
                '.chakra-checkbox__control': {
                  borderColor: 'black',
                },
              }}
              colorScheme="green"
              size="lg"
              isChecked={allSelected}
              onChange={handleHeaderCheckboxChange}
            />
          }
          body={(rowData) => (
            <Checkbox
              sx={{
                '.chakra-checkbox__control': {
                  borderColor: 'black',
                },
              }}
              colorScheme="green"
              size="lg"
              isChecked={
                selectedRows.some((row: any) => row.id === rowData.id) ||
                rowData.status === 'S'
              }
              onChange={(e) => handleNewChangeCheckBox(e, rowData)}
            />
          )}
          style={{ width: '3em' }}
        />
        <Column
          body={actionBodyTemplate}
          header=""
          style={{ width: '20px' }}
          frozen
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
        />

        {dynamicColumns}
      </DataTable>
    </>
  )
}

export default MainTable
