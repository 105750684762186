import { useCallback, useState } from 'react'

import Cards from './Cards'
import Table from './Table'
import Header from './Header'
import IFilters from './types/IFilters'
import { BodyContent, Container, HeadContent } from './styles'
import { colsClient } from './Table/dynamicColumns'

const PriceBaseDiscountV2: React.FC = () => {
  const [filters, setFilters] = useState({} as IFilters)
  const [typeView, setTypeView] = useState<string>('simple')
  const [getBigData, setGetBigData] = useState<boolean>(false)
  const [refreshVerifyStatus, setRefreshVerifyStatus] = useState(false)
  const [columns, setColumns] = useState(colsClient)
  const [newColumns, setNewColumns] = useState(colsClient)

  const toggleBigData = useCallback(() => {
    setGetBigData((current) => !current)
  }, [])

  return (
    <Container>
      <HeadContent>
        <Header
          filters={filters}
          setFilters={setFilters}
          typeView={typeView}
          setTypeView={setTypeView}
          columns={columns}
          newColumns={newColumns}
          setNewColumns={setNewColumns}
        />
        <Cards getBigData={getBigData} filters={filters} />
      </HeadContent>
      <BodyContent>
        <Table
          handleRefreshVerifyStatus={(e) => setRefreshVerifyStatus(e)}
          filters={filters}
          toggleBigData={toggleBigData}
          typeView={typeView}
          newColumns={newColumns}
        />
      </BodyContent>
    </Container>
  )
}

export default PriceBaseDiscountV2
