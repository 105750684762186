import {
  KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { InputText } from 'primereact/inputtext'
import api from 'services/api'
import { moneyComma } from 'utils/masks'

import { formatData } from '../actions'
import { UserContext } from 'state/user-context'

interface IProps {
  row: { [key: string]: any }
  rowIndex: number
  updateRow: (newRow: { [key: string]: any }) => void
  discountInputsRef: React.MutableRefObject<any[]>
  toggleBigData: () => void
}

export const Desconto: React.FC<IProps> = ({
  row,
  rowIndex,
  updateRow,
  discountInputsRef,
  toggleBigData,
}) => {
  const { userDiscountScenery } = useContext(UserContext)
  const [value, setValue] = useState(!row?.discount ? '0' : row?.discount)
  const [focusValue, setFocusValue] = useState(row?.discount)

  const autoRef = useRef<any>()

  useEffect(() => {
    setValue(row.discount)
  }, [row.discount])

  const onFocus = useCallback(() => {
    setFocusValue(value)
    setValue('')
  }, [value])

  const onBlur = () => {
    if (!value) return setValue(focusValue)
    if (value === row.discount) return

    const url = '/sceneries/item-discount'
    const req = {
      id: Number(row.id),
      discount: !value ? 0 : +value.replace(',', '.'),
      status: 'S',
      price: String(
        (
          +row.list_price_unit -
          +row.list_price_unit * (+value.replace(',', '.') / 100)
        ).toFixed(2)
      ),
    }

    api.put(url, req).then((res) => {
      const result = res.data.data.result[0]
      const formattedRow: any = formatData([{ ...res.data.data.scenery_item }])
      updateRow({
        ...row,
        ...formattedRow[0],
        discount: value,
        status: 'S',
        mrg_contribution_new: result.mrg_contribution_new,
        variation: result.variation,
        new_preco: String(
          (
            +row.list_price_unit -
            +row.list_price_unit * (+value.replace(',', '.') / 100)
          ).toFixed(2)
        ),
      })
      toggleBigData()
    })
  }

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    // const number = Number(e.target.value.replace(',', '.'))
    setValue(e.target.value)
    // if (number <= 0) {
    //   setValue('0.00')
    // } else {
    //   setValue(e.target.value)
    // }
  }, [])

  const handleKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const nextInput = null
      // const nextInput = inputsRef.current[rowIndex + 1];
      console.log(nextInput, 'nextInput')
      if (nextInput) {
        // nextInput.focus();
      } else {
        e.currentTarget.blur()
      }
    }
  }, [])

  const borderColor = useMemo(
    () =>
      `${row.ppv_novo !== row.ppc_s_mrg ? '#E5E5E5' : '#E5E5E5'} !important`,
    [row.ppc_s_mrg, row.ppv_novo]
  )

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div className="p-inputgroup">
        <span className="p-inputgroup-addon">%</span>
        <InputText
          // ref={inputRef}
          disabled={!userDiscountScenery.write}
          style={{ height: 32, borderColor: borderColor, textAlign: 'center', maxWidth: 80 }}
          value={value}
          onBlur={onBlur}
          placeholder={focusValue}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          onFocus={onFocus}
        />
      </div>
    </div>
  )
}

export default Desconto
