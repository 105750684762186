import React from 'react'

interface IProps {
  value: string | number
}

const Volume: React.FC<IProps> = ({ value }) => {
  const formatNumber = (value: string | number): string => {
    const numberValue = typeof value === 'string' ? parseFloat(value) : value

    if (!isNaN(numberValue) && numberValue >= 1000) {
      return numberValue.toLocaleString('pt-BR')
    }

    return value?.toString()
  }

  return <>{formatNumber(String(Number(value).toFixed(2)).replace('.', ',')) + ' Kg'}</>
}

export default Volume
