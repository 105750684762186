import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'

import Button from 'components/Button'
import api from 'services/api'
import Autocomplete from 'components/Autocomplete'
import { FaPlus as AddSvg } from 'react-icons/fa'
import { IProps, IOptions, IData } from './types'
import { UserContext } from 'state/user-context'
interface UFItem {
  value: string
}
const CreateButton: React.FC<IProps> = ({ row, ufOptions, getBundles }) => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { userParameters } = useContext(UserContext)
  const [uf, setUf] = useState<IOptions>({ label: 'TODOS', value: 'TODOS' })
  const [city, setCity] = useState<IOptions>({ label: 'TODOS', value: 'TODOS' })
  const [channel, setChannel] = useState<IOptions>({
    label: 'TODOS',
    value: 'TODOS',
  })

  const [ufOpt, setUfOpt] = useState<IOptions[]>([
    { label: 'TODOS', value: '0' },
  ])
  const [cityOpt, setCityOpt] = useState<IOptions[]>([
    { label: 'TODOS', value: '0' },
  ])
  const [channelOpt, setChannelOpt] = useState<IOptions[]>([
    { label: 'TODOS', value: '0' },
  ])

  const getUfs = useCallback(() => {
    isOpen &&
      api.get('/clients/add-bundles/ufs').then((res) => {
        const ufs = res.data.data.ufs
        const sortedUfs = ufs.sort((a: any, b: any) => {
          if (a.code === 'TODOS') return -1
          if (b.code === 'TODOS') return 1
          return a.code.localeCompare(b.code)
        })

        setUfOpt(
          sortedUfs.map((item: any) => ({
            label: item.code,
            value: item.code,
          }))
        )
      })
  }, [isOpen])
  useEffect(getUfs, [getUfs])

  const getRegions = useCallback(() => {
    setCityOpt([])

    if (!isOpen || !uf.value) return

    api.get(`/clients/add-bundles/ufs/cities?uf=${uf.value}`).then((res) => {
      const cities = res.data.data.cities
      const sortedCities = cities.sort((a: any, b: any) => {
        if (a.code === 'TODOS') return -1
        if (b.code === 'TODOS') return 1
        return a.code.localeCompare(b.code)
      })

      setCityOpt(
        sortedCities.map((item: any) => ({
          label: item.name,
          value: item.code,
        }))
      )
    })
  }, [isOpen, uf.value])
  useEffect(getRegions, [getRegions])

  const getChannels = useCallback(() => {
    isOpen &&
      api
        .get(`/clients/add-bundles/channels?city=${city.value}&uf=${uf.value}`)
        .then((res) => {
          setChannelOpt([
            ...res.data.data.channels.map((item: IData) => ({
              label: item.name,
              value: item.code,
            })),
          ])
        })
  }, [isOpen, uf.value, city.value])
  useEffect(getChannels, [getChannels])

  const valoresFiltrados = ufOptions
    .filter((item: UFItem) => item.value !== 'TODOS')
    .map((item: UFItem) => item.value)

  const onCreate = useCallback(() => {
    const request = {
      city: city.label,
      uf: uf.label,
      channel_code: channel.value,
      oldufs: valoresFiltrados,
    }

    api
      .post(`/cluster-bundles/${row.id}`, request)
      .then(() => {
        getBundles()
        onClose()
        toast({
          title: 'Adicionado com sucesso!',
          status: 'success',
          isClosable: true,
        })
      })
      .catch((e) => {
        console.log(e)
        if (e.code) {
          return toast({
            title: 'Os seguintes UFs têm ICMS diferentes do esperado',
            status: 'error',
            isClosable: true,
          })
        }

        toast({
          title: 'Erro ao adicionar, tente novamente.',
          status: 'error',
          isClosable: true,
        })
      })
  }, [
    city.label,
    uf.label,
    channel.value,
    getBundles,
    valoresFiltrados,
    onClose,
    toast,
    row.id,
  ])

  return (
    <>
      {userParameters.write ? (
        <HStack
          onClick={onOpen}
          w="100%"
          align="center"
          justify="center"
          my={4}
          py={4}
          spacing="0.5rem"
          color="green.500"
          cursor="pointer"
          borderRadius="1rem"
          _hover={{ bgColor: 'green.50' }}
        >
          <Text>Adicionar UF / Cidade / Canal</Text>
          <AddSvg />
        </HStack>
      ) : (
        <div style={{ height: 30 }} />
      )}

      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="40rem">
          <ModalHeader>Adicionar</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack w="100%" spacing={6}>
              <VStack w="100%" align="flex-start">
                <Text>UF</Text>
                <Autocomplete value={uf} options={ufOpt} setValue={setUf} />
              </VStack>
              <VStack w="100%" align="flex-start">
                <Text>Cidade</Text>
                <Autocomplete
                  value={city}
                  options={cityOpt}
                  setValue={setCity}
                />
              </VStack>
              <VStack w="100%" align="flex-start">
                <Text>Canal</Text>
                <Autocomplete
                  value={channel}
                  options={channelOpt}
                  setValue={setChannel}
                />
              </VStack>
            </HStack>
          </ModalBody>
          <ModalFooter>
            <Flex w="100%" justifyContent="space-between">
              <Button onClick={onClose}>Cancelar</Button>
              <Button onClick={onCreate}>Adicionar</Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CreateButton
