import { useCallback, useEffect, useState } from 'react'
import { TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'

import Paper from 'components/Paper'

import Checkbox from './Checkbox'
import InputRelativity from './InputRelativity'
import { columns } from './constants'
import { Table } from './styles'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'

interface IProps {
  rows: any[]
  setRows: React.Dispatch<React.SetStateAction<any[]>>
  clusterId: string
}

const Body: React.FC<IProps> = ({ rows, clusterId, setRows }) => {
  const [checked, setChecked] = useState('')
  const [order, setOrder] = useState(1)

  const findCaptain = useCallback(() => {
    const captain = rows.find((r) => r.is_captain)
    return setChecked(captain ? captain.id : '')
  }, [rows])
  useEffect(findCaptain, [findCaptain])

  const checkboxTemplate = (rowData: any) => {
    return (
      <Checkbox
        row={rowData}
        clusterId={clusterId}
        checked={checked}
        setChecked={setChecked}
        findCaptain={findCaptain}
      />
    )
  }

  const inputTemplate = (rowData: any) => {
    return <InputRelativity row={rowData} clusterId={clusterId} />
  }

  const onSort = (event: any) => {
    const { sortField } = event

    setRows((prevData) => {
      const sortedData = [...prevData].sort((a: any, b: any) => {
        const aValue = a[sortField]
        const bValue = b[sortField]

        if (aValue == null) return order === 1 ? 1 : -1
        if (bValue == null) return order === 1 ? -1 : 1

        if (aValue < bValue) return order === 1 ? -1 : 1
        if (aValue > bValue) return order === 1 ? 1 : -1

        return 0
      })

      return sortedData
    })

    setOrder(order === 1 ? -1 : 1)
  }

  if (rows?.length === 0) return null
  return (
    <Paper containerStyle={{ padding: '2rem', overflow: 'visible' }}>
      <DataTable
        onSort={onSort}
        scrollable
        scrollHeight="flex"
        paginator
        rows={rows?.length}
        value={rows}
        dataKey="id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        emptyMessage="Sem dados"
        filterDisplay="menu"
        currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
      >
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field=""
          header="Canal Pai"
          body={checkboxTemplate}
          style={{ minWidth: '20px', zIndex: '2' }}
          frozen
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="name"
          header="Canais"
          style={{ minWidth: '100px', zIndex: '2' }}
          frozen
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="relativity"
          header="Relatividade"
          body={inputTemplate}
          style={{ minWidth: '200px', zIndex: '2' }}
          frozen
        ></Column>
      </DataTable>
      {/* <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.Header}>{column.Header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Checkbox
                  row={row}
                  clusterId={clusterId}
                  checked={checked}
                  setChecked={setChecked}
                  findCaptain={findCaptain}
                />
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>
                <InputRelativity row={row} clusterId={clusterId} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> */}
    </Paper>
  )
}

export default Body
