import {
  KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  useToast,
} from '@chakra-ui/react'

import api from 'services/api'
import Indicator from './Indicator'
import { UserContext } from 'state/user-context'
import { InputText } from 'primereact/inputtext'

interface IProps {
  row: { [key: string]: any }
  rowIndex: number
  inputsRef: React.MutableRefObject<any[]>
  updateRow: (newRow: { [key: string]: any }) => void
  toggleBigData: () => void
  getRows: () => void
}

export const PPVFinal: React.FC<IProps> = ({
  row,
  rowIndex,
  inputsRef,
  updateRow,
  toggleBigData,
  getRows,
}) => {
  const toast = useToast()
  const { userDiscountScenery } = useContext(UserContext)
  const formatValue = (value: any) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
    })
      ?.format(parseFloat(value?.replace(',', '.')))
      ?.replace('.', ',')

  const [value, setValue] = useState(
    !row.new_preco || isNaN(row.new_preco) ? '0,00' : formatValue(row.new_preco)
  )
  const [focusValue, setFocusValue] = useState('')
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    setValue(row.new_preco)
  }, [row.new_preco])

  const onFocus = useCallback(() => {
    setFocusValue(value)
    setValue('')
  }, [value])

  const autoRef = useRef<any>()

  const onBlur = () => {
    if (!Number(value.replace('.', '').replace(',', '.'))) {
      return setValue(focusValue)
    }

    const formattedValue = formatValue(value)
    if (formattedValue === formatValue(row.new_preco)) return

    const newDiscountValue = +(
      ((+row.list_price_unit - +formattedValue.replace(',', '.')) /
        +row.list_price_unit) *
      100
    ).toFixed(1)

    const url = '/sceneries/item-discount'
    const req = {
      id: +row.id,
      price: formattedValue.replace(',', '.'),
      status: 'S',
      discount: newDiscountValue,
    }

    api
      .put(url, req)
      .then((res) => {
        const result = res.data.data.result[0]
        updateRow({
          ...row,
          new_preco: formattedValue,
          discount: newDiscountValue,
          variation: result.variation,
          status: 'S',
        })
        setValue(formattedValue) // Atualize o estado com o valor formatado
        toggleBigData()
        getRows()
        toast({
          status: 'success',
          title: 'Novo Preço atualizado com sucesso',
        })
      })
      .catch(() => {
        toast({
          status: 'error',
          title: 'Erro, tente novamente.',
        })
      })
  }

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const number = Number(e.target.value.replace(',', '.'))

    setValue(e.target.value)
  }, [])

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const nextInput = inputsRef.current[rowIndex + 1]
        console.log(nextInput, 'nextInput')
        if (nextInput) {
          nextInput.focus()
        } else {
          e.currentTarget.blur()
        }
      }
    },
    [inputsRef, rowIndex]
  )

  // useEffect(() => {
  //   inputsRef.current[rowIndex] = inputRef.current;
  //   console.log(inputsRef.current, 'inputsRef.current');
  // }, [rowIndex, inputsRef]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div className="p-inputgroup">
        <span className="p-inputgroup-addon">R$</span>
        <InputText
          ref={inputRef}
          disabled={!userDiscountScenery.write}
          style={{ height: 32, borderColor: '#E2E8F0', textAlign: 'center', maxWidth: 80 }}
          value={value}
          onBlur={onBlur}
          placeholder={focusValue}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          onFocus={onFocus}
        />
      </div>
    </div>
  )
}

export default PPVFinal
