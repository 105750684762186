import React, { useCallback, useContext, useState } from 'react'
import {
  Input as ChakraInput,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react'

import IRow from '../../types/IRow'
import { UserContext } from 'state/user-context'

interface IProps {
  row: IRow
  param: string
  clusterId: string
  onUpdate: (rowId: string, row: IRow, param: string) => void // Função de callback para atualizar a linha
  isPercent?: boolean
}

const Input: React.FC<IProps> = ({
  row,
  param,
  clusterId,
  onUpdate,
  isPercent = false,
}) => {
  const { userParameters } = useContext(UserContext)
  const [value, setValue] = useState<string>(row[param].toString())
  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }, [])

  const onBlur = useCallback(() => {
    const updatedRow = { ...row, [param]: value }
    onUpdate(row.id, updatedRow, param) // Passa o parâmetro atualizado e o ID da linha para a função onUpdate
  }, [param, row, onUpdate, value])

  return (
    <InputGroup w="10rem">
      {isPercent && <InputLeftAddon>%</InputLeftAddon>}
      <ChakraInput
        disabled={!userParameters.write}
        type="number"
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      />
    </InputGroup>
  )
}

export default Input
