import { useState, ChangeEvent, useContext, useEffect } from 'react'
import {
  Textarea,
  VStack,
  FormControl,
  FormLabel,
  Button,
  useToast,
} from '@chakra-ui/react'
import Table from './Table'
import Header from './Header'
import { Container } from './styles'
import Modal from 'components/Modal'
import {
  handleApproveRejectDiscountService,
  handleApproveRejectService,
} from './Service/handle-approve-reject.service'
import { UserContext } from 'state/user-context'
import api from 'services/api'
import { getRequestItemService } from './Service/get-request-item.service'
import { IUser } from 'domain/login/IUser'
import moment from 'moment'

const Approval: React.FC = () => {
  const toast = useToast()
  const { user } = useContext(UserContext)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [text, setText] = useState<string>('')
  const [newIds, setNewIds] = useState<{ id: number; sceneryType: string }[]>(
    []
  )
  const [type, setType] = useState<string>('')
  const [verifiedNivel, setVerifiedNivel] = useState<any>(1)
  const [newUser, setNewUser] = useState<IUser>()
  const [expired, setExpired] = useState(false)

  const messageToStatus = (userNivel: any, verifiedLevel: any) => {
    if (userNivel === 1 && verifiedLevel === 1) return 'APPROVED'
    if (userNivel === 1 && verifiedLevel === 2) return 'PENDING_APPROVAL_N2'
    if (userNivel === 2 && verifiedLevel === 2) return 'APPROVED'
    if (userNivel === 2 && verifiedLevel === 3) return 'PENDING_APPROVAL_N3'
    if (userNivel === 3 && verifiedLevel === 3) return 'APPROVED'
    return ''
  }
  useEffect(() => {
    const verify = async () => {
      const verifyNivel = await api.get(`access-controll/verify-nivel`)
      setVerifiedNivel(verifyNivel?.data)

      const res = await api.get(`access-controll/${user?.id}`)
      const resData = res.data?.data?.user
      setNewUser(resData)
    }
    verify()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async () => {
    for (const newId of newIds) {
      const verifyIfCanApprove = await getRequestItemService(
        String(newId.id) ?? ''
      )
      if (
        (newUser?.nivel === 1 &&
          !verifyIfCanApprove[0].status.includes('N1')) ||
        (newUser?.nivel === 2 && verifyIfCanApprove[0].status.includes('N3'))
      ) {
        toast({
          status: 'error',
          title: 'É necessário ter o nível maior para aprovação',
        })
        return
      }
    }

    const tableIds = newIds
      .filter((item) => item.sceneryType !== 'B')
      .map((item) => item.id) ?? [0]
    const discountIds = newIds
      .filter((item) => item.sceneryType !== 'A')
      .map((item) => item.id) ?? [0]

    if (tableIds.length > 0) {
      await handleApproveRejectService({
        ids: tableIds,
        type,
        reason: text,
        status: messageToStatus(newUser?.nivelValid, verifiedNivel),
      })
        .then((res: any) => {
          if (res.status === 201 || res.status === 200) {
            toast({
              status: 'success',
              title: 'Requisição atualizada com sucesso',
            })
            window.location.reload()
          } else {
            toast({
              status: 'error',
              title: 'Erro, tente novamente.',
            })
          }
        })
        .catch(() => {
          toast({
            status: 'error',
            title: 'Erro, tente novamente.',
          })
        })
    }

    if (discountIds.length > 0) {
      await handleApproveRejectDiscountService({
        ids: discountIds,
        type,
        reason: text,
        status: messageToStatus(newUser?.nivel, verifiedNivel),
      })
        .then((res: any) => {
          if (res.status === 201 || res.status === 200) {
            toast({
              status: 'success',
              title: 'Requisição atualizada com sucesso',
            })
            window.location.reload()
          } else {
            toast({
              status: 'error',
              title: 'Erro, tente novamente.',
            })
          }
        })
        .catch(() => {
          toast({
            status: 'error',
            title: 'Erro, tente novamente.',
          })
        })
    }
  }

  const handleClickRow = (
    id: number,
    sceneryType: string,
    initialValidityDate: string,
    finalValidityDate: string
  ) => {
    setNewIds((prevIds) => {
      const isPresent = prevIds.some(
        (item) => item.id === id && item.sceneryType === sceneryType
      )

      if (isPresent) {
        return prevIds.filter(
          (item) => item.id !== id || item.sceneryType !== sceneryType
        )
      } else {
        return [
          ...prevIds,
          { id, sceneryType, initialValidityDate, finalValidityDate },
        ]
      }
    })
  }

  useEffect(() => {
    const isExpired = newIds.some(
      (item: {
        id: number;
        sceneryType: string;
        initialValidityDate?: string;
        finalValidityDate?: string;
      }) => {
        const initialDate = item.initialValidityDate
          ? moment(item.initialValidityDate, 'YYYY-MM-DD')
          : null;
        const finalDate = item.finalValidityDate
          ? moment(item.finalValidityDate, 'YYYY-MM-DD')
          : null;
        const today = moment();
  
        // Se a data de início ou de fim não estiver definida, consideramos que não está expirado
        if (!initialDate || !finalDate) {
          return false;
        }
  
        // Verifica se hoje está fora do intervalo [initialValidityDate, finalValidityDate]
        return today.isBefore(initialDate, 'day') || today.isAfter(finalDate, 'day');
      }
    );
  
    setExpired(isExpired);
  }, [newIds]);

  const handleApproveReject = (type: string) => {
    setOpenModal(true)
    setType(type)
  }

  return (
    <Container>
      {openModal && (
        <Modal
          isOpen={true}
          onClose={() => setOpenModal(false)}
          w={'560px'}
          title={`Confirme que está ${
            type === 'approve' ? 'APROVANDO' : 'RECUSANDO'
          } o(s) cenário(s)?`}
          body={
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Escreva o motivo</FormLabel>
                <Textarea
                  placeholder="Digite aqui..."
                  value={text}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    setText(e.target.value)
                  }
                />
              </FormControl>
            </VStack>
          }
          footer={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Button
                colorScheme="gray"
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                Cancelar
              </Button>
              <div style={{ width: 20 }}></div>
              <Button
                colorScheme="green"
                onClick={() => {
                  handleSubmit()
                }}
              >
                Confirmar
              </Button>
            </div>
          }
        />
      )}

      <Header
        handleApproveReject={handleApproveReject}
        disabled={newIds.length < 1}
        expired={expired}
      />
      <Table
        handleClickRow={handleClickRow}
        ids={newIds.map((item) => item.id)}
      />
    </Container>
  )
}

export default Approval
