import {
  KeyboardEvent,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import api from 'services/api'
import Indicator from './Indicator'
import { UserContext } from 'state/user-context'
import { InputText } from 'primereact/inputtext'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import './styles.css'

interface IProps {
  row: { [key: string]: any }
  rowIndex: number
  inputsRef: React.MutableRefObject<any[]>
  updateRow: (newRow: { [key: string]: any }) => void
  toggleBigData: () => void
  getRows: () => void
}

export const PPVFinal: React.FC<IProps> = ({
  row,
  rowIndex,
  inputsRef,
  updateRow,
  toggleBigData,
  getRows,
}) => {
  const { userScenery } = useContext(UserContext)
  const formatValue = (value: string) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
    })
      .format(parseFloat(value?.replace(',', '.')))
      ?.replace('.', ',')

  const [value, setValue] = useState(
    !row.preco_bruto_sugestao || isNaN(row.preco_bruto_sugestao)
      ? '0,00'
      : formatValue(row.preco_bruto_sugestao)
  )
  const [focusValue, setFocusValue] = useState('')

  // console.log(inputsRef, 'inputsRef')
  // console.log(rowIndex, 'rowIndex')

  const onFocus = useCallback(() => {
    setFocusValue(value)
    setValue('')
  }, [value])

  const onBlur = useCallback(() => {
    if (!Number(value.replace('.', '').replace(',', '.'))) {
      return setValue(focusValue)
    }

    const formattedValue = formatValue(value)
    if (formattedValue === formatValue(row.preco_bruto_sugestao)) return

    const status =
      formattedValue === formatValue(row.custo_medio_produto) ? 'N' : 'S'
    const url = `/sceneries/${row.scenery_id}/edit`
    const req = {
      id: row.id,
      preco_bruto_sugestao: value.replace('.', '').replace(',', '.'),
      status,
      type: row.tax_recovery_percent ? 'trading_condition' : 'scenery',
    }

    api.put(url, req).then((res: any) => {
      const formattedRow = res.data.data[0]
      updateRow({
        ...row,
        ...formattedRow,
        preco_bruto_sugestao: formattedValue,
        status,
      })
      setValue(formattedValue) // Atualize o estado com o valor formatado
      toggleBigData()
      getRows()
    })
  }, [row, updateRow, value, getRows, toggleBigData, focusValue])

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }, [])

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const nextInput = inputsRef.current[rowIndex + 1]
        if (nextInput) {
          nextInput.focus()
        } else {
          e.currentTarget.blur()
        }
      }
    },
    [inputsRef, rowIndex]
  )

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Indicator row={row} />
      <div className="p-inputgroup">
        <span className="p-inputgroup-addon">R$</span>
        <InputText
          ref={(input) => {
            inputsRef.current[rowIndex] = input
          }}
          disabled={!userScenery.write}
          style={{ height: 32, borderColor: '#BEE3F8', textAlign: 'center', maxWidth: 80 }}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          placeholder={focusValue}
          onFocus={onFocus}
        />
      </div>
    </div>
  )
}

export default PPVFinal
