import React, {
  useCallback,
  useEffect,
  useState,
  useReducer,
  Fragment,
  useMemo,
  useContext,
} from 'react'
import { FaFilter as FilterSvg } from 'react-icons/fa'
import {
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'

import api from '../../../../../services/api'
import Button from '../../../../../components/Button'
import Autocomplete from '../../../../../components/Autocomplete'

import IFilters from '../types/IFilters'

import nloop from './nloop'
import { niveisIniState, niveisReducer } from './reducer'
import { ICaptain, IClusters } from '../types/IRow'
import Niveis from '../../_layouts/Options/Filters_Level/Niveis'
import { UserContext } from 'state/user-context'

interface IProps {
  filters: IFilters
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}
interface LevelsSelectedState {
  '1': IOption[]
  '2': IOption[]
  '3': IOption[]
  '4': IOption[]
  '5': IOption[]
}

const Filters: React.FC<IProps> = ({
  filters,
  setFilters,
  setPage,
  setLoading,
}) => {
  const toast = useToast()
  const [levels, setLevels] = useState<any>({
    '1': [],
    '2': [],
    '3': [],
    '4': [],
    '5': [],
  })
  const [levelsSelected, setLevelsSelected] = useState<any>({
    '1': [],
    '2': [],
    '3': [],
    '4': [],
    '5': [],
  })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [niveis, dispatch] = useReducer(niveisReducer, niveisIniState)
  const [clusterId, setClusterId] = useState<IOption[]>([])
  const [clusterIdOpt, setClusterIdOpt] = useState<IOption[]>([])
  const [captainsIdOpt, setCaptainsIdOpt] = useState<IOption[]>([])
  const [captainsId, setCaptainsId] = useState<IOption[]>([])
  const [isFiltering, setIsFiltering] = useState<boolean>(false)
  const [classificationOptSelected, setClassificationOptSelected] = useState<IOption[]>([])
  const { user } = useContext(UserContext)

  const classificationOptions = [
    { label: 'Mrg. Objetiva', value: 'MRG. OBJETIVA' },
    { label: 'Mercado', value: 'MERCADO' },
  ]

  const request = useMemo(() => {
    const newRequest: { [n: string]: string[] } = {}

    nloop.forEach((n) => {
      newRequest[`nivel0${n}`] = niveis[n].map((o) => o.value)
    })

    return newRequest
  }, [niveis])

  useEffect(() => {
    const getLevels = async () => {
      api.post('materials/filter/level-1').then((res: any) => {
        setLevels({
          [1]: res.data.data.result.map(
            (item: { level_name: string; level_code: string }) => ({
              label: item.level_name,
              value: item.level_code,
            })
          ),
        })
      })
    }
    getLevels()
  }, [])

  const setNivel = useCallback((data: { [n: string]: IOption[] }) => {
    dispatch({ type: 'SETNIVEL', payload: data })
  }, [])

  const setDefaultData = useCallback(() => {
    nloop.forEach((n) => {
      setNivel({ [n]: filters[`nivel0${n}`] || [] })
    })
    setLevelsSelected({
      '1': filters.level1_code,
      '2': filters.level2_code,
      '3': filters.level3_code,
      '4': filters.level4_code,
      '5': filters.level5_code,
    })

    setCaptainsId(filters?.captain_code)
    setClusterId(filters?.cluster_id)
  }, [filters, setNivel])

  useEffect(() => {
    setDefaultData()
  }, [setDefaultData, isOpen])

  const getClusterId = useCallback(() => {
    isOpen &&
      api
        .get('/clusters/all')
        .then((res) => {
          if (user?.clusters?.includes(0)) {
            setClusterIdOpt(
              res.data.data.clusters.map((item: any) => ({
                label: item.name,
                value: item.id,
              }))
            )
          } else {
            setClusterIdOpt(
              res.data.data.clusters
                .filter((item: any) => user?.clusters?.includes(item.id))
                .map((item: any) => ({
                  label: item.name,
                  value: item.id,
                }))
            )
          }
        })
        .catch((e) => {
          toast({
            title: `Não foi possível trazer as opções de filtro:  ${e.response?.data?.message}`,
            status: 'error',
            isClosable: true,
          })
        })
  }, [isOpen, toast, user])
  useEffect(getClusterId, [getClusterId])

  const getCaptainsId = useCallback(() => {
    isOpen &&
      api
        .get('/relativity-sku/captains')
        .then((res) => {
          setCaptainsIdOpt(
            res.data?.data?.materials?.map((v: ICaptain) => ({
              label: v?.captain_code + ' - ' + v?.description,
              value: v?.captain_code,
            }))
          )
        })
        .catch((e) => {
          toast({
            title: `Não foi possível trazer as opções de filtro:  ${e.response?.data?.message}`,
            status: 'error',
            isClosable: true,
          })
        })
  }, [isOpen, toast, setCaptainsIdOpt])

  useEffect(() => {
    getCaptainsId()
  }, [getCaptainsId])

  const onFilter = useCallback(() => {
    const newFilters: { [n: string]: IOption[] } = {
      cluster_id: clusterId,
      captain_code: captainsId,
      classificacao_item: classificationOptSelected
      }

    nloop.forEach((n) => {
      newFilters[`level${n}_code`] = levelsSelected[n]
    })
    setIsFiltering(false)

    setFilters(newFilters)
    onClose()
    setPage(1)
    setLoading(true)
  }, [clusterId, levelsSelected, onClose, setFilters, setLoading, setPage, captainsId, classificationOptSelected])

  const onClear = useCallback(() => {
    const newFilters: { [n: string]: IOption[] } = {
      cluster_id: [],
      captain_code: [],
      classificacao_item: []
    }

    nloop.forEach((n) => {
      newFilters[`level${n}_code`] = []
    })

    setIsFiltering(false)

    setFilters(newFilters)
    onClose()
    setPage(1)
    setLoading(true)
  }, [onClose, setFilters, setLoading, setPage])

  return (
    <>
      <Flex mb="1rem">
        <Button
          padding="0.5rem 1rem"
          onClick={onOpen}
          containerStyle={{
            backgroundColor: isFiltering ? '#38A169' : '#003b74',
          }}
        >
          <FilterSvg />
        </Button>
      </Flex>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="40rem">
          <ModalHeader>Filtros</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="flex-start">
              <Text>Cluster</Text>
              <Autocomplete
                value={clusterId}
                options={clusterIdOpt}
                setValue={setClusterId}
                isMulti
              />
              <Text>Sku Capitão</Text>
              <Autocomplete
                value={captainsId}
                options={captainsIdOpt}
                setValue={setCaptainsId}
                isMulti
              />
              <Autocomplete
                isMulti
                label="Classificação Produto"
                value={classificationOptSelected}
                options={classificationOptions}
                setValue={setClassificationOptSelected}
              />
              <VStack w="100%" align="flex-start">
                <Niveis
                  niveis={niveis}
                  setNivel={setNivel}
                  levels={levels}
                  setLevels={setLevels}
                  levelsSelected={levelsSelected}
                  setLevelsSelected={setLevelsSelected}
                />
              </VStack>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <VStack w="100%" align="flex-start">
              <Divider />
              <Flex w="100%" pt="0.5rem" justifyContent="space-between">
                <Button onClick={onClear}>Limpar</Button>
                <Button onClick={onFilter}>Filtrar</Button>
              </Flex>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Filters
