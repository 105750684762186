import React, { useCallback, useContext, useState } from 'react'
import { Input, InputGroup, InputLeftAddon, useToast } from '@chakra-ui/react'

import api from '../../../../../../services/api'
import { money } from '../../../../../../utils/masks'

import { IProps } from '../../types/IRow'
import { UserContext } from 'state/user-context'

const Indice: React.FC<IProps> = ({ row, getRows = () => null }) => {
  const { userParameters } = useContext(UserContext)
  const [value, setValue] = useState<string>(row?.index)
  const toast = useToast()

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(money(e.target.value))
  }, [])

  const onBlur = useCallback(() => {
    const url = '/politics'
    const req = {
      cluster_id: row?.cluster_id,
      captain_code: row?.sku,
      id: row?.id,
      index: Number(value),
    }

    api
      .put(url, req)
      .then(() => {
        toast({
          status: 'success',
          title: 'Índice atualizade com sucesso',
        })
        getRows()
        setTimeout(() => {
          setValue('')
        }, 2000)
      })
      .catch(() => {
        toast({
          status: 'error',
          title: 'Erro, tente novamente.',
        })
        setValue(row?.index)
      })
  }, [row, value, toast, getRows])

  return (
    <InputGroup w="8rem">
      <InputLeftAddon>%</InputLeftAddon>
      <Input
        minW="6rem"
        type="number"
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        disabled={!userParameters.write || row?.is_pricepoint}
      />
    </InputGroup>
  )
}

export default Indice
