import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import api from '../../../services/api'
import Paper from '../../../components/Paper'
import TableBig from '../../../components/TableBig'
import LoadingScreen from '../../../components/LoadingScreen'
import handleRoles from '../../../utils/handleRoles'

import { Regions, User } from './types'
import Status from './Status'
import { EditSvg } from './styles'
import { DataTable, SortOrder } from 'primereact/datatable'
import { Column } from 'primereact/column'
const Table: React.FC = () => {
  const navigate = useNavigate()
  const [rows, setRows] = useState(null)
  const [loading, setLoading] = useState(true)
  const [totalRecords, setTotalRecords] = useState(0)
  const [sort, setSort] = useState<SortOrder>(1)
  const [limit, setLimit] = useState(15)
  const [orderBy, setOrderBy] = useState('name')
  const [page, setPage] = useState(0)

  const editUser = useCallback(
    (id: string | number) => {
      navigate(`/users/edit/${id}`)
    },
    [navigate]
  )

  const getUsers = useCallback(() => {
    const _sort = sort === 1 ? 'asc' : 'desc'
    api
      .get(
        `/access-controll/all?page=${
          page === 0 ? 1 : page + 1
        }&limit=${limit}&order_by=${orderBy}&sort=${_sort}`
      )
      .then((response) => {
        setTotalRecords(response?.data.total)
        setRows(
          response.data?.users?.map((user: User) => ({
            id: user?.id,
            name: user?.name,
            email: user?.email,
            active: user?.active,
            roles: handleRoles(user?.roles),
            regions:
              user?.regions?.map((r: Regions) => r.name).join('; ') || '',
          }))
        )
        setLoading(false)
      })
  }, [limit, page, orderBy, sort])

  const activeTemplate = (rowData: any) => {
    return <Status active={rowData?.active} />
  }

  const editTemplate = (rowData: any) => {
    return <EditSvg onClick={() => editUser(rowData?.id)} />
  }

  useEffect(getUsers, [getUsers, limit, page, orderBy, sort])

  const onPage = (event: any) => {
    setPage(event.page)
    setLimit(event.rows)
  }

  const onSort = (event: any) => {
    const { sortField } = event
    setOrderBy(sortField)
    setSort(sort === 1 ? -1 : 1)
  }

  if (loading) return <LoadingScreen />
  return (
    <Paper>
      <DataTable
        scrollable
        scrollHeight="flex"
        paginator
        rows={limit}
        onSort={onSort}
        lazy
        sortField={orderBy} 
        sortOrder={sort}
        onPage={onPage}
        totalRecords={totalRecords}
        first={page * limit}
        rowsPerPageOptions={[5, 10, 25, 50]}
        value={rows || []}
        dataKey="id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        emptyMessage="Sem dados"
        filterDisplay="menu"
        currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
      >
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="id"
          header="ID"
          style={{ minWidth: '20px', zIndex: '2' }}
          sortable
        ></Column>

        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="name"
          header="Nome"
          style={{ minWidth: '130px', zIndex: '2' }}
          sortable
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="email"
          header="Email"
          style={{ minWidth: '130px', zIndex: '2' }}
          sortable
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="roles"
          header="Acessos"
          style={{ minWidth: '150px', zIndex: '2' }}
        ></Column>
        {/* <Column
          field="regions"
          header="Área de Atuação"
          style={{ minWidth: '50px', zIndex: '2' }}
          
        ></Column> */}
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="active"
          header="Ativo"
          body={activeTemplate}
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field=""
          header="Editar"
          body={editTemplate}
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column>
      </DataTable>
      {/* <TableBig data={rows || []} columns={columns} /> */}
    </Paper>
  )
}

export default Table
