import { HStack } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'

import api from 'services/api'

import Card from './Card'
import IData from './types/IData'
import { IFilters } from 'pages/PriceBaseDiscountV2/Table/types'

interface IProps {
  getBigData: boolean
  filters: IFilters
}

const Cards: React.FC<IProps> = ({ getBigData, filters }) => {
  const { id } = useParams()

  const [data, setData] = useState<IData[]>([])

  const getData = useCallback(() => {
    api.post(`/sceneries/big-numbers-discount`, { sceneryId: id, filters }).then((res) => {
      const info = res.data?.data?.result?.rows[0]

      setData([
        {
          title: 'Resultados',
          preco_medio_periodo: info?.average_price_period,
          preco_medio_atual: info?.average_price_current,
          preco_medio_novo: info?.new_price_average,
          margem_atual: info?.mrg_current,
          margem_novo: info?.mrg_new,
          color: 'blue',
        },
      ])
    })
  }, [id, filters])
  useEffect(getData, [getData, getBigData])

  return (
    <HStack w="100%" spacing="1rem" mb="1rem" align="flex-end">
      {data.map((info) => (
        <Card key={info.title} info={info} />
      ))}
    </HStack>
  )
}

export default Cards
