import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  Flex,
  Grid,
  GridItem,
  Spinner,
  HStack,
  VStack,
  Text,
} from '@chakra-ui/react'

import api from '../../../../../../../services/api'
import Button from '../../../../../../../components/Button'
import Autocomplete from '../../../../../../../components/Autocomplete'

import ViewButton from './ViewButton'
import DeleteButton from './DeleteButton'
import CreateButton from './CreateButton'
import { IOpt, IProps } from '../types'
import { UserContext } from 'state/user-context'
import Contract from './Contract'

const Panel: React.FC<IProps> = ({ row, isExpanded }) => {
  const { userParameters } = useContext(UserContext)
  const [bundles, setBundles] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const [uf, setUf] = useState<IOption>({ label: '', value: '' })
  const [ufOpt, setUfOpt] = useState<IOption[]>([])
  const [channel, setChannel] = useState<IOption>({ label: '', value: '' })
  const [channelOpt, setChannelOpt] = useState<IOption[]>([])

  useEffect(() => {
    setLoading(true)
  }, [uf, channel])

  const resetData = useCallback(() => {
    if (!isExpanded) {
      setLoading(true)
      setUfOpt([])
      setBundles([])
      setChannelOpt([])
      setUf({ label: '', value: '' })
      setChannel({ label: '', value: '' })
    }
  }, [isExpanded])
  useEffect(resetData, [resetData])

  const clearFilters = useCallback(() => {
    setUf({ label: '', value: '' })
    setChannel({ label: '', value: '' })
  }, [])

  const getBundles = useCallback(() => {
    api
      .get(
        `/cluster-bundles/${row.id}?uf=${uf.value}&channel_code=${channel.value}`
      )
      .then((response) => {
        setBundles(response.data.data)
        setTimeout(() => setLoading(false), 1000)
      })
  }, [row.id, uf.value, channel.value])

  useEffect(() => {
    if (!isExpanded || !loading) return
    getBundles()
  }, [getBundles, isExpanded, loading])
  const getFilters = useCallback(() => {
    if (!isExpanded) return

    api.get(`/cluster-bundles/filter/channels/${row.id}`).then((res) => {
      setChannelOpt(
        res.data?.data?.channels?.map((v: IOpt) => ({
          label: v?.name,
          value: v?.code,
        }))
      )
    })
    api.get(`/cluster-bundles/filter/ufs/${row.id}`).then((res) => {
      setUfOpt(
        res.data?.data?.ufs?.map((v: IOpt) => ({
          label: v?.name,
          value: v?.code,
        }))
      )
    })
  }, [isExpanded, row.id])
  useEffect(getFilters, [getFilters])

  return (
    <>
      <HStack w="100%">
        <HStack w="100%" maxW="30rem">
          <VStack w="100%" align="flex-start">
            <Text>UF</Text>
            <Autocomplete value={uf} options={ufOpt} setValue={setUf} />
          </VStack>
          <VStack w="100%" align="flex-start">
            <Text>Canal</Text>
            <Autocomplete
              value={channel}
              options={channelOpt}
              setValue={setChannel}
            />
          </VStack>
        </HStack>
        {(uf.value || channel.value) && (
          <Button
            onClick={clearFilters}
            containerStyle={{ height: '3.1rem', marginTop: 25 }}
          >
            Limpar Filtros
          </Button>
        )}
      </HStack>
      <CreateButton row={row} ufOptions={ufOpt} getBundles={getBundles} />
      {loading ? (
        <Flex justifyContent="center">
          <Spinner />
        </Flex>
      ) : (
        <Flex flexDir="column" maxH="20rem" overflowY="auto">
          <Grid
            w="100%"
            mb={2}
            px={1}
            gap={6}
            templateColumns="0.1fr 0.25fr 1fr 0.25fr 0.1fr"
          >
            <GridItem>UF</GridItem>
            <GridItem>Cidade</GridItem>
            <GridItem>Canal</GridItem>
            {/* <GridItem>Contrato</GridItem> */}
          </Grid>
          {bundles?.map((bundle) => (
            <Grid
              key={bundle.id}
              w="100%"
              gap={6}
              p={1}
              color="#919191"
              templateColumns="0.1fr 0.25fr 1fr 0.25fr 0.1fr"
              borderTop="1px solid transparent"
              borderBottom="1px solid transparent"
              _hover={{
                color: '#003b74',
                bgColor: '#EDF2F7',
                borderTop: '1px solid #E2E8F0',
                borderBottom: '1px solid #E2E8F0',
              }}
            >
              <GridItem>
                <Flex h="100%" align="center">
                  {bundle?.uf}
                </Flex>
              </GridItem>
              <GridItem>
                <Flex h="100%" align="center">
                  {bundle?.city}
                </Flex>
              </GridItem>
              <GridItem>
                <Flex h="100%" align="center">
                  {bundle?.channel_name}
                </Flex>
              </GridItem>
              <GridItem>
                <Flex h="100%" align="center">
                 {/* <Contract bundle={bundle}/> */}
                </Flex>
              </GridItem>
              {userParameters.write ? (
                <GridItem>
                  <HStack h="100%" spacing={4} align="center">
                    <ViewButton bundle={bundle} clusterId={Number(row.id)} />
                    <DeleteButton bundle={bundle} getBundles={getBundles} />
                  </HStack>
                </GridItem>
              ) : (
                <></>
              )}
            </Grid>
          ))}
        </Flex>
      )}
    </>
  )
}

export default Panel
