import { FaEdit } from 'react-icons/fa'
import { Flex, HStack, Text } from '@chakra-ui/react'
import Button from '../../../../components/Button'
import LoadingModalButton from '../../../../components/LoadingModalButton'
import { DefaultInfo } from '../useEdit/types'

interface IProps {
  name: string
  email: string
  enrollment: string
  defaultInfo: DefaultInfo | undefined
  onSave: () => Promise<void>
  onCancel: () => void
}

const Nav: React.FC<IProps> = ({
  name,
  email,
  enrollment,
  defaultInfo,
  onSave,
  onCancel,
}) => {
  return (
    <Flex w="100%" align="center" justify="space-between">
      <HStack align="center" color="#003b74">
        <Text fontSize="1.5rem">
          <FaEdit />
        </Text>
        <Text fontSize="1.3rem" fontWeight="semibold">
          Editando usuário: {defaultInfo?.name || 'Sem Nome'} /{' '}
          {defaultInfo?.email || 'Sem E-mail'} /{' '}
          {defaultInfo?.enrollment || 'Sem Matrícula'}
        </Text>
      </HStack>
      <HStack spacing="1rem">
        <Button onClick={onCancel}>Cancelar</Button>
        <LoadingModalButton
          disable={!name || !email || !enrollment}
          promise={onSave}
          message="Salvando, por favor aguarde..."
        >
          Salvar
        </LoadingModalButton>
      </HStack>
    </Flex>
  )
}

export default Nav
