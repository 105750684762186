import { AxiosResponse } from 'axios'
import api from '../../services/api'

export const getItemsListService = async (
  id: number,
  request: any,
  page: number,
  limit: number,
  type: string,
  step?: string,
  order_by?: string,
  sort?: string
) => {
  const url =
    type !== 'trading_condition'
      ? `/sceneries/${id}/items/A?page=${page}&limit=${limit}`
      : `/sceneries/${id}/items/C?page=${page}&limit=${limit}`

  //Deixei essa URL abaixo pra resolver se ainda vai ser reutilizada
  //   const oldUrl = `/sceneries/${id}/items?page=${page}&limit=${limit}&type=${type}`

  return api
    .post(url, { ...request, step, order_by, sort })
    .then((response: AxiosResponse<any>) => {
      return response.data.data
    })
    .catch(() => {
      return []
    })
}

export const getFirstItemsListService = async (
  id: number,
  request: any,
  page: number,
  limit: number,
  type: string
) => {
  const url =
    type !== 'trading_condition'
      ? `/sceneries/first/${id}/items/A?page=${page}&limit=${limit}`
      : `/sceneries/first/${id}/items/C?page=${page}&limit=${limit}`

  //Deixei essa URL abaixo pra resolver se ainda vai ser reutilizada
  //   const oldUrl = `/sceneries/${id}/items?page=${page}&limit=${limit}&type=${type}`

  return api
    .post(url, request)
    .then((response: AxiosResponse<any>) => {
      return response.data.data
    })
    .catch(() => {
      return []
    })
}
