import { HStack } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from 'services/api'

import Card from './Card'
import IData from './types/IData'

interface IProps {
  ids: number[]
  typeView: string
  refreshBigNumbers: boolean
}

const Cards: React.FC<IProps> = ({ ids, typeView, refreshBigNumbers }) => {
  const { id } = useParams()
  const [data, setData] = useState<IData[]>([])

  const getData2 = useCallback(() => {
    api
      .post(`/sceneries/new-big-numbers-request`, { ids, typeView })
      .then((res: any) => {
        const result = res?.data?.data?.rows[0]
        setData([
          {
            title: 'Preço Médio Histórico',
            main: result?.preco_medio_historico,
            volume: result?.volume_historico,
            faturamento: result?.faturamento_bruto_historico,
            margem1: result?.faturamento_liquido_historico,
            margem2: result?.margem_bruta_historico,
            margem3: result?.margem_bruta_historico,
            margem4: result?.margem_bruta_percent,

            color: 'green',
          },
          {
            title: 'Preço Médio Atual',
            main: result?.preco_medio_atual,
            volume: result?.volume_atual,
            faturamento: result?.faturamento_bruto_atual,
            margem1: result?.faturamento_liquido_atual,
            margem2: 0,
            margem3: result?.margem_bruta_atual,
            margem4: result?.margem_percent_atual,
            color: 'purple',
          },
          {
            title: 'Preço Médio Simulado',
            main: result?.preco_medio_projetado,
            volume: result?.volume_projetado,
            faturamento: result?.faturamento_bruto_projetado,
            margem1: result?.faturamento_liquido_projetado,
            margem2: 2,
            margem3: result?.margem_bruta_projetado,
            margem4: result?.margem_percent_projetado,
            indice: result?.indice,
            color: 'yellow',
          },
          {
            title: 'Preço Médio Final',
            main: result?.preco_medio_final,
            volume: result?.volume_final,
            faturamento: result?.faturamento_bruto_final,
            margem1: result?.faturamento_liquido_final,
            margem2: 2,
            margem3: result?.margem_bruta_final,
            margem4: result?.margem_percent_final,
            indice: result?.indice,
            color: 'blue',
          },
        ])
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids, typeView, refreshBigNumbers])
  useEffect(getData2, [getData2])

  return (
    <HStack w="100%" spacing="1rem" mb="1rem">
      {data.map((info) => (
        <Card key={info.title} info={info} />
      ))}
    </HStack>
  )
}

export default Cards
