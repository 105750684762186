import { useCallback, useContext, useEffect, useState } from 'react'
import { Select } from '@chakra-ui/react'

import api from 'services/api'

import IRow from '../../types/IRow'
import { UserContext } from 'state/user-context'

interface IProps {
  row: IRow
  options: IOption[]
}

const Input: React.FC<IProps> = ({ row, options }) => {
  const { userParameters } = useContext(UserContext)
  const [deflator, setDeflator] = useState(row.sensitivity)

  useEffect(() => {
    setDeflator(!row.sensitivity ? 'MRG. OBJETIVA' : row.sensitivity)
  }, [row])

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newVal = e.target.value
      setDeflator(newVal)
      const url = '/sensitivity'
      const req = {
        cluster_id: row.cluster_id,
        captain_code: row.captain_code,
        sensitivity: newVal,
      }

      api.put(url, req).catch(() => {
        setDeflator(row.sensitivity)
      })
    },
    [row]
  )

  return (
    <Select
      disabled={!userParameters.write}
      value={deflator}
      onChange={onChange}
    >
      {options.map((option) => (
        <option key={option.label} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  )
}

export default Input
