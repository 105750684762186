import { AxiosResponse } from 'axios'
import api from '../api'

export const getItemsListService = async (
  request: any,
  page: number,
  limit: number
) => {
  const url = `/market-margins?page=${page}&limit=${limit}`

  //Deixei essa URL abaixo pra resolver se ainda vai ser reutilizada
  //   const oldUrl = `/sceneries/${id}/items?page=${page}&limit=${limit}&type=${type}`

  return api
    .post(url, request)
    .then((response: AxiosResponse<any>) => {
      return response.data.data
    })
    .catch(() => {
      return []
    })
}
