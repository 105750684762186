import { useCallback, useContext, useState } from 'react'
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react'

import api from 'services/api'
import { UserContext } from 'state/user-context'

interface IProps {
  row: any
  captain: any
}

const InputRelativity: React.FC<IProps> = ({ captain, row }) => {
  const { userParameters } = useContext(UserContext)
  const [newValue, setNewValue] = useState(row.relativity)

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(e.target.value)
  }, [])

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      const newVal = e.target.value || '0'

      setNewValue(newVal)
      const url = `/relativity-sku/relativity/${row.sku}/${newValue}`
      api.put(url).catch(() => {})
    },
    [row.sku, newValue]
  )

  return (
    <InputGroup w="10rem">
      <InputLeftAddon>%</InputLeftAddon>
      <Input
        maxW="7rem"
        type="number"
        name="relativity"
        value={newValue}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={6}
        disabled={
          !userParameters.write || (userParameters.write && captain === row.sku)
            ? true
            : false
        }
      />
    </InputGroup>
  )
}

export default InputRelativity
