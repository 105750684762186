import { Flex, Grid, Text } from '@chakra-ui/react'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import api from 'services/api'

const MOCK_DATA = [
  {
    title: 'Faturamento do Ano Atual',
    sections: [
      { title: 'Meta', value: '0' },
      { title: 'Realizados', value: '0', color: 'green' },
      { title: 'Restante', value: '0' },
    ],
  },
  {
    title: 'Faturamento do Mês Atual',
    sections: [
      { title: 'Meta', value: '0' },
      { title: 'Realizados', value: '0', color: 'green' },
      { title: 'Restante', value: '0' },
    ],
  },
  {
    title: 'Preço Médio do Ano Atual',
    sections: [
      { title: 'Orçamento', value: '0' },
      { title: 'Realizados', value: '0', color: 'green' },
      { title: 'Restante', value: '0' },
    ],
  },
  {
    title: 'Preço Médio do Mês Atual',
    sections: [
      { title: 'Orçamento', value: '0' },
      { title: 'Realizados', value: '0', color: 'yellow' },
      { title: 'Restante', value: '0' },
    ],
  },
  {
    title: 'Faturamento do Ano Atual por Canal',
    sections: [{ title: 'Nenhum', value: '0' }],
  },
  {
    title: 'Faturamento do Mês Atual por Canal',
    sections: [{ title: 'Nenhum', value: '0' }],
  },
  {
    title: 'Preço Médio do Ano Atual por Canal',
    sections: [{ title: 'Nenhum', value: '0' }],
  },
  {
    title: 'Preço Médio do Mês Atual por Canal',
    sections: [{ title: 'Nenhum', value: '0' }],
  },
]

const colors = [
  '#63B3ED',
  '#4299E1',
  '#3182CE',
  '#2B6CB0',
  '#63B3ED',
  '#4299E1',
  '#3182CE',
  '#2B6CB0',
]

const Cards: React.FC = () => {
  const [data, setData] = useState(MOCK_DATA)
  const [isDemo, setisDemo] = useState(false)
  const [newData, setNewData] = useState(MOCK_DATA)

  const getData = async () => {
    const monthNumber = moment().month() + 1
    const year = 2024

    api
      .get(`dashboard?year=${year}&month=${monthNumber}`)
      .then((response: any) => {
        setNewData(response?.data?.data)
      })
      .catch((error: any) => {
        console.error(error)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const formatValue = (value: any) => {
    const newValue = new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(parseFloat(value?.replace(',', '.')))
    if (newValue.length > 5) return newValue.split(',')[0]
    else return newValue
  }

  const capitalizeFirstLetter = (string: string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
}

  return (
    <Grid
      templateColumns="repeat(4, 1fr)"
      gridGap="1rem"
      overflow="hidden"
      // filter={isDemo ? 'none' : 'grayscale(.8) opacity(.5)'}
    >
      {newData.map((card, index) => (
        <Flex
          maxHeight={40}
          key={card.title}
          p="1rem 1rem 0.5rem 1rem"
          flexDir="column"
          bgColor="#fff"
          position="relative"
        >
          <Flex
            pos="absolute"
            top="0"
            right="0"
            w="0"
            h="0"
            borderStyle="solid"
            borderWidth="0 30px 30px 0"
            borderColor={`transparent rgb(0, 59, 116) transparent transparent`}
          />
          <Text mb="0.75rem" fontWeight="semibold" fontSize="md" color="black">
            {card.title}
          </Text>

          <Flex
            key={index}
            maxHeight={40}
            paddingRight={1}
            flexDir="column"
            bgColor="#fff"
            position="relative"
            overflowY={index > 3 ? 'scroll' : 'hidden'}
            sx={{
              '&::-webkit-scrollbar': {
                width: '4px', // Ajusta a largura da barra de rolagem
              },
              '&::-webkit-scrollbar-track': {
                background: "#fff", // Cor do fundo da barra de rolagem
              },
              '&::-webkit-scrollbar-thumb': {
                background: "#fff", // Cor do polegar da barra de rolagem
                borderRadius: '10px', // Bordas arredondadas do polegar
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: "#fff", // Cor do polegar da barra de rolagem ao passar o mouse
              },
            }}
          >
            {card.sections.map((section) => (
              <Flex
                key={section.title}
                justify="space-between"
                fontSize="sm"
                py="0.125rem"
                bgColor="#fff"
              >
                <Text
                  style={{
                    fontWeight: section.title === 'Meta' ? `bold` : 'normal',
                  }}
                  color={section.color ? `${section.color}.900` : '#000'}
                >
                  {capitalizeFirstLetter(section.title)}
                </Text>
                <Text
                  isTruncated
                  fontSize='sm'
                  fontWeight="semibold"
                  color={section.color ? `${section.color}.900` : '#000'}
                >
                  R$ {formatValue(String(section.value))}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      ))}
    </Grid>
  )
}

export default Cards
