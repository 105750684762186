import { useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { FaExchangeAlt } from 'react-icons/fa'
import {
  Divider,
  Flex,
  HStack,
  Radio,
  RadioGroup,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { Spinner } from '@chakra-ui/react'
import Modal from 'components/Modal'
import Button from 'components/Button'
import api from 'services/api'
import InputToFilter from './Input'

interface IProps {
  filterToUpdateBulk: any
  display: string
  handleRefreshTable: () => void
}

const ChangeBulkButton: React.FC<IProps> = ({
  filterToUpdateBulk,
  display,
  handleRefreshTable,
}) => {
  const toast = useToast()
  const { id } = useParams()
  const [changeBulkOpen, setChangeBulkOpen] = useState(false)
  const [value, setValue] = useState('0,0')
  const [countType, setCountType] = useState('money')
  const [loading, setLoading] = useState<boolean>(false)

  const onClose = () => {
    setChangeBulkOpen(false)
  }

  const onSubmit = async () => {
    try {
      setLoading(true)
      toast({
        title: 'Aguarde...',
        status: 'info',
      })
      const { typeView, ...filters } = filterToUpdateBulk
      await api
        .put('sceneries/update-bulk-scenery-items', {
          filters: { ...filters, scenery_id: id },
          value: value.replace(',', '.'),
          countType,
          typeView,
        })
        .then((res) => {
          setLoading(false)
          toast({
            title: 'Atualizado com sucesso!',
            status: 'success',
          })
          console.log(res)
          onClose()
          handleRefreshTable()
        })
    } catch (error) {
      toast({
        title: 'Ocorreu um erro, verifique com o administrador!',
        status: 'error',
      })
      console.log(error)
    }
  }

  const onReset = async () => {
    try {
      const { typeView, ...filters } = filterToUpdateBulk
      setLoading(true)
      toast({
        title: 'Aguarde...',
        status: 'info',
      })
      await api
        .put('sceneries/reset-bulk-scenery-items', {
          filters: { ...filters, scenery_id: id },
          value: value.replace(',', '.'),
          countType,
          typeView,
        })
        .then((res) => {
          setLoading(false)
          toast({
            title: 'Dados resetados com sucesso!',
            status: 'success',
          })
          console.log(res)
          onClose()
          handleRefreshTable()
        })
    } catch (error) {
      toast({
        title: 'Ocorreu um erro, verifique com o administrador!',
        status: 'error',
      })
      console.log(error)
    }
  }

  return (
    <>
      <Flex display={display}>
        <Button
          padding="0.5rem 1rem"
          onClick={() => setChangeBulkOpen(true)}
          containerStyle={{
            backgroundColor: changeBulkOpen ? '#38A169' : '#003b74',
          }}
        >
          <FaExchangeAlt />
          <span style={{ whiteSpace: 'nowrap', marginLeft: 10 }}>
            Alteração de preço em massa
          </span>
        </Button>
      </Flex>
      <Modal
        w="70rem"
        isOpen={changeBulkOpen}
        title="Alterar Preço em Massa"
        onClose={onClose}
        body={
          <VStack w="100%" align="flex-start">
            <Divider style={{ marginTop: -15, marginBottom: 20 }} />
            <RadioGroup
              value={countType}
              onChange={(nextValue: string) => setCountType(nextValue)}
            >
              <HStack gap="6" style={{ marginBottom: 30 }}>
                <Radio value="money">R$</Radio>
                <Radio value="+money">Aumentar R$</Radio>
                <Radio value="-money">Reduzir R$</Radio>
                <Radio value="+percent">Aumentar %</Radio>
                <Radio value="-percent">Reduzir %</Radio>
              </HStack>
            </RadioGroup>
            <VStack flexDirection={'row'}>
              <InputToFilter
                type={countType}
                updateValue={(e) => setValue(e)}
              />
              <Button
                style={{
                  display: 'flex',
                  marginLeft: 30,
                  height: 31,
                  marginTop: -1,
                }}
                bg={'#3f79b0'}
                onClick={onSubmit}
              >
                {loading ? <Spinner size="md" /> : 'Alterar'}
              </Button>
            </VStack>
            <span style={{ marginTop: 20, color: 'red' }}>
              * <b>Atenção</b> - Todos os items da listagem serão alterados.{' '}
            </span>
          </VStack>
        }
        footer={
          <VStack w="100%" align="flex-start">
            <Divider />
            <Flex w="100%" pt="0.5rem" justifyContent="space-between">
              <Button onClick={onReset}>Resetar</Button>
              <Button onClick={onClose}>Fechar</Button>
            </Flex>
          </VStack>
        }
      />
    </>
  )
}

export default ChangeBulkButton
