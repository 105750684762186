import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  Fragment,
  useContext,
} from 'react'
import { ListItemText } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  FaHome as DashboardSvg,
  FaUsers as UsersSvg,
  FaCheckDouble as ApprovalSvg,
} from 'react-icons/fa'
import { HiAdjustments as ParametersSvg } from 'react-icons/hi'
import { IoPricetagsSharp as PriceBaseSvg } from 'react-icons/io5'

import { IPage } from './interfaces'
import { Container, Item, ListItemIcon } from './styles'
import { UserContext } from 'state/user-context'

const ListItems: React.FC = () => {
  const navigate = useNavigate()
  const { user } = useContext(UserContext)

  const { pathname } = useLocation()

  // const user = useMemo(() => {
  //   return JSON.parse(localStorage.getItem('@Prixsia:user') || '{}')
  // }, [])

  // const showItem = useCallback(
  //   (permission: string) => {
  //     return user?.roles?.find((role: any) =>
  //       ['ADMIN', permission].includes(role.name)
  //     )
  //   },
  //   [user]
  // )

  const defaultPages = useMemo(
    () => [
      {
        active: false,
        showItem: true,
        title: 'Início',
        icon: <DashboardSvg />,
        navigatePath: '/dashboard',
      },
      //TODO - modificar showitem para admin posteriormente
      {
        active: false,
        showItem: !!user?.access,
        title: 'Controle de Acessos',
        icon: <UsersSvg />,
        navigatePath: '/users',
      },
      {
        active: false,
        showItem: true,
        title: 'Parâmetros',
        icon: <ParametersSvg />,
        navigatePath: '/parameters',
      },
      {
        active: false,
        //showItem: showItem('READ_SCENERY') || showItem('READ_DISCOUNT_SCENERY'),
        showItem: true,
        title: 'Gestão de Preços',
        icon: <PriceBaseSvg />,
        navigatePath: '/pricebase',
      },
      {
        active: false,
        //showItem: showItem('READ_SCENERY') || showItem('READ_DISCOUNT_SCENERY'),
        showItem: true,
        title: 'Requisições',
        icon: <ApprovalSvg />,
        navigatePath: '/approval',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const [pages, setPages] = useState<IPage[]>(defaultPages)

  const updateActivePage = useCallback(() => {
    const [, routeName] = pathname.split('/')
    setPages((prevState) => {
      return prevState.map((navItem) => ({
        ...navItem,
        active: navItem.navigatePath.includes(routeName),
      }))
    })
  }, [pathname])
  useEffect(updateActivePage, [updateActivePage])

  const handleNavigation = useCallback(
    (page: IPage) => {
      navigate(page.navigatePath)
    },
    [navigate]
  )

  return (
    <Container>
      {pages?.map(
        (page) =>
          page?.showItem && (
            <Fragment key={page.navigatePath}>
              <Item
                key={page.title}
                button
                active={String(page.active)}
                onClick={() => handleNavigation(page)}
              >
                <ListItemIcon>{page.icon}</ListItemIcon>
                <ListItemText primary={page.title} />
              </Item>
            </Fragment>
          )
      )}
    </Container>
  )
}

export default ListItems
