import { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { useToast } from '@chakra-ui/react'

import api from '../../../../services/api'
import Paper from '../../../../components/Paper'
import LoadingScreen from '../../../../components/LoadingScreen'

import Table from './Table'
import IRow from './types/IRow'
import IPricePointOpt from './types/IPricePointOpt'
import Filters from './Filters'
import { IFilters } from './Filters/types'

const defaultOption = {
  label: 'Selecione...',
  value: '',
}

const EstrategiaDePosicionamento = () => {
  const toast = useToast()

  const [dataTable, setDataTable] = useState<IRow[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [filters, setFilters] = useState<IFilters>({
    values: {
      cluster_id: [],
      captain_code: [],
      channel_code: [],
      sensitivity: [],
    },
    is_pricepoint: 0,
  })
  const [isFiltering, setIsFiltering] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)
  const [totalPages] = useState<number>(1)
  const [sort, setSort] = useState<number>(1)
  const [orderBy, setOrderBy] = useState<string>('id')
  const [limit, setLimit] = useState<number>(15)

  const getRows = useCallback(() => {
    setLoading(true)
    const req: { [key: string]: string[] | number } = {
      page: page === 0 ? 1 : page + 1,
      limit,
      is_pricepoint: filters?.is_pricepoint,
    }

    Object.keys(filters.values).forEach((key) => {
      req[key] = filters.values[key]?.map((v: IOption) => v.value) || []
    })

    api
      .post(`/politics?page=${page === 0 ? 1 : page + 1}&limit=${limit}`, {
        ...req,
        order_by: orderBy,
        sort: sort === 1 ? 'asc' : 'desc',
      })
      .then((response: any) => {

        setDataTable(
          response.data?.data?.politics.map((row: IRow) => ({
            ...row,
            start_date: !row.start_date
              ? ''
              : moment(row.start_date).zone(0).format('YYYY-MM-DD'),
            end_date: !row.end_date
              ? ''
              : moment(row.end_date).zone(0).format('YYYY-MM-DD'),
          }))
        )

        setTotal(response.data?.data?.total)
        setLoading(false)
      })
      .catch((error) => {
        if (!toast.isActive('data')) {
          toast({
            id: 'data',
            title: `Erro ao carregar dados, tente novamente: ${error.message}`,
            status: 'error',
          })
        }
      })
  }, [page, toast, filters, limit, sort, orderBy])

  useEffect(getRows, [getRows])

  if (loading) return <LoadingScreen />
  return (
    <Paper>
      <Filters
        setPage={setPage}
        setLoading={setLoading}
        filters={filters}
        setIsFiltering={setIsFiltering}
        isFiltering={isFiltering}
        setFilters={setFilters}
      />
      <Table
        getRows={getRows}
        dataTable={dataTable}
        page={page}
        total={total}
        loading={loading}
        totalPages={totalPages}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        setOrderBy={setOrderBy}
        limit={limit}
        setLimit={setLimit}
      />
    </Paper>
  )
}

export default EstrategiaDePosicionamento
