import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Divider,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import Button from '../../../../../components/Button'
import Autocomplete from '../../../../../components/Autocomplete'
import api from 'services/api'
import { IChannels } from '../../ClassificacaoDoItem/types/IRow'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UserContext } from 'state/user-context'

interface IProps {
  clients: any
  channels: any
  setNewRows: (data: any[]) => void
}

const ModalAddCompetitor: React.FC<IProps> = ({
  clients,
  channels,
  setNewRows,
}) => {
  const initialValue = useMemo(() => ({ label: '', value: '' }), [])
  const { user } = useContext(UserContext)
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [channelsSelected, setChannelsSelected] =
    useState<IOption>(initialValue)
  const [isLoading, setIsLoading] = useState(false)
  const [clusterIdOpt, setClusterIdOpt] = useState([])
  const [clusterSelected, setClusterSelected] = useState<IOption>(initialValue)
  const [channelOpt, setChannelOpt] = useState<IOption[]>([])
  const [skuOpt, setSkuOpt] = useState<IOption[]>([])
  const [skuSelected, setSkuSelected] = useState<IOption>(initialValue)
  const [prioritySelected, setPrioritySelected] = useState<any>()
  const [competitorSelected, setCompetitorSelected] =
    useState<IOption>(initialValue)
  const [ufOpt, setUFOpt] = useState<IOption[]>([])
  const [competitorOpt, setCompetitorOpt] = useState<IOption[]>([])
  const [ufSelected, setUFSelected] = useState<IOption>(initialValue)

  useEffect(() => {
    setClusterSelected(initialValue)
    setChannelsSelected(initialValue)
    setCompetitorSelected(initialValue)
    setSkuSelected(initialValue)
    setPrioritySelected('')
    setUFSelected(initialValue)
  }, [isOpen, initialValue])

  const handleAddCompetitor = (data: any) => {
    setIsLoading(true)
    api
      .post('/cluster-competitor/create', data)
      .then((res: any) => {
        onClose()

        setNewRows(res?.data?.data)
        setIsLoading(false)

        if (res.status === 201) {
          toast({
            title: `Registro efetuado com sucesso`,
            status: 'success',
            isClosable: true,
          })
        }
      })
      .catch(() => {
        toast({
          title: `Houve um erro ao efetuar o registro`,
          status: 'error',
          isClosable: true,
        })
        setIsLoading(false)
      })
  }

  const getCompetitorOpt = useCallback(() => {
    isOpen &&
      api
        .post('/cluster-competitor/list-competitor', {
          uf: ufSelected.label,
          channel_code: Number(channelsSelected.value),
        })
        .then((res) => {
   
          setCompetitorOpt(res.data.data)
        })
        .catch((e) => {
          toast({
            title: `Não foi possível trazer as opções de concorrente:  ${e.response?.data?.message}`,
            status: 'error',
            isClosable: true,
          })
        })
  }, [isOpen, toast, ufSelected, channelsSelected])

  const getUFOpt = useCallback(() => {
    isOpen &&
      api
        .post('/cluster-competitor/list-uf', {
          cluster_id: clusterSelected.value,
        })
        .then((res) => {
          if (res.data.data.length <= 1) {
            setUFSelected(res.data.data[0])
            setUFOpt(res.data.data)
          } else {
            setUFOpt(res.data.data)
          }
   
        })
        .catch((e) => {
          toast({
            title: `Não foi possível trazer as opções de UF:  ${e.response?.data?.message}`,
            status: 'error',
            isClosable: true,
          })
        })
  }, [isOpen, toast, clusterSelected])

  const getSKUOpt = useCallback(() => {
    isOpen &&
      api
        .get('/relativity-sku/list-sku')
        .then((res) => {
  
          setSkuOpt(res.data.data.sku)
        })
        .catch((e) => {
          toast({
            title: `Não foi possível trazer as opções de SKU:  ${e.response?.data?.message}`,
            status: 'error',
            isClosable: true,
          })
        })
  }, [isOpen, toast])

  const getClusterId = useCallback(() => {
    isOpen &&
      api
        .get('/clusters/all')
        .then((res) => {
          if (user?.clusters?.includes(0)) {
            setClusterIdOpt(
              res.data.data.clusters.map((item: any) => ({
                label: item.name,
                value: item.id,
              }))
            )
          } else {
            setClusterIdOpt(
              res.data.data.clusters
                .filter((item: any) => user?.clusters?.includes(item.id))
                .map((item: any) => ({
                  label: item.name,
                  value: item.id,
                }))
            )
          }
        })
        .catch((e) => {
          toast({
            title: `Não foi possível trazer as opções de filtro:  ${e.response?.data?.message}`,
            status: 'error',
            isClosable: true,
          })
        })
  }, [isOpen, toast, user])

  const getChannelId = useCallback(() => {
    isOpen &&
      api
        .post('/channels/by-cluster', {
          clusters: [clusterSelected?.value],
        })
        .then((res) => {
          setChannelOpt(
            res.data?.data?.channels?.map((v: IChannels) => ({
              label: v?.channel_name,
              value: v?.channel_code,
            }))
          )
        })
        .catch((error) => {
          if (!toast.isActive('data')) {
            toast({
              id: 'data',
              title: `Erro ao carregar dados, tente novamente: ${error.message}`,
              status: 'error',
            })
          }
        })
  }, [isOpen, toast, clusterSelected])

  useEffect(() => {
    if (clusterSelected.value) {
      getChannelId()
      getUFOpt()
    }
  }, [clusterSelected, getChannelId, getUFOpt])

  useEffect(() => {
    getSKUOpt()
  }, [isOpen, getSKUOpt])

  useEffect(() => {
    if (ufSelected.value && channelsSelected.value) {
      getCompetitorOpt()
    }
  }, [ufSelected, channelsSelected, getCompetitorOpt])

  useEffect(() => {
    // getChannelId()
    getClusterId()
  }, [getClusterId])

  const addCompetitor = () => {
    const data = {
      ean_competitor: competitorSelected.value,
      cluster_name: clusterSelected.label,
      cluster_id: Number(clusterSelected.value),
      sku: skuSelected.value,
      sku_description: skuSelected.label.split('-')[1]?.trim(),
      description_priority: prioritySelected,
      competitor_description: competitorSelected.label,
      channel_code: channelsSelected.value,
      channel_name: channelsSelected.label,
      uf: ufSelected.label
    }
    handleAddCompetitor(data)
  }

  return (
    <>
      <Flex mb="1rem">
        <Button
          padding="0.5rem 1rem"
          onClick={onOpen}
          containerStyle={{
            backgroundColor: '#003b74',
            // backgroundColor: '#003b74' || '#38A169',
          }}
        >
          Adicionar Concorrente
        </Button>
      </Flex>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="40rem">
          <ModalHeader>Adicionar Concorrente</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="flex-start">
              <Text>Cluster</Text>
              <Autocomplete
                value={clusterSelected}
                options={clusterIdOpt}
                setValue={setClusterSelected}
              />
              <Text>SKU Produto</Text>
              <Autocomplete
                value={skuSelected}
                options={skuOpt}
                setValue={(a: any) => setSkuSelected(a)}
              />
              <Text>UF</Text>
              <Autocomplete
                value={ufSelected}
                options={ufOpt}
                setValue={(a: any) => setUFSelected(a)}
              />
              <Text>Canal</Text>
              <Autocomplete
                value={channelsSelected}
                options={channelOpt}
                setValue={(a: any) => setChannelsSelected(a)}
              />
              <Text>Concorrente</Text>
              <Autocomplete
                value={competitorSelected}
                options={competitorOpt}
                setValue={setCompetitorSelected}
              />
              <Text>Prioridade</Text>
              <Input
                type="number"
                value={prioritySelected}
                onChange={(e) => setPrioritySelected(Number(e.target.value))}
              />
              {/* {nloop.map((n) => (
                <Fragment key={n}>
                  <Text>Nível 0{n}</Text>
                  <Autocomplete
                    isMulti
                    value={niveis[n]}
                    options={niveis[`${n}opt`]}
                    setValue={(newVal: IOption[]) => setValueNivel(newVal, n)}
                  />
                </Fragment>
              ))} */}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <VStack w="100%" align="flex-start">
              <Divider />
              <Flex w="100%" pt="0.5rem" justifyContent="space-between">
                <Button onClick={onClose}>Voltar</Button>
                <Button onClick={addCompetitor}>
                  {isLoading ? (
                    <CircularProgress style={{ color: 'white' }} size={20} />
                  ) : (
                    'Adicionar'
                  )}
                </Button>
              </Flex>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalAddCompetitor
