import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import { Roles, DefaultInfo } from './types'
import api from '../../../../services/api'
import { Values } from '../Access/Approvals/UseSlider/types'
import { sliderInitialValues } from 'pages/UsersRegister/Types/initalValues'
import { ApproveType } from '../Access/Level'

export const initialApproveType = {
  table: false,
  discount: false,
}

const useEdit = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { userId } = useParams<string>()

  const [name, setName] = useState<string>('')
  const [roles, setRoles] = useState<Roles[]>([{ id: 1, name: 'ADMIN' }])
  const [nivel, setNivel] = useState<number>(1)
  const [password, setPassword] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [access, setAccess] = useState<boolean>(false)
  const [active, setActive] = useState<boolean>(false)
  const [enrollment, setEnrollment] = useState<string>('')
  const [defaultInfo, setDefaultInfo] = useState<DefaultInfo>()
  const [loading, setLoading] = useState(true)
  const [clusters, setClusters] = useState<IOption[]>([])
  const [approvalScopes, setApprovalScopes] =
    useState<Values[]>(sliderInitialValues)
  const [level, setLevel] = useState<any>()
  const [approvalLevel, setApprovalLevel] = useState<any>()
  const [category, setCategory] = useState<IOption[]>([])
  const [approveType, setApproveType] =
    useState<ApproveType>(initialApproveType)

  useEffect(() => {
    if (roles?.length > 0) {
      setRoles((current) => {
        if (access) {
          return [...current, { id: 1, name: 'ADMIN' }]
        }

        return current.filter((role) => role.name !== 'ADMIN')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access])

  const getUser = useCallback((userId: string) => {
    api.get(`/access-controll/${userId}`).then((response) => {
      const { user } = response.data?.data || {}

      const rolesData = !user?.roles
        ? []
        : user?.roles?.map((item: any) => {
            return JSON.parse(item)
          })
      setApprovalScopes(
        user?.approval_scope?.map((item: string) => JSON.parse(item))
      )
      setClusters(user?.clusters)
      setName(user?.name)
      setEmail(user?.email)
      setNivel(+user?.approval_levels?.at(-1)?.title?.split('0')[1] || 1)
      setActive(user?.active)
      setEnrollment(user?.enrollment)
      setLevel(JSON.parse(user?.level))
      setApprovalLevel(user?.approval_level)
      setNivel(JSON.parse(user?.nivel))
      setCategory(JSON.parse(user?.category))
      setApproveType({
        table: user?.approve_table,
        discount: user?.approve_discount,
      })
      setRoles(rolesData)
      setDefaultInfo({
        name: user?.name,
        email: user?.email,
        enrollment: user?.enrollment,
      })
      setAccess(user?.access)

      setLoading(false)
    })
  }, [])
  useEffect(() => {
    if (userId) {
      getUser(userId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  const onCancel = useCallback(() => {
    navigate('/users')
  }, [navigate])

  const onSave = () => {
    const url = `/access-controll/${userId}`
    const request = {
      approvalScopes,
      name,
      email,
      roles,
      access,
      enrollment,
      password,
      approvalLevel: approvalLevel,
      active: +active,
      clusters: clusters?.map((item: IOption) => item.value) ?? [],
      approveTable: approveType.table ?? false,
      approveDiscount: approveType.discount ?? false,
      nivel: JSON.stringify(nivel),
      level: JSON.stringify(level),
      category: JSON.stringify(category),
    }

    return api
      .put(url, request)
      .then(() => {
        navigate('/users')
        toast({
          title: 'Usuário editado com sucesso!',
          status: 'success',
        })
      })
      .catch(() => {
        toast({
          title: 'Erro, tente novamente.',
          status: 'error',
        })
      })
  }

  return {
    onSave,
    onCancel,
    name,
    email,
    nivel,
    roles,
    access,
    active,
    enrollment,
    setName,
    setEmail,
    setNivel,
    setRoles,
    setAccess,
    setActive,
    setEnrollment,
    defaultInfo,
    loading,
    clusters,
    setClusters,
    approvalScopes,
    // handleChangeSliderValues,
    password,
    setPassword,
    approveType,
    setApproveType,
    setCategory,
    category,
    setLevel,
    level,
    setApprovalLevel,
    approvalLevel,
  }
}

export default useEdit
