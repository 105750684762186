import api from 'services/api'

import nloop from './nloop'

type THandleGetOptions = (
  id: string,
  req: { [n: string]: string[] },
  cb: (data: { [n: string]: IOption[] }) => void
) => void

const ufs = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' },
]

const classificacaoProduto = [
  { value: 'MRG. OBJETIVA', label: 'Mrg. Objetiva' },
  { value: 'MERCADO', label: 'Mercado' },
]

export const handleGetOptions: THandleGetOptions = (id, req, cb) => {
  api.get(`/sceneries/edit/filters`, req).then((res) => {
    const data = { ...res.data.data.filters }

    data.cluster = data.cluster.map((cluster: any) => ({
      label: cluster.name,
      value: cluster.id,
    }))

    data.uf = ufs

    data.classificacao_item = classificacaoProduto

    data.niveis = {}

    nloop.forEach((n) => {
      data.niveis[n] = data[`nivel0${n}`]?.map((nivel: any) => ({
        label: nivel.level_name,
        value: nivel.level_code,
      }))
    })

    cb(data)
  })
}
