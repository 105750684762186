import React, { useEffect, useState } from 'react'
import { Checkbox } from '@chakra-ui/react'
import { Column } from 'primereact/column'
import { DataTable, SortOrder } from 'primereact/datatable'
import 'primeicons/primeicons.css'
import { createDynamicColumnsDiscount } from './createDynamicColumnsDiscount'

interface IMainTableProps {
  dataTable: any[]
  getRows: () => void
  toggleBigData: () => void
  inputsRef: React.MutableRefObject<any[]>
  selectedRows: any
  onSelectionChange: any
  updateRow: (newRow: Partial<any>) => void
  handleHeaderCheckboxChange: any
  allSelected: any
  newColumns: any
  sort: SortOrder
  setSort: (sort: SortOrder) => void
  setOrderBy: (orderBy: string) => void
  totalRecords: string
  rows: number
  setRows: (number: number) => void
  setPage: (number: number) => void
  page: number
  loading: boolean
  orderBy: string
}

const MainTable: React.FC<IMainTableProps> = ({
  dataTable,
  getRows,
  toggleBigData,
  inputsRef,
  selectedRows,
  onSelectionChange,
  updateRow,
  handleHeaderCheckboxChange,
  allSelected,
  newColumns,
  sort,
  setSort,
  setOrderBy,
  totalRecords,
  rows,
  setRows,
  setPage,
  page,
  loading,
  orderBy
}) => {
  const dynamicColumns = createDynamicColumnsDiscount(
    newColumns,
    getRows,
    updateRow,
    toggleBigData,
    inputsRef
  )

  const [scrollHeight, setScrollHeight] = useState('55vh')

  useEffect(() => {
    const updateScrollHeight = () => {
      const height = window.innerHeight
      if (height > 1280) {
        setScrollHeight('65vh')
      } else {
        setScrollHeight('55vh')
      }
    }
    updateScrollHeight()
  }, [])

  const [data, setData] = useState(dataTable)

  useEffect(() => {
    setData(dataTable)
  }, [dataTable])

  const onSort = (event: any) => {
    const { sortField } = event
    setOrderBy(sortField)
    setSort(sort === 1 ? -1 : 1)
  }

  const onPage = (event: any) => {
    setPage(event.page)
    setRows(event.rows)
  }

  return (
    <>
      <DataTable
        dataKey="id"
        scrollable
        scrollHeight={scrollHeight}
        paginator
        rows={rows}
        onPage={onPage}
        rowsPerPageOptions={[5, 10, 25, 50]}
        tableStyle={{ minWidth: '40rem', maxHeight: '600px' }}
        value={data}
        lazy
        sortField={orderBy} 
        sortOrder={sort}
        loading={loading}
        selectionMode={null}
        totalRecords={1200}
        selection={selectedRows}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        emptyMessage="Sem dados"
        filterDisplay="menu"
        currentPageReportTemplate={`Exibindo de {first} à {last} de ${totalRecords} registros`}
        onSort={(e) => onSort(e)}
        first={page * rows}
      >
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          // frozen
          header={
            <Checkbox
              sx={{
                '.chakra-checkbox__control': {
                  borderColor: 'black',
                },
              }}
              colorScheme="green"
              size="lg"
              isChecked={allSelected}
              onChange={handleHeaderCheckboxChange}
            />
          }
          body={(rowData) => (
            <Checkbox
              sx={{
                '.chakra-checkbox__control': {
                  borderColor: 'black',
                },
              }}
              colorScheme="green"
              size="lg"
              isChecked={selectedRows.some((row: any) => row.id === rowData.id)}
              onChange={(e) => onSelectionChange(e, rowData)}
            />
          )}
          style={{ width: '3em' }}
        />
        {/* <Column
          body={actionBodyTemplate}
          header="Ações"
          style={{ width: '150px', zIndex: '2' }}
          frozen
        /> */}
        {dynamicColumns}
      </DataTable>
    </>
  )
}

export default MainTable
