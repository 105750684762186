export const parameters = [
  'Plano de Metas',  
  'Cluster de Preço',
  'Relatividade de Canais', 
  'Relatividade de Produto',   
  'Margem Indústria',
  'Margem Mercado',
  'Classificação do Item',
  'Concorrente',  
  // 'Arredondamento',
  'Estratégia de Posicionamento',  
  'Condições Comerciais',
  'Upload de Pesquisa',
  
  //'Fator ICMS',
]
