import { HStack, Text, VStack, CheckboxGroup, Checkbox } from '@chakra-ui/react'
import { useCallback, useMemo } from 'react'

const permissions = [
  {
    name: 'Parâmetros',
    read: {
      id: 2,
      name: 'READ_PARAMETERS',
    },
    write: {
      id: 6,
      name: 'WRITE_PARAMETERS',
    },
  },
  {
    name: 'Simulador Tabela de Preço',
    read: {
      id: 4,
      name: 'READ_SCENERY',
    },
    write: {
      id: 3,
      name: 'WRITE_SCENERY',
    },
  },
  {
    name: 'Simulador Tabela de Desconto',
    read: {
      id: 5,
      name: 'READ_DISCOUNT_SCENERY',
    },
    write: {
      id: 1,
      name: 'WRITE_DISCOUNT_SCENERY',
    },
  },
  {
    name: 'Plano de Metas',
    read: {
      id: 8,
      name: 'READ_GOALS_PLAN',
    },
    write: {
      id: 9,
      name: 'WRITE_GOALS_PLAN',
    },
  },
]

interface IRole {
  id: number
  name: string
}

interface IProps {
  roles: IRole[]
  setRoles: React.Dispatch<React.SetStateAction<IRole[]>>
}

const Permissions: React.FC<IProps> = ({ roles, setRoles }) => {
  const isChecked = useCallback(
    (rol: IRole) => {
      return !!roles?.find((role) => role.name === rol.name)
    },
    [roles]
  )

  const mods = useMemo(
    () => [
      { name: 'Parâmetros' },
      { name: 'Simulador Tabela de Preço' },
      { name: 'Simulador Tabela de Desconto' },
      { name: 'Plano de Metas' },
    ],
    []
  )

  const onChangeRead = useCallback(
    (rolRead: IRole, rolWrite: IRole) => {
      setRoles((current) => {
        if (roles.find((role) => role.name === rolRead.name)) {
          let newRoles = current.filter((role) => role.name !== rolRead.name)

          if (roles.find((role) => role.name === rolWrite.name)) {
            newRoles = newRoles.filter((role) => role.name !== rolWrite.name)
          }

          return newRoles
        }

        return [...current, rolRead]
      })
    },
    [roles, setRoles]
  )

  const onChangeWrite = useCallback(
    (rolRead: IRole, rolWrite: IRole) => {
      setRoles((current) => {
        if (roles.find((role) => role.name === rolWrite.name)) {
          return current.filter((role) => role.name !== rolWrite.name)
        }

        if (!roles.find((role) => role.name === rolRead.name)) {
          return [...current, rolWrite, rolRead]
        }

        return [...current, rolWrite]
      })
    },
    [roles, setRoles]
  )

  return (
    <VStack w="100%" align="flex-start">
      <Text fontSize="md" fontWeight="semibold" color="#003b74">
        Modulos / Funções
      </Text>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{marginTop: 20}}>
        <CheckboxGroup size="lg">
          <VStack align="flex-start">
            {mods.map((mod: any) => (
              <Text key={mod.name} fontSize="1rem">
                {mod.name}
              </Text>
            ))}
          </VStack>
        </CheckboxGroup>
        </div>
        
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 50,
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row',  }}>
            <Text style={{marginLeft: -17}} fontSize="1rem">Visualizar</Text>
            <Text style={{marginLeft: 22}} fontSize="1rem">Editar</Text>
          </div>
          {permissions.map((permission) => (
            <CheckboxGroup key={permission.name} size="lg">
              <HStack>
                <Checkbox
                  isChecked={isChecked(permission.read)}
                  onChange={() =>
                    onChangeRead(permission.read, permission.write)
                  }
                ></Checkbox>
                <Checkbox
                style={{marginLeft: 50}}
                  isChecked={isChecked(permission.write)}
                  onChange={() =>
                    onChangeWrite(permission.read, permission.write)
                  }
                ></Checkbox>
              </HStack>
            </CheckboxGroup>
          ))}
        </div>
      </div>
    </VStack>
  )
}

export default Permissions
