import { useRef, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  FormControl,
  FormLabel,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import LoadingModalButton from 'components/LoadingModalButton'
import Button from 'components/Button'
import { handleApproveRejectService } from 'pages/Approval/Service/handle-approve-reject.service'
import api from 'services/api'
import { getRequestItemService } from 'pages/Approval/Service/get-request-item.service'
import { IUser } from 'domain/login/IUser'
import moment from 'moment'

const ConfirmButton = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { requestId } = useParams()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [text, setText] = useState<string>('')
  const [verifiedNivel, setVerifiedNivel] = useState<any>(1)
  const [user, setUser] = useState<IUser>()
  const cancelRef = useRef(null)

  const messageToStatus = (userNivel: any, verifiedLevel: any) => {
    if (userNivel?.nivel1?.checked && verifiedLevel === 1) return 'APPROVED'
    else if (userNivel?.nivel2?.checked && verifiedLevel === 2)
      return 'APPROVED'
    else if (userNivel?.nivel1?.checked && verifiedLevel === 2)
      return 'PENDING_APPROVAL_N2'
    else if (userNivel?.nivel3?.checked && verifiedLevel === 3)
      return 'APPROVED'
    else if (userNivel?.nivel2?.checked && verifiedLevel === 3)
      return 'PENDING_APPROVAL_N3'
    else return ''
  }

  useEffect(() => {
    const verify = async () => {
      const verifyNivel = await api.get(`access-controll/verify-nivel`)
      setVerifiedNivel(verifyNivel?.data)

      const res = await api.get(`access-controll/${user?.id}`)
      const resData = res.data?.data?.user
      setUser(resData)
    }
    verify()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onConfirm = async (type: string) => {
    const verifyIfCanApprove = await getRequestItemService(requestId ?? '')

    //VERIFICACAO PARA APROVAVAO OU REPROVACAO DENTRO OU FORA DA DATA VIGENTE
    
    // const initialDate = moment(
    //   verifyIfCanApprove[0].initial_validity_date,
    //   'YYYY-MM-DD'
    // )
    // const finalDate = moment(
    //   verifyIfCanApprove[0].final_validity_date,
    //   'YYYY-MM-DD'
    // )
    // const today = moment()

    // if (today.isBefore(initialDate, 'day')) {
    //   toast({
    //     status: 'error',
    //     title:
    //       'Não foi possível aprovar. O prazo de vigência ainda não começou',
    //   })
    //   return
    // }

    // if (today.isAfter(finalDate, 'day')) {
    //   toast({
    //     status: 'error',
    //     title: 'Não foi possível aprovar. O prazo de vigência já terminou',
    //   })
    //   return
    // }

    if (
      (user?.nivel === 1 && !verifyIfCanApprove[0].status.includes('N1')) ||
      (user?.nivel === 2 && verifyIfCanApprove[0].status.includes('N3'))
    ) {
      toast({
        status: 'error',
        title: 'É necessário ter o nível maior para aprovação',
      })
      return
    }
    await handleApproveRejectService({
      ids: [Number(requestId) || 0],
      type,
      reason: text,
      status: messageToStatus(JSON.parse(user?.nivel), verifiedNivel),
    })
      .then((res: any) => {
        if (res.status === 201) {
          toast({
            status: 'success',
            title: 'Requisição atualizada com sucesso',
          })
          navigate('/approval')
        } else {
          toast({
            status: 'error',
            title: 'Erro, tente novamente.',
          })
        }
      })
      .catch(() => {
        toast({
          status: 'error',
          title: 'Erro, tente novamente.',
        })
      })
  }

  return (
    <>
      <Button onClick={onOpen}>Confirmar</Button>
      <AlertDialog
        isOpen={isOpen}
        onClose={() => {
          onClose(), setText('')
        }}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent maxW="xl">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirmar
            </AlertDialogHeader>
            <AlertDialogBody>
              <FormControl>
                <FormLabel>Escreva o motivo</FormLabel>
                <Textarea
                  placeholder="Digite aqui..."
                  value={text}
                  onChange={(e: any) => setText(e.target.value)}
                />
              </FormControl>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Flex w="100%" justify="space-between">
                <Button onClick={onClose}>Cancelar</Button>
                <LoadingModalButton
                  disable={!text}
                  bg="brown"
                  promise={() => onConfirm('reject')}
                  message="Reprovando, por favor aguarde..."
                >
                  Reprovar
                </LoadingModalButton>
                <LoadingModalButton
                  disable={!text}
                  bg="darkGreen"
                  promise={() => onConfirm('approve')}
                  message="Aprovando, por favor aguarde..."
                >
                  Aprovar
                </LoadingModalButton>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default ConfirmButton
