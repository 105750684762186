import React, { useCallback, useContext, useState } from 'react'
import {
  Input as ChakraInput,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react'
import IRow from '../../types/IRow'
import { UserContext } from 'state/user-context'
import api from 'services/api'

interface IProps {
  row: IRow
  param: string
  onUpdate: (rowId: string, row: IRow, param: string) => void // Função de callback para atualizar a linha
}

const Input: React.FC<IProps> = ({ row, param, onUpdate }) => {
  const { userParameters } = useContext(UserContext)
  const [value, setValue] = useState<number | string>(
    Number(row[param]).toFixed(2) || 0.0
  )

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }, [])

  const onBlur = useCallback(() => {
    if (!value) return
    const updatedRow = { ...row, [param]: value }
    onUpdate(row.client_code, updatedRow, param) // Passa o parâmetro atualizado e o ID da linha para a função onUpdate

    const url = '/trading-condition'
    const req = { id: row.client_code, data: { [param]: Number(value) } }

    api.put(url, req).then(() => {})
  }, [param, row, onUpdate, value])

  return (
    <InputGroup w="10rem">
      <InputLeftAddon>%</InputLeftAddon>
      <ChakraInput
        maxLength={100}
        disabled={!userParameters.write}
        type="number"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </InputGroup>
  )
}

export default Input
