import { useCallback } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import Paper from 'components/Paper'
import scenValue from 'utils/scenValue'
import { FaDropbox } from 'react-icons/fa'
import IData from '../types/IData'
import SubSection from './SubSection'

interface IProps {
  info: IData
}

const Card: React.FC<IProps> = ({ info }) => {
  const invalid = useCallback((value: string | number) => {
    return +(value + '').replace(',', '.') ? false : '-'
  }, [])

  const localeNumber = useCallback((value: string) => {
    let newVal = Intl.NumberFormat('pt-BR').format(+value.replace(',', '.'))
    if (!newVal.includes(',')) newVal += ',00'
    if (newVal.split(',')[1].length === 1) newVal += '0'
    return newVal
  }, [])

  const formatarVolume = useCallback((numero: number) => {
    return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }, [])

  return (
    <Paper containerStyle={{ width: '100%', height: '17rem' }}>
      <Flex h="100%" flexDir="column" justify="space-between" color="#003b74">
        <Flex flexDir="column" align="center">
          {!info.volume ? (
            <Flex
              flexDir="column"
              align="center"
              marginTop={16}
            >
              <FaDropbox size="50px" color='#bababa' />
              <Text fontSize="md" fontWeight={500} marginTop={5} color={'#bababa'}>Sem dados</Text>
            </Flex>
          ) : (
            <Flex flexDir="column" align="center">
              <Text fontSize="md">{info.title}</Text>
              <Text
                color={`${info.color}.500`}
                fontSize="5xl"
                fontWeight="bold"
              >
                {invalid(info.main) ||
                  `R$ ${String(info.main).replace('.', ',')}`}
              </Text>
              <Flex flexDir="column" align="center">
                <SubSection label="Volume">
                  {formatarVolume(+info.volume)}
                </SubSection>
                <SubSection label="Faturamento Bruto">
                  {invalid(info.faturamento) ||
                    `R$ ${localeNumber(
                      scenValue(Number(info.faturamento).toFixed(0))
                    )
                      .replace(',', '.')
                      .slice(0, -3)}`}
                </SubSection>
                <SubSection label="Faturamento Líquido">
                  {invalid(info.margem1) ||
                    `R$ ${localeNumber(scenValue(info.margem1))
                      .replace(',', '.')
                      .slice(0, -3)}`}
                </SubSection>
                <SubSection label="Margem Bruta">
                  {invalid(info.margem3) ||
                    `R$ ${localeNumber(scenValue(info.margem3))
                      .replace(',', '.')
                      .slice(0, -3)}`}{' '}
                  / {invalid(info.margem4) || `${scenValue(info.margem4, 1)}%`}
                </SubSection>
                {info.indice ? (
                  <SubSection label="Índice">
                    {invalid(info.indice) || `${scenValue(info.indice, 2)}%`}
                  </SubSection>
                ) : (
                  <Flex color="transparent">-</Flex>
                )}
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Paper>
  )
}

export default Card
