import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
`

export const HeadContent = styled.div`
  height: 31%;
  // position: absolute;
  width: 100%;
`

export const BodyContent = styled.div`
  height: 69%;
  overflow: scroll;
  // margin-top: 265px;
`
