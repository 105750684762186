import React, { useCallback, useContext, useState } from 'react'
import { Divider, useToast, Flex, HStack, Input, Text } from '@chakra-ui/react'

import api from '../../../../../../services/api'
import Button from '../../../../../../components/Button'

import Bundles from './Bundles'
import { Container } from './styles'
import { IProps } from './types'
import { UserContext } from 'state/user-context'

const Panel: React.FC<IProps> = ({ row, isExpanded }) => {
  const toast = useToast()
  const { userParameters } = useContext(UserContext)
  const [name, setName] = useState(row.name)

  const onSave = useCallback(() => {
    const request = { id: row.id, name }

    api
      .put('/clusters', request)
      .then(() => {
        toast({
          title: 'Salvo com sucesso!',
          status: 'success',
          isClosable: true,
        })
        setTimeout(() => window.location.reload(), 2000)
      })
      .catch(() => {
        toast({
          title: 'Erro, tente novamente.',
          status: 'error',
          isClosable: true,
        })
      })
  }, [name, row.id, toast])

  return (
    <Container>
      <Flex justifyContent="space-between">
        <HStack>
          <Text w="10rem">{row?.factory_name}</Text>
          <Input
            placeholder="Nome do Cluster"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </HStack>
        <Button disable={!userParameters.write} onClick={onSave}>
          Salvar
        </Button>
      </Flex>
      <Divider my={8} />
      <Bundles row={row} isExpanded={isExpanded} />
    </Container>
  )
}

export default Panel
