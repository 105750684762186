import { useCallback, useState } from 'react'
import Cards from './Cards'
import Header from './Header'
import { IFilters } from './types/IFilters'
import { BodyContent, Container, HeadContent } from './styles'
import Table2 from './Table2'
import { colsClient } from './Table2/dynamicColumns'

const ApprovalPriceBaseDiscountV2: React.FC = () => {
  const [filters, setFilters] = useState({} as IFilters)
  const [getBigData, setGetBigData] = useState(false)
  const [majorDiscount, setMajorDiscount] = useState(0)
  const [columns, setColumns] = useState(colsClient)
  const [newColumns, setNewColumns] = useState(colsClient)

  const toggleBigData = useCallback(() => {
    setGetBigData((current) => !current)
  }, [])

  const handleSetDiscount = (number: number) => {
    setMajorDiscount(number)
  }

  return (
    <Container>
      <HeadContent>
        <Header
          filters={filters}
          setFilters={setFilters}
          handleRefresh={toggleBigData}
          majorDiscount={majorDiscount}
          columns={columns}
          newColumns={newColumns}
          setNewColumns={setNewColumns}
        />
        <Cards getBigData={getBigData} filters={filters} />
      </HeadContent>
      <BodyContent>
        <Table2 filters={filters} handleSetDiscount={handleSetDiscount} newColumns={newColumns} />
      </BodyContent>
    </Container>
  )
}

export default ApprovalPriceBaseDiscountV2
