import styled from 'styled-components'
import { FaEdit } from 'react-icons/fa'

export const EditSvg = styled(FaEdit)`
  cursor: pointer;
  font-size: 1.5rem;
`
export const DeleteContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;

    color: red;
    font-size: 1.5rem;

    :hover {
      filter: brightness(110%);
    }
  }
`
