import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import api from 'services/api'
import Paper from 'components/Paper'
import Status from 'components/Status'
import { ViewSvg } from './styles'
import { Tooltip } from 'primereact/tooltip'
import { UserContext } from 'state/user-context'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useToast } from '@chakra-ui/react'

const limit = 50

const Table: React.FC = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { userDiscountScenery, userScenery } = useContext(UserContext)
  const [rows, setRows] = useState<any[]>([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPage] = useState(1)

  const viewDetails = useCallback(
    (id: string, type: string, status: string, requestId: number) => {
      if (
        (type === 'A' && !userScenery.read) ||
        (type === 'B' && !userDiscountScenery.read)
      ) {
        toast({
          status: 'error',
          title: 'É necessário ter permissão para visualizar o cenário',
        })
        return
      } else {
        let url =
          type === 'B' ? `/approval/discount/${id}` : `/approval/table/${id}`

        if (/aguardando/i.test(status)) {
          url += `/edit/${requestId}`
        } else {
          url += `/view/${requestId}`
        }

        navigate(url)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  )

  // const columns = useMemo(() => {
  //   return [
  //     { Header: 'ID', accessor: 'id' },
  //     { Header: 'Solicitante', accessor: 'creator_name' },
  //     { Header: 'Nome', accessor: 'scenery_name' },
  //     {
  //       Header: 'Data',
  //       accessor: ({ row }: any) => (
  //         <>{moment(row.updated_at).zone(0).format('DD/MM/YYYY HH:mm')}</>
  //       ),
  //     },
  //     {
  //       Header: 'Status',
  //       accessor: ({ row }: any) => <Status status={row.status} />,
  //     },
  //     {
  //       Header: 'Visualizar',
  //       accessor: ({ row }: any) => (
  //         <Tooltip label="Ver cenário" placement="top">
  //           <ViewSvg
  //             onClick={() =>
  //               viewDetails(
  //                 row.scenery.id,
  //                 row.scenery.type,
  //                 row.status,
  //                 row.id
  //               )
  //             }
  //           />
  //         </Tooltip>
  //       ),
  //     },
  //   ]
  // }, [viewDetails])

  const getUsers = useCallback(() => {
    api
      .get(`/requests/pending-approval?page=${page}&limit=${limit}`)
      .then((res) => {
        setRows(
          res.data.data.payload?.map((row: any) => ({
            ...row,
            user: row.creator.name,
          }))
        )
        setTotal(res.data.data.total)
        setTotalPage(res.data.data.totalPages)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dateTemplate = (rowData: any) => {
    return moment(rowData.created_at).format('DD/MM/yyyy HH:mm')
  }

  const statusTemplate = (rowData: any) => {
    return <Status status={rowData.status} />
  }

  const viewTemplate = (rowData: any) => {
    return (
      <>
        <Tooltip target=".view-icon" content="Ver cenário" position="top" />
        <ViewSvg
          className="view-icon"
          onClick={() =>
            viewDetails(
              rowData.scenery.id,
              rowData.scenery.type,
              rowData.status,
              rowData.id
            )
          }
        />
      </>
    )
  }

  useEffect(getUsers, [getUsers])

  // if (loading) return <LoadingScreen />
  return (
    <Paper containerStyle={{ width: '100%', margin: 'auto 0' }}>
      {/* <TableBackend
        rows={rows}
        cols={columns}
        page={page}
        limit={limit}
        total={total}
        totalPages={totalPages}
        setPage={(newVal) => {
          setPage(newVal)
        }}
      /> */}
      <DataTable
        scrollable
        scrollHeight="flex"
        paginator
        rows={15}
        rowsPerPageOptions={[5, 10, 25, 50]}
        value={rows}
        // selectionMode="multiple"
        // selection={selectedRows}
        // onSelectionChange={onSelectionChange}
        dataKey="id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        emptyMessage="Sem dados"
        filterDisplay="menu"
        currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
      >
        <Column
          field="id"
          header="ID"
          style={{ minWidth: '20px', zIndex: '2' }}
          frozen
        ></Column>

        <Column
          field="creator_name"
          header="Solicitante"
          style={{ minWidth: '150px', zIndex: '2' }}
          frozen
        ></Column>
        <Column
          field="scenery_name"
          header="Nome do cenário"
          style={{ minWidth: '150px', zIndex: '2' }}
          frozen
        ></Column>
        <Column
          field="created_at"
          header="Data"
          body={dateTemplate}
          style={{ minWidth: '50px', zIndex: '2' }}
          frozen
        ></Column>
        <Column
          field="status"
          header="Status"
          body={statusTemplate}
          style={{ minWidth: '50px', zIndex: '2' }}
          frozen
        ></Column>
        <Column
          field=""
          header=""
          body={viewTemplate}
          style={{ minWidth: '50px', zIndex: '2' }}
          frozen
        ></Column>
      </DataTable>
    </Paper>
  )
}

export default Table
