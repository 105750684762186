import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  Input as ChakraInput,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react'

import IRow from '../../types/IRow'
import { UserContext } from 'state/user-context'
import api from 'services/api'

interface IProps {
  row: {
    month: string
    year: number
    average_price: string
    monthly_billing: string
  }
  param: string
  value: any
  onUpdate: () => void // Função de callback para atualizar a linha
}

const Input: React.FC<IProps> = ({ row, param, onUpdate, value }) => {
  const formatValue = (value: any) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(parseFloat(value?.replaceAll('.', '').replace(',', '.')))
  }

  const { userGoalsPlan } = useContext(UserContext)

  const [newValue, setNewValue] = useState<any>(formatValue(value))

  useEffect(() => {
    setNewValue(formatValue(value))
  }, [value])

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(e.target.value)
  }, [])

  const onBlur = async (e: any) => {
    await api.put('goals-plan/update', {
      month: row.month,
      year: row.year,
      data: { [param]: Number(e.target.value.replace(',', '.')) },
    })
    const formattedValue = formatValue(e.target.value)
    setNewValue(formattedValue)
    onUpdate()
  }

  return (
    <InputGroup w="18rem">
      <InputLeftAddon bgColor="#BEE3F8" fontSize="1rem !important">
        R$
      </InputLeftAddon>
      <ChakraInput
        disabled={!userGoalsPlan.write || row.month === 'TOTAL'}
        type="text"
        value={newValue}
        onBlur={onBlur}
        onChange={onChange}
        borderColor="#BEE3F8"
      />
    </InputGroup>
  )
}

export default Input
