import { useCallback, useState, useEffect } from 'react'
import { Divider, VStack } from '@chakra-ui/react'

import api from 'services/api'
import Paper from 'components/Paper'
import LoadingScreen from 'components/LoadingScreen'

import Name from './Name'
import Footer from './Footer'
import Filters from './Filters'
import PriceType from './PriceType'
import VolumePeriod from './VolumePeriod'

export interface INewClient {
  id?: number
  // cluster_id?: string
  channel_code: string
  channel_name: string
  client_code: string
  client_name: string
  // cluster_code: string
  city: string
  uf: number
}

const Panel: React.FC = () => {
  const [name, setName] = useState('')
  const [type, setType] = useState('TABELA')
  const [loading, setLoading] = useState(true)
  const [dateIni, setDateIni] = useState('')
  const [dateEnd, setDateEnd] = useState('')
  const [filters, setFilters] = useState<{ [key: string]: IOption[] }>({})
  const [options, setOptions] = useState<{ [key: string]: IOption[] }>({})
  const [clienteOptions, setClientOptions] = useState<{
    [key: string]: INewClient[]
  }>({})

  const getFilters = useCallback(() => {
    if (!loading) return

    api
      .post('sceneries/new/filters-discount', {
        clusters: filters?.cluster?.map((item: IOption) => item.value),
      })
      .then((res) => {
        const newOptions: any = {}
        const newClients: any = {}

        Object.keys(res.data?.data?.filters)?.forEach((key) => {
          const filterData = res.data?.data?.filters[key]

          if (key === 'client') {
            newClients[key] = filterData.filter((item: any) => item !== null)
          } else {
            newOptions[key] = filterData
              .filter((item: any) => item)
              .map((item: any) => {
                if (item.level_code) {
                  return { label: item.level_name, value: item.level_code }
                } else if (item.name) {
                  return {
                    label: item.name,
                    value: item.id,
                  }
                } else if (item.label) {
                  return {
                    label: item.label,
                    value: item.value,
                  }
                } else {
                  return { label: item.channel_name, value: item.channel_code }
                }
              })
          }
        })

        setClientOptions(newClients)
        setOptions(newOptions)
        setLoading(false)
      })
  }, [loading, setLoading, filters.cluster])

  const refreshClientFilter = () => {
    
    api
      .post('sceneries/new/filters-discount', {
        clusters: filters?.cluster?.map((item: IOption) => item.value),
      })
      .then((res) => {
        const newClients: any = {}

        Object.keys(res.data?.data?.filters)?.forEach((key) => {
          const filterData = res.data?.data?.filters[key]

          if (key === 'client') {
            newClients[key] = filterData.filter((item: any) => item !== null)
          } else return
        })

        setClientOptions(newClients)

        setLoading(false)
      })
  }

  useEffect(() => {
    refreshClientFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.cluster])

  useEffect(() => {
    const getLevel2 = async () => {
      api
        .post('materials/filter/level-2', {
          level1: filters?.nivel01?.map(
            (item: { label: string; value: string }) => Number(item.value)
          ),
        })
        .then((res) => {
          setOptions((oldOptions) => ({
            ...oldOptions,
            nivel02: res.data.data.result.map(
              (item: { level_code: string; level_name: string }) => {
                return {
                  value: item.level_code,
                  label: item.level_name,
                }
              }
            ),
          }))
        })
    }
    getLevel2()
  }, [filters.nivel01])

  useEffect(() => {
    const getLevel3 = async () => {
      api
        .post('materials/filter/level-3', {
          level1: filters.nivel01?.map(
            (item: { label: string; value: string }) => Number(item.value)
          ),
          level2: filters.nivel02?.map(
            (item: { label: string; value: string }) => Number(item.value)
          ),
        })
        .then((res) => {
          if (res.data.data.result.length == 0) {
            setOptions((oldOptions: any) => ({
              ...oldOptions,
              nivel03: [{ value: '0', label: 'Não há dados disponivels' }],
            }))
          } else {
            setOptions((oldOptions) => ({
              ...oldOptions,
              nivel03: res.data.data.result.map(
                (item: { level_code: string; level_name: string }) => {
                  return {
                    value: item.level_code,
                    label: item.level_name,
                  }
                }
              ),
            }))
          }
        })
    }
    getLevel3()
  }, [filters.nivel01, filters.nivel02])

  useEffect(() => {
    const getLevel4 = async () => {
      api
        .post('materials/filter/level-4', {
          level1: filters.nivel01?.map(
            (item: { label: string; value: string }) => Number(item.value)
          ),
          level2: filters.nivel02?.map(
            (item: { label: string; value: string }) => Number(item.value)
          ),
          level3: filters.nivel03?.map(
            (item: { label: string; value: string }) => Number(item.value)
          ),
        })
        .then((res) => {
          if (res.data.data.result.length == 0) {
            setOptions((oldOptions: any) => ({
              ...oldOptions,
              nivel04: [{ value: '0', label: 'Não há dados disponivels' }],
            }))
          } else {
            setOptions((oldOptions) => ({
              ...oldOptions,
              nivel04: res.data.data.result.map(
                (item: { level_code: string; level_name: string }) => {
                  return {
                    value: item.level_code,
                    label: item.level_name,
                  }
                }
              ),
            }))
          }
        })
    }
    getLevel4()
  }, [filters.nivel01, filters.nivel02, filters.nivel03])

  useEffect(() => {
    const getLevel5 = async () => {
      api
        .post('materials/filter/level-5', {
          level1: filters.nivel01?.map(
            (item: { label: string; value: string }) => Number(item.value)
          ),
          level2: filters.nivel02?.map(
            (item: { label: string; value: string }) => Number(item.value)
          ),
          level3: filters.nivel03?.map(
            (item: { label: string; value: string }) => Number(item.value)
          ),
          level4: filters.nivel03?.map(
            (item: { label: string; value: string }) => Number(item.value)
          ),
        })
        .then((res) => {
          if (res.data.data.result.length == 0) {
            setOptions((oldOptions: any) => ({
              ...oldOptions,
              nivel05: [{ value: '0', label: 'Não há dados disponivels' }],
            }))
          } else {
            setOptions((oldOptions) => ({
              ...oldOptions,
              nivel05: res.data.data.result.map(
                (item: { level_code: string; level_name: string }) => {
                  return {
                    value: item.level_code,
                    label: item.level_name,
                  }
                }
              ),
            }))
          }
        })
    }
    getLevel5()
  }, [filters.nivel01, filters.nivel02, filters.nivel03, filters.nivel04])

  useEffect(getFilters, [getFilters])

  if (loading) return <LoadingScreen />
  return (
    <Paper
      containerStyle={{
        width: '100%',
        maxWidth: '80rem',
        margin: '0 auto',
        overflow: 'visible',
      }}
    >
      <VStack w="100%" align="flex-start" spacing="1rem">
        <PriceType type={type} setType={setType} />
        <Name name={name} setName={setName} />
        <VolumePeriod
          dateIni={dateIni}
          dateEnd={dateEnd}
          setDateIni={setDateIni}
          setDateEnd={setDateEnd}
        />
        <Filters
          type={type}
          options={options}
          filters={filters}
          setFilters={setFilters}
          clients={clienteOptions.client}
        />
        <Divider />
        <Footer
          name={name}
          type={type}
          filters={filters}
          dateIni={dateIni}
          dateEnd={dateEnd}
        />
      </VStack>
    </Paper>
  )
}

export default Panel
