import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { FaFilter as FilterSvg } from 'react-icons/fa'
import {
  Divider,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import Button from '../../../../../components/Button'
import Autocomplete from '../../../../../components/Autocomplete'
import nloop from './nloop'
import IFilters from 'pages/PriceBaseTable/types/IFilters'
import { handleGetTradingConditionOptions } from './service'
import { UserContext } from 'state/user-context'
import api from 'services/api'
import { IChannels } from '../../ClassificacaoDoItem/types/IRow'

interface IProps {
  filters: any
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const Filters: React.FC<IProps> = ({
  filters,
  setFilters,
  setPage,
  setLoading,
}) => {
  const initialValue = useMemo(() => ({ label: '', value: '' }), [])
  const toast = useToast()
  const { user } = useContext(UserContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [channelsSelected, setChannelsSelected] =
    useState<IOption>(initialValue)
  const [isLoading, setIsLoading] = useState(false)
  const [clusterIdOpt, setClusterIdOpt] = useState([])
  const [clusterSelected, setClusterSelected] = useState<IOption>(initialValue)
  const [channelOpt, setChannelOpt] = useState<IOption[]>([])
  const [skuOpt, setSkuOpt] = useState<IOption[]>([])
  const [skuSelected, setSkuSelected] = useState<IOption>(initialValue)
  const [prioritySelected, setPrioritySelected] = useState<any>()
  const [competitorSelected, setCompetitorSelected] =
    useState<IOption>(initialValue)
  const [ufOpt, setUFOpt] = useState<IOption[]>([])
  const [competitorOpt, setCompetitorOpt] = useState<IOption[]>([])
  const [ufSelected, setUFSelected] = useState<IOption>(initialValue)
  const [isFiltering, setIsFiltering] = useState<boolean>(false)

  const setDefaultData = useCallback(() => {
    setClusterSelected(filters?.clusterSelected)
    setChannelsSelected(filters?.channelsSelected)
    setCompetitorSelected(filters?.competitorSelected)
    setSkuSelected(filters?.skuSelected)
    setPrioritySelected(filters.prioritySelected)
    setUFSelected(filters?.ufSelected)
  }, [filters])

  useEffect(setDefaultData, [setDefaultData, isOpen])

  const getCompetitorOpt = useCallback(() => {
    isOpen &&
      api
        .post('/cluster-competitor/list-competitor', {
          uf: ufSelected.label,
          channel_code: Number(channelsSelected.value),
        })
        .then((res) => {
             setCompetitorOpt(res.data.data)
        })
        .catch((e) => {
          toast({
            title: `Não foi possível trazer as opções de concorrente:  ${e.response?.data?.message}`,
            status: 'error',
            isClosable: true,
          })
        })
  }, [isOpen, toast, ufSelected, channelsSelected])

  const getUFOpt = useCallback(() => {
    isOpen &&
      api
        .post('/cluster-competitor/list-uf', {
          cluster_id: clusterSelected.value,
        })
        .then((res) => {
          if (res.data.data.length <= 1) {
            setUFSelected(res.data.data[0])
            setUFOpt(res.data.data)
          } else {
            setUFOpt(res.data.data)
          }
         })
        .catch((e) => {
          toast({
            title: `Não foi possível trazer as opções de UF:  ${e.response?.data?.message}`,
            status: 'error',
            isClosable: true,
          })
        })
  }, [isOpen, toast, clusterSelected])

  const getSKUOpt = useCallback(() => {
    isOpen &&
      api
        .get('/relativity-sku/list-sku')
        .then((res) => {
     
          setSkuOpt(res.data.data.sku)
        })
        .catch((e) => {
          toast({
            title: `Não foi possível trazer as opções de SKU:  ${e.response?.data?.message}`,
            status: 'error',
            isClosable: true,
          })
        })
  }, [isOpen, toast])

  const getClusterId = useCallback(() => {
    isOpen &&
      api
        .get('/clusters/all')
        .then((res) => {
          if (user?.clusters?.includes(0)) {
            setClusterIdOpt(
              res.data.data.clusters.map((item: any) => ({
                label: item.name,
                value: item.id,
              }))
            )
          } else {
            setClusterIdOpt(
              res.data.data.clusters
                .filter((item: any) => user?.clusters?.includes(item.id))
                .map((item: any) => ({
                  label: item.name,
                  value: item.id,
                }))
            )
          }
        })
        .catch((e) => {
          toast({
            title: `Não foi possível trazer as opções de filtro:  ${e.response?.data?.message}`,
            status: 'error',
            isClosable: true,
          })
        })
  }, [isOpen, toast, user])

  const getChannelId = useCallback(() => {
    isOpen &&
      api
        .post('/channels/by-cluster', {
          clusters: [clusterSelected?.value],
        })
        .then((res) => {
          setChannelOpt(
            res.data?.data?.channels?.map((v: IChannels) => ({
              label: v?.channel_name,
              value: v?.channel_code,
            }))
          )
        })
        .catch((error) => {
          if (!toast.isActive('data')) {
            toast({
              id: 'data',
              title: `Erro ao carregar dados, tente novamente: ${error.message}`,
              status: 'error',
            })
          }
        })
  }, [isOpen, toast, clusterSelected])



  useEffect(() => {
    if (clusterSelected?.value) {
      getChannelId()
      getUFOpt()
    }
  }, [clusterSelected, getChannelId, getUFOpt])

  useEffect(() => {
    getSKUOpt()
  }, [isOpen, getSKUOpt])

  useEffect(() => {
    if (ufSelected?.value && channelsSelected?.value) {
      getCompetitorOpt()
    }
  }, [ufSelected, channelsSelected, getCompetitorOpt])

  useEffect(() => {
    // getChannelId()
    getClusterId()
  }, [getClusterId])

  const onFilter = useCallback(() => {
    const newFilters: { [n: string]: any } = {
      clusterSelected,
      skuSelected,     
      prioritySelected,
      competitorSelected,
      channelsSelected,
      ufSelected
    }

    setIsFiltering(false)

    Object.keys(newFilters).forEach((key) => {
      if (newFilters[key]?.length > 0) setIsFiltering(true)
    })

    setFilters(newFilters)
    onClose()
    setPage(1)
    setLoading(true)
  }, [
    clusterSelected,
    onClose,
    setFilters,
    setLoading,
    setPage,
    channelsSelected, 
    ufSelected,
    prioritySelected,
    skuSelected,
    competitorSelected
  ])

  const onClear = useCallback(() => {
    const newFilters: { [n: string]: IOption[] } = {
      cluster_id: [],
      captain_code: [],
    }

    nloop.forEach((n) => {
      newFilters[`level${n}_code`] = []
    })

    setIsFiltering(false)

    setFilters(newFilters)
    onClose()
    setPage(1)
    setLoading(true)
  }, [onClose, setFilters, setLoading, setPage])

  return (
    <>
      <Flex mb="1rem">
        <Button
          padding="0.5rem 1rem"
          onClick={onOpen}
          containerStyle={{
            backgroundColor: isFiltering ? '#38A169' : '#003b74',
          }}
        >
          <FilterSvg />
        </Button>
      </Flex>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="40rem">
          <ModalHeader>Filtros</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="flex-start">
              <Text>Cluster</Text>
              <Autocomplete
                value={clusterSelected}
                options={clusterIdOpt}
                setValue={setClusterSelected}
              />
              <Text>SKU Produto</Text>
              <Autocomplete
                value={skuSelected}
                options={skuOpt}
                setValue={(a: any) => setSkuSelected(a)}
              />
              <Text>UF</Text>
              <Autocomplete
                value={ufSelected}
                options={ufOpt}
                setValue={(a: any) => setUFSelected(a)}
              />
              <Text>Canal</Text>
              <Autocomplete
                value={channelsSelected}
                options={channelOpt}
                setValue={(a: any) => setChannelsSelected(a)}
              />
              <Text>Concorrente</Text>
              <Autocomplete
                value={competitorSelected}
                options={competitorOpt}
                setValue={setCompetitorSelected}
              />
              <Text>Prioridade</Text>
              <Input
                type="number"
                value={prioritySelected}
                onChange={(e) => setPrioritySelected(Number(e.target.value))}
              />
              {/* {nloop.map((n) => (
                <Fragment key={n}>
                  <Text>Nível 0{n}</Text>
                  <Autocomplete
                    isMulti
                    value={niveis[n]}
                    options={niveis[`${n}opt`]}
                    setValue={(newVal: IOption[]) => setValueNivel(newVal, n)}
                  />
                </Fragment>
              ))} */}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <VStack w="100%" align="flex-start">
              <Divider />
              <Flex w="100%" pt="0.5rem" justifyContent="space-between">
                <Button onClick={onClear}>Limpar</Button>
                <Button onClick={onFilter}>Filtrar</Button>
              </Flex>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Filters
