import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { HStack, useToast } from '@chakra-ui/react'
import { FaCheckDouble as ApprovalSvg } from 'react-icons/fa'

import Button from 'components/Button'
import PageTitle from 'components/PageTitle'
import ExportExcelButton from 'components/ExportExcelButton'

import { IFilters } from '../types/IFilters'
import FilterButton from './FilterButton'
import ConfirmButton from './ConfirmButton'
import { Container } from './styles'
import ConfigButton from './ConfigButton'

interface IProps {
  filters: IFilters
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>
  handleRefresh: React.Dispatch<React.SetStateAction<boolean>>
  majorDiscount: number
  columns: any
  newColumns: any
  setNewColumns: React.Dispatch<any>
}

const Header: React.FC<IProps> = ({
  filters,
  setFilters,
  handleRefresh,
  majorDiscount,
  columns,
  newColumns,
  setNewColumns,
}) => {
  const toast = useToast()
  const navigate = useNavigate()
  const { id, type } = useParams()

  const onSave = useCallback(() => {
    if (type === 'edit') {
      toast({
        title: 'Salvo com sucesso!',
        status: 'success',
      })
    }

    navigate('/approval')
  }, [type, toast, navigate])

  return (
    <Container>
      <HStack spacing="1rem">
        <PageTitle icon={<ApprovalSvg />} title="Requisições" />
      </HStack>
      <HStack spacing="1rem">
        <Button onClick={() => handleRefresh}>Atualizar</Button>
        <ConfigButton columns={columns} setNewColumns={setNewColumns} newColumns={newColumns}/>
        <FilterButton filters={filters} setFilters={setFilters} />
        {type !== 'edit' && (
          <ExportExcelButton
            url={`/requests/${id}/export`}
            type={`Desconto ${type}`}
            filter={filters}
            sceneryId={id ?? ''}
          />
        )}
        <Button onClick={onSave}>
          {type === 'edit' ? 'Salvar' : 'Voltar'}
        </Button>
        {type === 'edit' && <ConfirmButton majorDiscount={majorDiscount} />}
      </HStack>
    </Container>
  )
}

export default Header
