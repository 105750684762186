import { useCallback, useContext, useEffect, useState } from 'react'
import { Box, Text } from '@chakra-ui/react'
import { DeleteContainer } from './styles'
import api from '../../../../../services/api'
import Paper from '../../../../../components/Paper'
import { useToast } from '@chakra-ui/react'
import InputRelativity from './InputRelativity'
import { Title } from 'components/PageTitle/styles'
import AddComponent from './AddComponent'
import { DeleteComponent } from './DeleteComponent'
import { IMaterials } from '../types'
import { UserContext } from 'state/user-context'
import { Column } from 'primereact/column'
import { DataTable, SortOrder } from 'primereact/datatable'

interface IProps {
  rows: IMaterials[]
  setRows: React.Dispatch<React.SetStateAction<any[]>>
  captain: string
}

const Body: React.FC<IProps> = ({ rows, setRows, captain }) => {
  const toast = useToast()
  const { userParameters } = useContext(UserContext)
  const [isAdding, setIsAdding] = useState(false)
  const [sku, setSku] = useState<IOption>({ label: '', value: '' })
  const [skuOpt, setSkuOpt] = useState<IOption[]>([])
  const [error, setError] = useState<string>('')
  const [sort, setOrder] = useState<SortOrder>(1)
  const [orderBy, setOrderBy] = useState('')

  useEffect(() => {
    setError('')
  }, [sku])

  const getSku = useCallback(() => {
    api.get('/relativity-sku/non-captains').then((res) => {
      setSkuOpt(
        res?.data?.data?.materials?.map(
          (item: IMaterials) =>
            +item?.sku && {
              label: `${+item?.sku} - ${item?.description}`,
              value: +item?.sku + '',
            }
        )
      )
    })
  }, [])
  useEffect(getSku, [getSku])

  const onCancel = useCallback(() => {
    setSku({ label: '', value: '' })
    setError('')
    setIsAdding(false)
  }, [])
  useEffect(onCancel, [onCancel, rows])

  const onAdd = useCallback(() => {
    const url = `/relativity-sku/child/${captain}/${sku.value}`
    api
      .post(url)
      .then((res) => {
        toast({
          title: 'Adicionado com sucesso',
          status: 'success',
          isClosable: true,
        })
        onCancel()
        setRows((current) => [
          ...current,
          {
            sku: res.data?.data?.materials[0]?.sku,
            description: res.data?.data?.materials[0]?.description,
            relativity: res.data?.data?.materials[0]?.relativity,
          },
        ])
      })
      .catch((e) => {
        setError(e.response?.data?.message)
        toast({
          title: 'Houve um erro ao adicionar',
          status: 'error',
          isClosable: true,
        })
      })
  }, [sku, captain, onCancel, setRows, toast])

  const inputTemplate = (rowData: any) => {
    return <InputRelativity row={rowData} captain={captain} />
  }

  const deleteTemplate = (rowData: any) => {
    return userParameters.write ? (
      <DeleteContainer>
        <DeleteComponent
          row={rowData}
          captain={captain}
          setRows={setRows}
        ></DeleteComponent>
      </DeleteContainer>
    ) : (
      <></>
    )
  }

  if (rows?.length === 0) return null

  const onSort = (event: any) => {
    const { sortField } = event

    setRows((prevData) => {
      const sortedData = [...prevData].sort((a: any, b: any) => {
        const aValue = a[sortField].includes('.')
          ? Number(a[sortField])
          : a[sortField]
        const bValue = b[sortField].includes('.')
          ? Number(b[sortField])
          : b[sortField]

        if (aValue == null) return sort === 1 ? 1 : -1
        if (bValue == null) return sort === 1 ? -1 : 1

        if (aValue < bValue) return sort === 1 ? -1 : 1
        if (aValue > bValue) return sort === 1 ? 1 : -1

        return 0
      })

      return sortedData
    })

    setOrderBy(sortField)
    setOrder(sort === 1 ? -1 : 1)
  }

  console.log(rows, 'rows')

  return (
    <Paper containerStyle={{ padding: '2rem', overflow: 'visible' }}>
      <Box
        style={{ zIndex: 98 }}
        display={!isAdding ? 'flex' : 'block'}
        justifyContent={'space-between'}
        marginBottom={'15px'}
      >
        <Title>
          <Text fontSize={'16pt'}>Produtos filho</Text>
        </Title>
        {userParameters.write ? (
          <AddComponent
            isAdding={isAdding}
            setIsAdding={() => setIsAdding(true)}
            sku={sku}
            skuOpt={skuOpt}
            setSku={setSku}
            error={error}
            onCancel={onCancel}
            onAdd={onAdd}
          />
        ) : (
          ''
        )}
      </Box>
      <DataTable
        onSort={onSort}
        sortField={orderBy}
        sortOrder={sort}
        scrollable
        scrollHeight="flex"
        paginator
        rows={rows?.length}
        value={rows}
        dataKey="id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        emptyMessage="Sem dados"
        filterDisplay="menu"
        currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
      >
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="sku"
          header="Sku"
          style={{ minWidth: '100px', zIndex: '2' }}
          frozen
        ></Column>

        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="description"
          header="Descrição"
          style={{ minWidth: '200px', zIndex: '2' }}
          frozen
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="relativity"
          header="Relatividade"
          body={inputTemplate}
          style={{ minWidth: '200px', zIndex: '2' }}
          frozen
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field=""
          header=""
          body={deleteTemplate}
          style={{ minWidth: '200px', zIndex: '2' }}
          frozen
        ></Column>
      </DataTable>

      {/* <Table>
        <TableHead>
          <TableRow>
            {columns?.map((column) => (
              <TableCell key={column?.Header}>{column?.Header}</TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row?.id}>
              <TableCell>{row?.sku}</TableCell>
              <TableCell>{row?.description}</TableCell>
              <TableCell>
                <InputRelativity row={row} captain={captain} />
              </TableCell>
              <TableCell>
              {userParameters.write && (
                <DeleteContainer>
                    <DeleteComponent
                      row={row}
                      captain={captain}
                      setRows={setRows}
                    ></DeleteComponent>
                 
                </DeleteContainer>
                 )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> */}
    </Paper>
  )
}

export default Body
