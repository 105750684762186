import React from 'react'
import { HStack, StackDivider } from '@chakra-ui/react'
import Paper from 'components/Paper'
import Level, { ApproveType } from './Level'
import Cluster from './Cluster'
// import Modules from './Modules'
// import Category from './Category'
import Permissions from './Permissions'
// import Approvals from './Approvals'
// import { Values } from './Approvals/UseSlider/types'

interface IProps {
  nivel: any
  roles: { id: number; name: string }[]
  active: boolean
  setNivel: React.Dispatch<any>
  setRoles: React.Dispatch<React.SetStateAction<{ id: number; name: string }[]>>
  // handleChangeSliderValues: (values: Values, index: number) => void
  // sliderValues: Values[]
  setClusters: React.Dispatch<React.SetStateAction<IOption[]>>
  clusters: IOption[]
  setCategory: React.Dispatch<React.SetStateAction<IOption[]>>
  category: IOption[]
  setLevel: React.Dispatch<React.SetStateAction<IOption[]>>
  level: IOption[]
  setApproveType: any
  approveType: any
}

const Access: React.FC<IProps> = ({
  nivel,
  roles,
  active,
  setNivel,
  setRoles,
  // handleChangeSliderValues,
  // sliderValues,
  setClusters,
  clusters,
  setCategory,
  category,
  setLevel,
  level,
  setApproveType,
  approveType,
}) => {
  return (
    <Paper
      containerStyle={{
        width: '100%',
        padding: '1rem',
        overflow: 'visible',
        backgroundColor: ` ${active ? '#FFF' : '#FFF5F5'}`,
      }}
    >
      <HStack
        w="100%"
        align="flex-start"
        justify="space-around"
        spacing="1rem"
        divider={<StackDivider />}
      >
        {/* <Modules /> */}
        <Permissions roles={roles} setRoles={setRoles} />
        <Level
          nivel={nivel}
          setNivel={setNivel}
          setApproveType={setApproveType}
          approveType={approveType}
        />
        {/* <Approvals
          handleChangeSliderValues={handleChangeSliderValues}
          sliderValues={sliderValues}
        /> */}
        <Cluster
          setClusters={setClusters}
          clusters={clusters}
          setCategory={setCategory}
          category={category}
          setLevel={setLevel}
          level={level}
        />
        {/* <Category /> */}
      </HStack>
    </Paper>
  )
}

export default Access
