import { useCallback, useContext, useEffect, useState } from 'react'
import Table from './Table'
import { Paper } from '@material-ui/core'
import IFilters from '../MargemIndustria/types/IFilters'
import { getItemsListService } from 'services/MargemMercadoBaseTable/get-items-list.service'
import FilterButton from '../_layouts/Options/Filters_Level'
import { UserContext } from 'state/user-context'

const limit = 15
const binaryPos = [1, 2, 4, 8, 16]
const parameter = 'margem_mercado'

const MargemMercado = () => {
  const { user } = useContext(UserContext)
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [clusterId, setClusterId] = useState('')
  const [totalPages, setTotalPages] = useState(1)
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState({} as IFilters)

  useEffect(() => {
    setPage(1)
    setLoading(true)
  }, [filters])

  useEffect(() => {
    if (
      user?.clusters &&
      user?.clusters?.length > 0 &&
      !user?.clusters?.includes(0)
    ) {
      setFilters({
        ...filters,
        cluster_id: user?.clusters?.map((item: any) => {
          return {
            value: item,
            label: '',
          }
        }),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const getRows = useCallback(async () => {
    const request: { [key: string]: string[] } = {}

    if (!Array.isArray(filters)) {
      Object.keys(filters).forEach((key) => {
        if (Array.isArray(filters[key])) {
          request[key] = filters[key].map((v: IOption) => v.value)
        } else {
          const { value }: any = filters[key] || {}
          request[key] = [value]
        }
      })
    }

    const newRequest = {
      ...request,
      cluster_id:
        request?.cluster_id?.length < 1 ? user?.clusters : request.cluster_id,
    }

    try {
      const result = await getItemsListService(newRequest, page, limit)
      setRows(result.data.payload)
      setTotal(result.data.total)
      setTotalPages(result.data.totalPages)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, page])

  useEffect(() => {
    if (loading) getRows()
  }, [loading, getRows])

  return (
    <Paper>
      <FilterButton filters={filters} setFilters={setFilters} />

      <Table
        rows={rows}
        loading={loading}
        clusterId={clusterId || '0'}
        binaryPos={binaryPos}
        page={page}
        limit={limit}
        total={total}
        setPage={(newVal) => {
          if (newVal === page) return
          setPage(newVal)
          setLoading(true)
        }}
        totalPages={totalPages}
        filters={filters}
      />
    </Paper>
  )
}

export default MargemMercado
