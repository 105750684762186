import { useCallback, useRef } from 'react'

import Button from 'components/Button'
import LoadingModal from 'components/LoadingModal'

interface IProps {
  promise: () => Promise<void>
  disable?: boolean
  message?: string
  children: Children
  bg?: string
}

const LoadingModalButton: React.FC<IProps> = ({
  promise,
  disable = false,
  message = 'Por favor aguarde...',
  children,
  bg,
}) => {
  const loadingRef = useRef<any>(null)

  const openLoading = useCallback((message: string, promise: Promise<void>) => {
    loadingRef.current.open(message, promise)
  }, [])

  const onConfirm = useCallback(() => {
    openLoading(message, promise())
  }, [openLoading, message, promise])

  return (
    <>
      <Button disable={disable} onClick={onConfirm} bg={bg}>
        {children}
      </Button>
      <LoadingModal ref={loadingRef} />
    </>
  )
}

export default LoadingModalButton
