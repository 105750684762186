import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px !important;
`

export const HeadContent = styled.div`
  height: 31%;
  // position: fixed;
  top: 10px
  width: 90%;
`

export const BodyContent = styled.div`
  height: 69%;
  overflow: scroll;
  margin-top: -40px;
`
