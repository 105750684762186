import { useCallback } from 'react'

interface IProps {
  value: string | number
}

const Money: React.FC<IProps> = ({ value }) => {
  const localeNumber = useCallback((value: string) => {
    let newVal = !value
      ? '0,00'
      : Intl.NumberFormat('pt-BR').format(+value.replace(',', '.'))
    if (!newVal.includes(',')) newVal += ',00'
    if (newVal.split(',')[1].length === 1) newVal += '0'
    if (newVal.split(',')[1].length > 2) {
      return `${newVal.split(',')[0]},${newVal.split(',')[1].slice(0, 2)}`
    } else return newVal
  }, [])

  if (value === '-') return <>{value}</>
  return <>{value && `R$ ${localeNumber(value + '')}`}</>
}

export default Money
