import { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Column } from 'primereact/column'
import { DataTable, SortOrder } from 'primereact/datatable'
import api from 'services/api'
import IRow from '../../../types/IRow'
import { ViewButton } from './styles'

interface IProps {
  row: IRow
}

interface IExcelRow {
  id: number
  upload_id: number
  date: string
  captain_sku: number
  description: string
  uf: string
  city: string
  channel: string
  discounted_price: number
  regular_price: number
  created_at: string
  updated_at: string
}

const View: React.FC<IProps> = ({ row }) => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [dataTable, setDataTable] = useState<IExcelRow[]>([])
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState(15)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [sort, setSort] = useState<SortOrder>(1)
  const [orderBy, setOrderBy] = useState<string>('description')

  const resetData = useCallback(() => {
    if (!isOpen) {
      setDataTable([])
      setLoading(true)
      setPage(0)
      setTotal(0)
    }
  }, [isOpen])

  const getRows = useCallback(() => {
    isOpen &&
      api
        .get(
          `/market-prices?&upload_id=${row.id}&page=${
            page === 0 ? 1 : page + 1
          }&limit=${Number(rows)}&order_by=${orderBy}&sort=${
            sort === 1 ? 'asc' : 'desc'
          }`
        )
        .then((response) => {
          setDataTable(response.data.data.data.payload)
          setTotal(response.data.data.data.total)
          setLoading(false)
        })
        .catch(() => {
          if (!toast.isActive('viewdata')) {
            toast({
              id: 'viewdata',
              title: 'Erro ao carregar dados, tente novamente.',
              status: 'error',
              isClosable: true,
            })
          }
        })
  }, [isOpen, row.id, toast, rows, page, orderBy, sort])

  useEffect(getRows, [getRows, page, orderBy, sort, rows])

  const dateHourTemplate = (rowData: any) => {
    return moment(rowData.price_date).format('DD/MM/yyyy HH:mm')
  }

  const onPage = (event: any) => {
    setPage(event.page)
    setRows(event.rows)
  }

  const DefaultTemplate = (rowData: any, field: any) => {
    return rowData[field] ? rowData[field] : '-'
  }

  const onSort = (event: any) => {
    const { sortField } = event

    setOrderBy(sortField)
    setSort(sort === 1 ? -1 : 1)
  }

  const handleClose = () => {
    onClose()
    resetData()
  }

  return (
    <>
      <ViewButton onClick={onOpen} />
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={handleClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="80vw">
          <ModalHeader>
            Visualizar: {row.id} - {row.name} - {row.type} -{' '}
            {moment(row.date).zone(0).format('DD/MM/YYYY')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex overflowX="auto" justify="center">
              {loading ? (
                <Spinner mb={12} size="xl" />
              ) : (
                <DataTable
                  onSort={onSort}
                  lazy
                  sortField={orderBy}
                  sortOrder={sort}
                  scrollable
                  onPage={onPage}
                  scrollHeight="65vh"
                  paginator
                  rows={rows}
                  first={page * rows}
                  value={dataTable}
                  totalRecords={total}
                  // rowsPerPageOptions={[15, 20, 25]}
                  dataKey="id"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  emptyMessage="Sem dados"
                  filterDisplay="menu"
                  currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
                >
                  <Column
                    headerStyle={{
                      backgroundColor: '#d0e1fd',
                      color: '#003b74',
                    }}
                    sortable
                    field="data"
                    header="Data Preço"
                    body={dateHourTemplate}
                    style={{ minWidth: '20px' }}
                    frozen
                  ></Column>
                  <Column
                    headerStyle={{
                      backgroundColor: '#d0e1fd',
                      color: '#003b74',
                    }}
                    sortable
                    field="ean"
                    header="EAN"
                    style={{ minWidth: '20px' }}
                    frozen
                    body={(rowData) => DefaultTemplate(rowData, 'ean')}
                  ></Column>
                  <Column
                    headerStyle={{
                      backgroundColor: '#d0e1fd',
                      color: '#003b74',
                    }}
                    sortable
                    field="description"
                    header="Descrição Concorrente"
                    style={{ minWidth: '100px' }}
                    frozen
                    body={(rowData) => DefaultTemplate(rowData, 'description')}
                  ></Column>
                  <Column
                    headerStyle={{
                      backgroundColor: '#d0e1fd',
                      color: '#003b74',
                    }}
                    sortable
                    field="uf"
                    header="UF"
                    style={{ minWidth: '15px' }}
                    frozen
                    body={(rowData) => DefaultTemplate(rowData, 'uf')}
                  ></Column>
                  <Column
                    headerStyle={{
                      backgroundColor: '#d0e1fd',
                      color: '#003b74',
                    }}
                    sortable
                    field="channel_name"
                    header="Canal do PDV"
                    style={{ minWidth: '40px' }}
                    frozen
                    body={(rowData) => DefaultTemplate(rowData, 'channel_name')}
                  ></Column>
                  <Column
                    headerStyle={{
                      backgroundColor: '#d0e1fd',
                      color: '#003b74',
                    }}
                    sortable
                    field="sales_price"
                    header="Preço Ponderado"
                    style={{ minWidth: '30px' }}
                    frozen
                    body={(rowData) => DefaultTemplate(rowData, 'sales_price')}
                  ></Column>
                  <Column
                    headerStyle={{
                      backgroundColor: '#d0e1fd',
                      color: '#003b74',
                    }}
                    sortable
                    field="regular_price"
                    header="Preço Regular"
                    style={{ minWidth: '30px' }}
                    frozen
                    body={(rowData) =>
                      DefaultTemplate(rowData, 'regular_price')
                    }
                  ></Column>
                  <Column
                    headerStyle={{
                      backgroundColor: '#d0e1fd',
                      color: '#003b74',
                    }}
                    sortable
                    field="research_source"
                    header="Preço Pesquisa"
                    style={{ minWidth: '30px' }}
                    frozen
                    body={(rowData) =>
                      DefaultTemplate(rowData, 'research_source')
                    }
                  ></Column>
                </DataTable>
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default View
