import React, { useMemo } from 'react'
import { Text, VStack } from '@chakra-ui/react'
import { createFilter } from 'react-select'
import { StyledAutocomplete } from './styles'

interface IProps {
  label?: string
  value: any
  options: IOption[]
  setValue: any
  isMulti?: boolean
  loading?: boolean // Adicionando a propriedade loading na interface
  disabled?: boolean
}

const Autocomplete: React.FC<IProps> = ({
  label,
  value,
  options,
  setValue,
  isMulti = false,
  loading = false, // Definindo um valor padrão para loading
  disabled = false,
}) => {
  const isLoading = useMemo(() => {
    return (
      loading ||
      options === null ||
      options === undefined ||
      options.length === 0
    )
  }, [loading, options])

  return (
    <VStack w="100%" align="flex-start">
      {label && <Text>{label}</Text>}
      <StyledAutocomplete
        isDisabled={disabled}
        isMulti={isMulti}
        options={options}
        value={value}
        onChange={(val) => setValue(val)}
        isSearchable
        isClearable={false}
        isLoading={isLoading}
        placeholder={`${isLoading ? '. Carregando' : '. Selecione'}...`}
        filterOption={createFilter({ ignoreAccents: false })}
      />
    </VStack>
  )
}

export default Autocomplete
